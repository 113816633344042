
import { useState } from "react"
import BarChart from "./Market Cap/MarketCap"


export default function Showcapforreport(){
    
    
    const admin=JSON.parse(localStorage.getItem('admindata'))
    const [brokerid,setBrokerid]=useState(null)
    const [brokername,setBrokerName]=useState(admin.user_name)
    const [brokertype,setBrokerType]=useState(admin.user_type)
    const body=[{brokerid:brokerid,brokername:brokername,brokertype:brokertype}]
    

return (

    <div style={{width:'40%',height:'40%'}}>

      <BarChart body={body} />
       
    </div>
      
    
)


}