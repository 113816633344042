import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useState} from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { URL } from '../service/url'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import inddustrytype from "../industrytype.json"
import { JSONURL } from '../service/url'
import {  useRef,  useMemo, useCallback} from 'react';

// ag Grid....

// ag Grid


export default function StockAlert() {

  const admindetail=JSON.parse(localStorage.getItem('admindata'))
  const [ltpstock,setLtpStock]=useState('')
  const [high,setHigh]=useState('')
  const [low,setLow]=useState('')
  const [stocklist,setStockList]=useState([])
  const [highlowstocklist,setHighLowStockList]=useState([])
  const [exist,seExist]=useState(0)
  const Swal = require('sweetalert2')
  const [error,setError]=useState({})


    const dispatch=useDispatch()
    const  refreshPage=()=>{
  
        window.location.reload(false);
      
      }
      
      
      const brokerrr=(bid,bnm)=>{
      
        var body={bro_id:bid,bro_name:bnm}
        var bi ='broker'
      
        dispatch({type:'brokeridset',payload:[bi,body]})
        
      
       
      }


      const getallstocklist=async()=>{

        var formdata=new FormData()
        
        formdata.append('admin_id',admindetail.user_id)
       
        var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=getadminwiseStock`,formdata)
        
        
  
        setStockList (result.data.data)
        
    
      }

      const getallhighlowstocklist=async()=>{
      
      
        var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=Getstockalert`)
        
        
  
        setHighLowStockList(result.data.data)
        
    
      }





      useEffect(()=>{

        getallstocklist()
        getallhighlowstocklist()
      
      },[])
      

      
      
   const showstocklist=()=>{

    return stocklist.map((val)=>{
 
     return (
 
       <option value={val.StockName} >{val.StockName}</option>
 
     )
 
 
    })
  }

  const showhighlow=()=>{

    return highlowstocklist.map((val)=>{
 
     return (
 
      <tr>

      <td align='center' >{val.STOCKS}</td>
      <td align='center'>{val.weekHigh}</td>
      <td align='center'>{val.weekLow}</td>

      </tr>
 
     )
 
 
    })
  }


  const handleerror=(input,value)=>{

    setError((prev)=>({...prev,[input]:value}))
    
  }
  

  

const validation=()=>{
  
  var isvalid=true
  
  
 
  if(ltpstock=="")
  {
    handleerror("ltpstock","Please Select Stock")
    isvalid=false
  }


  if(high=="")
  {
    handleerror("high","Please Enter 52 Week High")
    isvalid=false
  }
  else if(isNaN(high))
  {
    handleerror("high","Please Enter 52 Week High (Only Number)")
    isvalid=false
  }

  if(low=="")
  {
    handleerror("low","Please Enter 52 Week Low")
    isvalid=false
  }
  else if(isNaN(low))
  {
    handleerror("low","Please Enter 52 Week Low (Only Number)")
    isvalid=false
  }

  
  return isvalid

  
  }






 const checkexiststock=async(value)=>{

  const formdata=new FormData()
  formdata.append('stock_name',value)
  formdata.append('admin_id',admindetail.user_id)
  let result = await axios.post(`${URL}stockInfo&bpuser=10891&action=stockalertexit`,formdata)
  if(result.data.res_code===1)
  {
    seExist(1)
  }
  else
  {
    seExist(2)
  }
  
 }

 const  Submit=async()=>{

  if(low>high)
  {
    Swal.fire({
      icon: 'error',
      title: 'Alert',
      text: '52 week high must be greater than 52 week low',
    })
  
  }
else
{
  


  if(validation()){


    if(exist===1)
    {
    const formdata=new FormData()
    formdata.append('admin_id',admindetail.user_id)
    formdata.append("stock_name",ltpstock)
    formdata.append("weekhigh",high)
    formdata.append("weeklow",low)
    let result = await axios.post(`${URL}stockInfo&bpuser=10891&action=stock_alert`,formdata)
    if(result.data.res_code===1)
    {
      Swal.fire({
        icon: 'success',
        title: 'Alert',
        text: '52 week high/low update successfully',
      }).then((result) => {
        window.location.reload(false);
    })
    }
    else
    {
      Swal.fire({
        icon: 'error',
        title: 'Alert',
        text: 'Server error please try again',
      }).then((result) => {
        window.location.reload(false);
    })
    }
    
  
    // console.log('asasasasasasasasasasassasasasa',admindetail.user_id,ltpstock,high,low)
  }
  else if (exist===2)
  {
    const formdata=new FormData()
    formdata.append('admin_id',admindetail.user_id)
    formdata.append("stock_name",ltpstock)
    formdata.append("weekhigh",high)
    formdata.append("weeklow",low)
    let result = await axios.post(`${URL}stockInfo&bpuser=10891&action=stock_alert`,formdata)
   if(result.data.res_code===1)
    {
      Swal.fire({
        icon: 'success',
        title: 'Alert',
        text: '52 week high/low insert successfully',
      }).then((result) => {
        window.location.reload(false);
    })
    }
    else
    {
      Swal.fire({
        icon: 'error',
        title: 'Alert',
        text: 'Server error please try again',
      }).then((result) => {
        window.location.reload(false);
    })
  
    }
  
  }
  
  
  }
  else
  {
   console.log('check Validation')
  }
  
}

 }


  return (
    <>
    <div className="wrapper">
    <Header   refresh={refreshPage}   brokerr={brokerrr} />
    <div className="content-wrapper">
    <section className="content-header">
      <div className="container-fluid">
     
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1>Stock Alert</h1>
          </div>
          <div className="col-sm-4">
            <h1>Stock Alert List</h1>
          </div>
          <div className="col-sm-2">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="/Dashboard">Home</a></li>
              <li className="breadcrumb-item active">Add Stock Alert</li>
            </ol>
          </div>
        </div>
       

      </div>
    </section>
    <section className="content">
      <div className="container-fluid">
        
    <div className='row'>
    <div className="col-sm-6">
        <div className="card card-default">
         
         <div class="card-body" style={{ height: 440}} >


         

                  <div className="row">

                    <div className="col-sm-12">
                      
                      <div className="form-group">
                        
                        <label>Stock Name</label>
                        <select onFocus={()=>setError('ltpstock',null)}  onChange={(e)=>{setLtpStock(e.target.value);checkexiststock(e.target.value);}} className="form-control">
                          <option value={''} >--Select Stock--</option>
                          {showstocklist()}
                        </select>
                     
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      
                      {error.ltpstock} 
                      
                      </div>
                     
                    </div>

                    <div className="col-sm-12">
                      
                      <div className="form-group">
                        <label>52 week High</label>
                        <input onFocus={()=>setError('high',null)} onChange={(e)=>setHigh(e.target.value)} type="text" className="form-control" placeholder="Price Enter"/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      {error.high} 
                      </div>

                    </div>

                      <div className="col-sm-12">
                      
                      <div className="form-group">
                        <label>52 Week Low</label>
                        <input onFocus={()=>setError('low',null)} onChange={(e)=>setLow(e.target.value)}  type="text" className="form-control" placeholder="Price Enter"/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      {error.low} 
                      </div>
                    </div>



                  </div> 

                
                 

            

                </div>
                <div className="card-footer">
                  <button   onClick={()=>Submit()}  type="button" className="btn btn-block btn-primary" style={{width:'200px', float:'none', margin:'0 auto' }}>Save/Update</button>
              </div>
         </div>
        </div>


        {/* .............................master stock list */}
      
        <div className="col-sm-6" >
       
          
         
          <div className="card">
          {/* card-default */}
            
            
            <div class="card-body" style={{width: '100%', height: 500}}>
            <table width="100%" className='table table-bordered'>
 <thead>
    <tr>
      <th align='center' style={{textAlign:'center'}}>Stock Name</th>
      <th align='center' style={{textAlign:'center'}}>52 Week High</th>
      <th align='center' style={{textAlign:'center'}}>52 Week Low</th>
    </tr>
</thead>	
	
 <tbody>	  
    {showhighlow()}
  </tbody>
</table>

            </div>


          </div>




        </div>

        {/* .............................master stock list */}
        


    </div>
    </div>
    </section>
    </div>
    {/* ............... */}
    <div>

    
      
    </div>
    {/* ......................... */}
    <Footer/>
    </div>
    </>
  )
}

