import React, { useMemo } from "react";

import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { URL } from "../../service/url";
import CanvasJSReact from '@canvasjs/react-charts';
import mqtt from 'mqtt'
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'ag-grid-enterprise';




function BarChart(props) {


 

  var CanvasJS = CanvasJSReact.CanvasJS;
  var CanvasJSChart = CanvasJSReact.CanvasJSChart;
  const [showModal, setShowModal] = useState(false);
  const admindetail=JSON.parse(localStorage.getItem('admindata'))
  const [brokerid,setBrokerid]=useState()
  const [brokername,setBrokerName]=useState()
  const [brokertype,setBrokerType]=useState()
  const [capdata,setCapData]=useState([])
  const [currentholdingdatabackup,setCurrentHoldingDataBackUp]=useState([])
  const [fcapdata,setFCapData]=useState([])
  // console.log('capdata',capdata)




useEffect(()=>{

  setBrokerid(props.body[0].brokerid)
  setBrokerName(props.body[0].brokername)
  setBrokerType(props.body[0].brokertype)


},[props])

  

    // .............................................CURRENT HOLDING... 


const getholdingdata=async()=>{

 
  
  var formdata=new FormData()
  formdata.append('bro_id',brokerid)
  formdata.append('admin_id',admindetail.user_id)




  var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=getMarketCapDetails`,formdata)
   
 
 
  setCapData(result.data.data)

  //  for (let i=0 ; i<finaldata.length; i++){

  //      capiname.push([finaldata[i].Type])
  //      capivalue.push(finaldata[i].counts)

  //  }

  // setCapName(capiname)
  // setCapValue(capivalue)
 }



// .........................................END CURRENT HOLDING...





  useEffect(()=>{

      getholdingdata()



  },[brokerid])


  const handlePointClick = (e) => {

  

    if (e.dataPoint) {



      console.log(e.dataPoint)

      if(e.dataPoint.label==='Large')   
      {

        const large=currentholdingdatabackup.filter((val)=>val.MARKET_CAP==='LARGE' && val.INVESTED_HOLDING!=0)


        setFCapData(large)
      }
      else if (e.dataPoint.label==='Middle')
      {
        const middle=currentholdingdatabackup.filter((val)=>val.MARKET_CAP==='MIDDLE' && val.INVESTED_HOLDING!=0)
        setFCapData(middle)
      }
      else if (e.dataPoint.label==='Small') 
      {
        const small=currentholdingdatabackup.filter((val)=>val.MARKET_CAP==='SMALL' && val.INVESTED_HOLDING!=0)
        setFCapData(small)
      }

      setShowModal(true)

     
     
    }
    else
    {
      console.log('Sorry no data found.')
    }
  };
  




  const options = {
    responsive: true,
    
    data: [{				
      type: "column",
      indexLabel: "{label}: {y} %",

      dataPoints: [
       
        ...capdata
      ]
      ,
      click: handlePointClick,
    }]
  }


  
  // mqtt...code .................................
 
  

  const MQTT_HOST = 'wss://test.mosquitto.org:8081';


  const [MQTT_TOPIC,setMqtt_Topic]=useState(`testtopic/amitjha/2222`)
 
  const [mqttClient, setMqttClient] = useState(null);
  const [mqttdata,setMqttData]=useState([])







  useEffect(()=>{

     const zeronodata=mqttdata.filter((val)=>val.QTY !==0)
   
    
     setCurrentHoldingDataBackUp(mqttdata)


  },[mqttdata])



  useEffect(()=>{
  
  
    if(brokerid===undefined || brokerid===null)
      {
        setMqtt_Topic(`testtopic/amitjha/2222`)
      }
    else
    {
       setMqtt_Topic(`testtopic/amitjha/${brokerid}`)
    }

  },[brokerid])


  

useEffect(() => {
  const client = mqtt.connect(MQTT_HOST);

  client.on('connect', () => {

    setMqttClient(client);

    client.subscribe(MQTT_TOPIC);

  });

  return () => {

    client.end(); 

  };

}, [MQTT_TOPIC]);






useEffect(() => {
  

  const handleMessage = (topic, message) => {

    setMqttData(JSON.parse(message.toString()));
    
 
  };

  if (mqttClient) {
    mqttClient.on('message', handleMessage);
  }

  return () => {
    if (mqttClient) {
      mqttClient.off('message', handleMessage); 
    }
  };

}, [mqttClient]); 






const Modal = () => {


  const [columnDefs, setColumnDefs] = useState([

    { 
      field: 'STOCKS', 
      resizable: true ,   
      suppressSizeToFit: true ,
   
      minWidth:250,
      cellStyle: { textAlign: 'left' },
      
    },
    { 
      field: 'INVESTED_HOLDING',
      resizable: true,
      suppressSizeToFit: true ,
      cellStyle: { textAlign: 'center' },
      minWidth:250, 
    }
    
  
  ]);

  // DefaultColDef sets props common to all Columns
  
  const defaultColDef = useMemo(() => ({

    resizable: true,
    sortable: true,
    filter:true,
    floatingFilter:true,
    cellRenderer:false,
    flex:1,
    maxWidth:400,
    suppressSizeToFit: true

  }), []);


  const Doempty=()=>{


  


  }


  return ( 
   
    <div className={`modal fade ${showModal ? 'show d-block' : ''}`} id="exampleModalToggle" aria-hidden="true" tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>

      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">

        <div className="modal-content">

          <div className="modal-header">

          <h5 className="modal-title">Market Cap</h5>

          
           
            <button  type="button" style={{background:'#ffff',border:'none',padding:'10px'}} aria-label="Close" onClick={() => {setShowModal(false)
             Doempty()
            }}>
             
             
              <img  style={{width:'20px'}}  src='../assets/cancel.png'/>

            </button>

          </div>

          <div className="modal-body">
          

              <div className="ag-theme-alpine" style={{ height: '400px', width: '765px' }}>
              <AgGridReact
                rowData={fcapdata}
                columnDefs={columnDefs} 
                defaultColDef={defaultColDef} 
              />
              </div>

    

          </div>

         
        </div>


      </div>


    </div>


  );

};









  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              <h4 className="card-title">Market Cap</h4>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="mtable4">
            <div className="container-fluid" >
                        
             
             <CanvasJSChart options = {options}    />
            

            </div>
          </div>
        </div>
        <Modal/>
      </div>
    </>
  );
}

export default BarChart;
