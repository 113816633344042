// Install dependencies: npm install react-dnd react-dnd-html5-backend
// import React, { useState, useCallback } from 'react';
// import { useDrag, useDrop, DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';

// const FormFieldTypes = [{ type: 'text', label: 'Text Input' },{ type: 'textarea', label: 'Textarea' },{ type: 'checkbox', label: 'Checkbox' },
// ];

// const DraggableFormField = ({ type, label }) => {
//   const [{ isDragging }, drag] = useDrag({
//     type: 'FORM_FIELD',
//     item: { type },
//     collect: (monitor) => ({
//       isDragging: !!monitor.isDragging(),
//     }),
//   });

//   return (
//     <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move', padding: '8px', border: '1px solid #ccc', marginBottom: '5px' }}>
//       {label}
//     </div>
//   );
// };

// const FormPreview = ({ fields, onDrop }) => {
//   const [, drop] = useDrop({
//     accept: 'FORM_FIELD',
//     drop: onDrop,
//   });

//   return (
//     <div ref={drop} style={{ border: '1px dashed #ccc', minHeight: '200px', padding: '10px' }}>
//       {fields.map((field, index) => (
//         <div key={index}>
//           {field.type === 'text' && <input type="text" placeholder="Text Input" />}
//           {field.type === 'textarea' && <textarea placeholder="Textarea"></textarea>}
//           {field.type === 'checkbox' && <label><input type="checkbox" /> Checkbox</label>}
//         </div>
//       ))}
//     </div>
//   );
// };

// const FormBuilder = () => {
//   const [formFields, setFormFields] = useState([]);

//   const handleDrop = useCallback(
//     (item) => {
//       setFormFields((prevFields) => [...prevFields, { type: item.type }]);
//     },
//     [setFormFields]
//   );

//   return (
//     <DndProvider backend={HTML5Backend}>
//       <div>
//         <h2>Form Builder</h2>
//         <div style={{ display: 'flex' }}>
//           <div>
//             <h3>Available Fields</h3>
//             {FormFieldTypes.map((field) => (
//               <DraggableFormField key={field.type} {...field} />
//             ))}
//           </div>
//           <div>
//             <h3>Form Preview</h3>
//             <FormPreview fields={formFields} onDrop={handleDrop} />
//           </div>
//         </div>
//       </div>
//     </DndProvider>
//   );
// };

// export default FormBuilder;


// import React, { useState } from 'react';

// const DraggableComponent = () => {
//   const [isDragging, setIsDragging] = useState(false);
//   const [attributeValue, setAttributeValue] = useState('');
//   const [showPopup, setShowPopup] = useState(false);

//   const handleDragStart = (event) => {
//     event.dataTransfer.setData('text/plain', 'draggable');
//     setIsDragging(true);
//   };

//   const handleDrop = () => {
//     setIsDragging(false);
//     setAttributeValue('some-default-value');
//     setShowPopup(true);
//   };

//   const setAttribute = () => {
//     // Set the attribute value based on user input
//     // You may want to add validation and error handling here
//     setAttributeValue(document.getElementById('attributeInput').value);
//     setShowPopup(false);
//   };

//   return (
//     <div>
//       <div draggable onDragStart={handleDragStart} onDragOver={(event) => event.preventDefault()}  onDrop={handleDrop} style={{background:'red'}}>
      
//       </div>

//       {showPopup && (
//         <div className="popup">
//           <label htmlFor="attributeInput">Attribute Value:</label>
//           <input type="text" id="attributeInput" value={attributeValue} onChange={(e) => setAttributeValue(e.target.value)} />
//           <button onClick={setAttribute}>Set Attribute</button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default DraggableComponent;

import React, { useState } from 'react';

const DraggableComponent = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const handleDragStart = (event) => {
    event.dataTransfer.setData('text/plain', 'draggable');
    setIsDragging(true);
  };

  const handleDrop = () => {
    setIsDragging(false);
    setShowPopup(true);
  };

  const setAttribute = () => {
    // Set the attribute value based on user input
    // You may want to add validation and error handling here
    setInputValue(document.getElementById('inputElement').value);
    setShowPopup(false);
  };

  return (
    <div>
      <div
        draggable
        onDragStart={handleDragStart}
        onDragOver={(event) => event.preventDefault()}
        onDrop={handleDrop}
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px' }}
      >
        <input
          type="text"
          id="inputElement"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          style={{ width: '100%' }}
        />
      </div>

      {showPopup && (
        <div className="popup" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', padding: '20px', border: '1px solid #ccc', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' }}>
          <label htmlFor="attributeInput">Attribute Value:</label>
          <input type="text" id="attributeInput" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
          <button onClick={setAttribute}>Set Attribute</button>
        </div>
      )}
    </div>
  );
};

export default DraggableComponent;
