import React from 'react'

export default function Footer() {
  return (
   <>
   <div>
  <aside className="control-sidebar control-sidebar-dark">
    {/* Control sidebar content goes here */}
  </aside>
  <footer className="main-footer">
    <strong>Copyright © 2023-2024 <a href="https://techwaveitsolutions.com">Techwave IT Solutions Pvt. Ltd</a>.</strong>
    All rights reserved.
    <div className="float-right d-none d-sm-inline-block">
      <b>Version</b> 3.2.0
    </div>
  </footer>
</div>

   
   </>
  )
}
