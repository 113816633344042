
import { Chart } from "react-google-charts";

export const data = [
    ["Task", "Hours per Day"],
    ["Software & IT Serivces",19 ],
    [ "Machinery Equipment & Components",19],
    ["Automobiles & Auto Parts",9 ],
    ["Oil & Gas",1 ],
    ["Construction & Engineering",5 ],
    ["Paper & Forest Products",4 ],
    [ "Banking Services",16 ],
    [ "Aerospace & Defense",18 ],

  ];
  
  export const options = {
    title: "Holding",
  };
  




  
export function Piechart() {








    return (
      <Chart chartType="PieChart"  data={data}  options={options}  width={"100%"} height={"400px"}
      />
    );
  }
  

