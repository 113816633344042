
// const URL='http://192.168.2.2/raj/app/stocktrading/api.php?s='
// export {URL}

// const IMGURL='http://192.168.2.2/raj/app/stocktrading/api.php?s='
// export {IMGURL}


const URL='https://practice.concorderp.com/api.php?s='
export {URL}

const IMGURL='https://practice.concorderp.com/'
export {IMGURL}


// const JSONURL='http://localhost:3000/industry'
// export {JSONURL}

// const JSONURL='http://localhost:3000/industry'
// export {JSONURL}

