import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import {Link} from 'react-router-dom'
import { useState,useMemo,useCallback,useRef,useEffect } from 'react'
import axios from 'axios'
import { URL } from '../service/url'
// ...........................................
import { AgGridReact } from 'ag-grid-react' // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css' // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css' // Optional theme CSS
import 'ag-grid-enterprise'
import { useLocation } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";






export default function ViewStatement() {

  const location=useLocation()
  const Mbrokerid=parseInt(location.search.slice(1))
  const admindetail=JSON.parse(localStorage.getItem('admindata'))
  const [broker,setBroker]=useState('')
  const [brokerdata,setBrokerdata]=useState([])
  // const [fromdate,setFromDate]=useState()
  // const [todate,setToDate]=useState()
  // const [mfromdate,setMFromDate]=useState()
  // const [mtodate,setMToDate]=useState()

  const [datewisedata,setDateWiseData]=useState([])
  const [mdatewisedata,setMDateWiseData]=useState([])

  console.log(datewisedata,'datewisedata')
  console.log(mdatewisedata,'mdatewisedata')
 


// new date component ........................................

const [fromDate,setFromDate] = useState(new Date());
const [toDate,setToDate] = useState(new Date());
const [mfromDate,setMFromDate] = useState(new Date());
const [mtoDate,setMToDate] = useState(new Date());

// new date component ........................................
 





  // ......................................................
  const gridRef = useRef(); 

  const columnDefs = useMemo(() => [
    { field: 'trasdate',width: 100, suppressSizeToFit: true, headerTooltip: 'Stocks' , cellStyle: { textAlign: 'left' } },
    { field: 'payment_mode',width: 80, suppressSizeToFit: true , cellStyle: { textAlign: 'left' }  },
    { field: 'cr_amnt',width: 80, suppressSizeToFit: true , cellStyle: { textAlign: 'left' }  },
    { field: 'dr_amnt',width: 80, suppressSizeToFit: true , cellStyle: { textAlign: 'left' }  },
  
  ], []);
  
  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true,
    filter:true,
    floatingFilter:true,
    cellRenderer:false,
    flex:1,
    minWidth: 100,
    editable:true,
    suppressSizeToFit: true,
    sortable: true,
  // flex:1
  }), []);
  

  // ................................


  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  const getdatadatewise = async()=>{


   let fd=formatDate(fromDate)
   let td=formatDate(toDate)
    
  // console.log(formatDate(fromDate),'fromDate')
  // console.log(formatDate(toDate),'toDate')
 

  
    var formdata=new FormData()
    formdata.append('from_date',fromDate)
    formdata.append('to_date',toDate)
    formdata.append('broker_id',broker)
    formdata.append('admin_id',admindetail.user_id) 
    var result= await axios.post(`${URL}stockfund&bpuser=10891&action=viewStatement`,formdata)
    setDateWiseData(result.data.data)

  }


  // useEffect(()=>{
   
  //   getdatadatewise()

  // },[fromdate,todate])



  const getmdatadatewise = async()=>{


    let mfd=formatDate(mfromDate)
    let mtd=formatDate(mtoDate)
    // console.log(formatDate(mfromDate),'mfromDate')
    // console.log(formatDate(mtoDate),'mtoDate')

    var formdata=new FormData()
    formdata.append('from_date',mfromDate)
    formdata.append('to_date',mtoDate)
    formdata.append('broker_id',broker)
    formdata.append('admin_id',admindetail.user_id) 
    var result= await axios.post(`${URL}stockfund&bpuser=10891&action=viewStatementmtf`,formdata)
    setMDateWiseData(result.data.data)

  }


  
  // const getdate=()=>{
    
  //   let  dates = document.getElementById('reservation').value
  //   let  fromdate=dates.split('-')
  //   setFromDate(fromdate[0])
  //   setToDate(fromdate[1])
  //   getdatadatewise()

  // }


  // const getMdate=()=>{
    
  //   let  dates = document.getElementById('reservationone').value
  //   let  fromdate=dates.split('-')
  //   setMFromDate(fromdate[0])
  //   setMToDate(fromdate[1])
  //   getmdatadatewise()

  // }


  // useEffect(()=>{
   
  //   getmdatadatewise()

  // },[mfromdate,mtodate])



  
 // ...............api get broker 

 const getbroke=async()=>{
  var formdata=new FormData()
  formdata.append('admin_id',admindetail.user_id) 
  const result=await axios.post(`${URL}brokerinfo&bpuser=10891&action=getbrokers`,formdata)
  setBrokerdata(result.data.data)
  
  }



  
  useEffect(()=>{
  
    getbroke()
   
   },[])




  // ........................api get broker end function 




  
  


const showtable=()=>{

return datewisedata.map((val)=>{


  return (

    <tr>
                   
      <td>{val.trasdate}</td>
      <td className='tcolor_red'>{val.payment_mode}</td>
      <td className='tcolor_green'>₹ {val.cr_amnt}</td>
      <td className='tcolor_blue'>₹ {val.dr_amnt}</td>
              
    </tr>
      
  )


})}

const showmtable=()=>{

  return mdatewisedata.map((val)=>{
  
  
    return (
            
      <tr>
                
        <td>{val.trasdate}</td>
        <td className='tcolor_red'>{val.payment_mode}</td>
        <td className='tcolor_green'>₹ {val.cr_amnt}</td>
        <td className='tcolor_blue'>₹ {val.dr_amnt}</td>
          
      </tr>
        
    )
  
  
  
  })}

 


var drtotal=0
var crtotal=0
  
datewisedata.map((vali)=>{

  if(vali.dr_amnt==0 ) 
  {

    crtotal+=parseInt(vali.cr_amnt)
  }
   
  else
  {
      drtotal+=parseInt(vali.dr_amnt)
    
    }


})




var mdrtotal=0
var mcrtotal=0
  
mdatewisedata.map((vali)=>{

  if(vali.dr_amnt==0 ) 
  {

    mcrtotal+=parseInt(vali.cr_amnt)
  }
   
  else
  {
      mdrtotal+=parseInt(vali.dr_amnt)
    
    }




})







const showbroker=()=>{

  return  brokerdata.map((val)=>{
  
    return (
       <><option value={val.user_id} >{val.user_name}</option></>
    ) })
 
 }



 



  return (
    <>
<div className="wrapper">
  <Header/>
  <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>
              View Statment
              </h1>

              <div className="row" style={{marginTop:'2vh'}}>

                <div className="col-sm-6">
                  
                  <div className="form-group">
                    
                    <select    onChange={(e)=>setBroker(e.target.value)}  className="form-control">
                      <option value={null} >--Select Account--</option>
                      {showbroker()}
                    </select>

                  </div>
                  
                </div>

              </div>


            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><Link to="/Funds">Funds</Link></li>
                <li className="breadcrumb-item active">View Statment</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid">
        <div className="row" >


        <div className="col-12 col-sm-6">
        
          <div className="card card-primary card-outline card-outline-tabs">
             
            <div className="card-body" >
            
              <div className='row'  style={{paddingBottom:'20px',display:'flex',justifyContent:'center'}}>

                {/* date picer ..............................................................................NML */}

                <div className='col-md-2'>

                  {/* <div className="input-group" style={{width:'350px'}}>
                  <div className="input-group-prepend">
                  <strong className='mr-2'>Select Date</strong> 
                  <span className="input-group-text">
                    <i className="far fa-calendar-alt"></i>
                  </span>
                  </div>
                  <input type="text" className="form-control float-right" id="reservation" />
                  </div> */}

                  <DatePicker selected={fromDate} onChange={(date) => setFromDate(date)} />
                  
                </div> &nbsp;
                -
                <div className='col-md-2' style={{display:'flex'}}>

                  <DatePicker selected={toDate} onChange={(date) => setToDate(date)} />
                  
                </div>
                <div  className='col-md-2' >
                  
                  <button  onClick={()=>getdatadatewise()} > Search</button>

                </div>
                <div className='col-md-2' style={{fontSize:'20px'}}  >

                  NML

                </div>
               

                {/* date picer ..............................................................................NML */}
                
              </div>


              <div className="ag-theme-alpine" style={{width: '100%', height: 550}}>
                <AgGridReact className="ag-theme-alpine"

                  rowData={datewisedata}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  enableRangeSelection="true"
                  enableCharts={true}
                  animateRows="true"
                  rowSelection="multiple"
                  suppressRowClickSelection="true"
                  pagination={false}
                  // onRowClicked={handleRowClicked}

                />
              </div>
         
              
            </div>

              
             
          </div>

        </div>


          {/* .....................................................************************** */}



        <div className="col-12 col-sm-6">

          <div className="card card-primary card-outline card-outline-tabs">
                    
            <div className="card-body">

             
              <div className='row'  style={{paddingBottom:'20px',display:'flex',justifyContent:'center'}} >
                
                {/* date picer ..............................................................................MTF */}

                <div className='col-md-2' >

                  {/* <div className="input-group" style={{width:'350px'}}>
                  <div className="input-group-prepend">
                  <strong className='mr-2'>Select Date </strong> 
                  <span className="input-group-text">
                  <i className="far fa-calendar-alt"></i>
                  </span>
                  </div>
                  <input type="text" className="form-control float-right" id="reservationone" />
                  </div> */}

                  <DatePicker selected={mfromDate} onChange={(date) => setMFromDate(date)} />

                </div> &nbsp;
                  -
                <div  className='col-md-2' >

                  <DatePicker selected={mtoDate} onChange={(date) => setMToDate(date)} />

                </div>

                <div className='col-md-2'  >

                  <button onClick={()=>getmdatadatewise()} >Search</button>
                  
                </div>

                <div className='col-md-2' style={{fontSize:'20px'}}  >

                  MTF

                </div>


                {/* date picer ..............................................................................MTF */}                
                    
                      
              </div>


              <div className="ag-theme-alpine" style={{width: '100%', height: 550}}>
                <AgGridReact className="ag-theme-alpine"

                  rowData={mdatewisedata}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  enableRangeSelection="true"
                  enableCharts={true}
                  animateRows="true"
                  rowSelection="multiple"
                  suppressRowClickSelection="true"
                  pagination={false}
                  // onRowClicked={handleRowClicked}

                />
              </div>


              
                      
            </div>
          
          </div>

        </div>


      </div> 




    </div>
  



  </div>
        
  <Footer/>

</div>
    
    </>
  )
}
