import React, { useEffect, useState } from 'react'
import Holding from './rkp/Holding'
import CurrentHolding from './rkp/CurrentHolding'
import IndustriesHolding from './rkp/IndustriesHolding'

//import SmallCap from './rkp/Market Cap/SmallCap'
//import MiddleCap from './rkp/Market Cap/MiddleCap'
//import LargeCap from './rkp/Market Cap/LargeCap'
import TopGainers from './rkp/TopGainers'
import TopLosers from './rkp/TopLosers'
import Summery_Update from './rkp/Summery_Update'
import MarketCap from './rkp/Market Cap/MarketCap'
import Header from './components/Header'
import Footer from './components/Footer'
import Consolidated from './Consolidated'
import ConsolidatedAllinOneSingleScript from './ConsolidatedAllinOneSingleScript'
import CurrentHoldingSingleScriptAccount from './rkp/CurrentHoldingSingleScriptAccount'
import CurrentHoldingMultipleScriptAccount from './CurrentHoldingMultipleScriptAccount'
import Chatbox from './Chatbox'
import CurrentHoldingForm from './CurrentHoldingForm'

import { useNavigate } from 'react-router-dom'


import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'



///////////////////

//import Summery from './rkp/Summery'

export default function Dashboard() {

  

const dispatch=useDispatch()
const admin=JSON.parse(localStorage.getItem('admindata'))


const [brokerid,setBrokerid]=useState(null)
const [brokername,setBrokerName]=useState(admin.user_name)
const [brokertype,setBrokerType]=useState(admin.user_type)
// const [popupopen,setPopupOpen]=useState('1')


const body=[{brokerid:brokerid,brokername:brokername,brokertype:brokertype}]

const [brokerdata,setBrokerData]=useState([])
const [currentholding,setCurrentHolding]=useState([]) 








const  refreshPage=()=>{
  
  window.location.reload(false);

}


var navigate=useNavigate()

useEffect(()=>{
  
  

  if(!admin)
  {
    navigate('/')

  }
  // if(popupopen)
  // {

  // }
  
},[])


const broker=(bid,bnm,btp)=>{
  
  var body={bro_id:bid,bro_name:bnm,bro_type:btp}

  var bi ='broker'

  dispatch({type:'brokeridset',payload:[bi,body]})
  
}


var bro  = useSelector((state)=>state.broker) 

useEffect(()=>{

if(!bro.broker)
{
  setBrokerid(null)
  
}
else
{
  setBrokerid(bro.broker.bro_id)
  setBrokerName(bro.broker.bro_name)
  setBrokerType(bro.broker.bro_type)

}
  
},[bro.broker])



// var myHeaders = new Headers();
// myHeaders.append("Authorization","IWsk4fTaRF9q1sdNfqWT2wl2XpYa:luI2vQx_rwCirLYt2sAKXmIN1IQa");

// var urlencoded = new URLSearchParams();
// urlencoded.append("grant_type", "password");
// urlencoded.append("username", "client12728");
// urlencoded.append("password", "jJjrpYJv");

// var requestOptions = {
//   method: 'POST',
//   headers: myHeaders,
//   body: urlencoded,
//   redirect: 'follow'
// };

// fetch("https://napi.kotaksecurities.com/oauth2/token", requestOptions)
//   .then(response => response.text())
//   .then(result => console.log('resulttttttt',result))
//   .catch(error => console.log('errorRRRR', error));









  return (
    <>
   
<div className="wrapper">
    
   
     <Header  refresh={refreshPage}   brokerr={broker}   />

     {/* dark-mode */}
   
   <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <div className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1 className="m-0" style={{fontSize:"16px"}}>{brokername}</h1>
        </div>{/* /.col */}

        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="/Dashboard">Home</a></li>
            <li className="breadcrumb-item active">{brokername}</li>
          </ol>
        </div>{/* /.col */}
      </div>{/* /.row */}
    </div>{/* /.container-fluid */}
  </div>

  {/* /.content-header */}
  {/* Main content */}
  <div className="content">
    <div className="container-fluid">

   

  
   <Summery_Update body={body} />


      <div className="row">
        <div className='col-md-4'>
      
          <Consolidated  body={body}  />

        </div>
        <div className="col-8">
                
          <CurrentHolding  body={body}  />
             
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">PORTFOLIO BY INDUSTRY</h3>
            </div>
          </div>
        </div>
      </div>
      {/* /.row */}
      <div className="row">
        <div className="col-md-6">
                <IndustriesHolding  body={body}  />
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-body" style={{height:'470px', paddingTop:'30px'}}>
             <Holding   body={body}  />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {/* <div className="col-12">
          <h4>Market Cap</h4>
        </div> */}
      </div>
      <div className="row">
        <div className="col-md-4">

         <MarketCap  body={body} />
        
        </div>
        <div className="col-md-8">
        <div className="row">                                        
        <div className="col-lg-6">    
          <TopGainers  body={body}  />      
        
        </div>{/*end col*/}
        <div className="col-lg-6">   

         <TopLosers  body={body}    />

        </div>{/*end col*/}
      </div>

        
        </div>
      
      </div>
      {/* /.row */}
      

    </div>
    {/* /.container-fluid */}
  </div>
  {/* /.content */}
  <div className="modal fade" id="modal-xl">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">CURRENT HOLDING</h4>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mtable">
            
           
    
          
              <div className='row'>
                
              <div className="col-sm-6">
              <CurrentHoldingForm  body={body}  />
              </div>

                <div className='col-sm-6'>
                 <Chatbox     />
                </div>


          </div>

            <p>&nbsp;</p>
            <div className="mtable3">
            <CurrentHoldingSingleScriptAccount/>
            </div>
            <p/>    
           
            <br/>

            <div className="mtable2">
           <CurrentHoldingMultipleScriptAccount/>
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-between">
          <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
          
        </div>
      </div>
      {/* /.modal-content */}
    </div>
    {/* /.modal-dialog */}
  </div>
  {/* /.modal */}






  <ConsolidatedAllinOneSingleScript/>
  {/* /.modal */}
</div>
<Footer/>
</div>
     

    </>
  )
}

