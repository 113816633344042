import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
//import { createRoot } from 'react-dom/client';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'ag-grid-enterprise';

export default function ConsolidatedAllinOneSingleScript() {


    const columnDefs = useMemo(() => [
        { field: 'SNO' },
        { field: 'ACCOUNT NAME'},
        { field: 'QTY'},
        { field: 'RATE'},
        { field: 'LTP'},
        { field: 'HOLDING'},
        { field: 'MARKET VALUE'},
        { field: 'UNREALISED'},
        
    ], []);
    
    
    const defaultColDef = useMemo(() => ({
      resizable: true,
      sortable: true,
      flex:1
    }), []);
    
    const [rowData, setRowData] = useState();
    
    useEffect(() => {fetch('/stock_trading_api/consolidatedallinonesinglescript.json').then(result => result.json()) .then(rowData => setRowData(rowData))
          .catch(error => console.error('Error fetching data:', error));
    }, []);


  return (
    <div className="modal fade" id="modal-xll">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">ALL ACCOUNT'S SCRIPTS TOTAL</h4>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mtable">
          
           
            <div className="mtable2">
            <div className="ag-theme-alpine" style={{width: '100%', height: 300}}>
                <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        enableRangeSelection="true"
                        enableCharts={true}
                        className="ag-theme-alpine"
                        animateRows={true}
                        rowSelection="multiple"
                        suppressRowClickSelection="true"
                        pagination={true}

                    />
        </div>
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-between">
          <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
          {/* <button type="button" className="btn btn-primary">Save changes</button> */}
        </div>
      </div>
      {/* /.modal-content */}
    </div>
    {/* /.modal-dialog */}
  </div>
  )
}
