
  


import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios'; // Import Axios
import Header from './components/Header'
import Footer from './components/Footer'
import { URL } from './service/url';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux'


function ExcelUpload() {


  const dispatch=useDispatch()
  const admin=JSON.parse(localStorage.getItem('admindata'))

  const [broker,setBroker]=useState('')
  const [brokerdata,setBrokerdata]=useState([])
  const getbroke=async()=>{
    var formdata=new FormData()
    formdata.append('admin_id',admin.user_id)
    const result= await axios.post(`${URL}brokerinfo&bpuser=10891&action=getbrokers`,formdata)
    setBrokerdata(result.data.data)
 
    }
    useEffect(()=>{
      getbroke()
    },[])
    const showbroker=()=>{
      return  brokerdata.map((val)=>{
          return (
              <>
              <option value={val.user_id} >{val.user_name}</option>
            </>
    ) })}

  const navigate = useNavigate();
  // const URL = 'http://192.168.2.2/raj/app/stocktrading/api.php?s=';
  const Swal = require('sweetalert2')
  const [jsonData, setJsonData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [titleData, setTitleData] = useState({
    "Date / Time" : "" ,
    "Particulars" : "",
    "Fund Type" : "",
    "Deal Mode" : "",
    "Type" : "",
    "Quantity" : "",
    "Buy Rate" : "",
    "Avg Rate" : "",
    "Stt Charges" : "",
    "Sebi Charges" : "",
    "Stamp Duty" : "",
    "Brokerage Charge" : "",
    "Remark" : ""
});


const handleFileUpload = (e) => {

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, {
        raw: false,
      });
      const headingMappings = {
        "Date / Time" : "buy_sell_date" ,
        "Particulars" : "st_name",
        "Fund Type" : "fund_type",
        "Deal Mode" : "st_deal_type",
        "Type" : "st_trans_type",
        "Quantity" : "st_qty",
        "Buy Rate" : "st_price",
        "Avg Rate" : "sta_price",
        "Stt Charges" : "stt_paid",
        "Sebi Charges" : "trans_charge_sebi",
        "Stamp Duty" : "stamp_duty",
        "Brokerage Charge" : "broker_charge",
        "Remark" : "st_remark"
      };

    const usernamesArray = brokerdata.map((user) => user.user_id);

    const array =usernamesArray.forEach((val)=>{
      return val;
    });
      
    const keysExcel = Object.keys(headingMappings);
    const keysTittle = Object.keys(titleData);

     

    if(keysExcel[0]!==keysTittle[0])
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong Date is invalid!',
      }).then((result) => {
        navigate('/excel')
      })

      return false;

      window.location.reload();

    }
    else if(keysExcel[1]!==keysTittle[1])
    {
      // alert(" Particulars invalid")
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong Particulars invalid !',
      }).then((result) => {
        navigate('/excel')
      })
      return false;

    }
    else if(keysExcel[2]!==keysTittle[2])
    {
      // alert(" Particulars invalid")
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong Fund Type invalid !',
      }).then((result) => {
        navigate('/excel')
      })
      return false;
    }
    else if(keysExcel[3]!==keysTittle[3])
    {
        Swal.fire("Deal Mode invalid")
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong Deal Mode invalid !',
        }).then((result) => {
          navigate('/excel')
      })
      return false;

    }
    else if(keysExcel[4]!==keysTittle[4])
    {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong Type invalid !',
        }).then((result) => {
          navigate('/excel')
        })
        return false;
    }
    else if(keysExcel[5]!==keysTittle[5])
    {
      Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong Quantity invalid !',
        }).then((result) => {
          navigate('/excel')
       })
      return false;
    }
    else if(keysExcel[6]!==keysTittle[6])
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong Buy Rate invalid!',
      }).then((result) => {
        navigate('/excel')
      })
    
      return false;

    }
      else if(keysExcel[7]!==keysTittle[7])
      {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong Avg Rate invalid!',
        }).then((result) => {
          navigate('/excel')
      })
      return false;
      }
      else
      if(keysExcel[8]!==keysTittle[8])
      {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong Stt Charges invalid!',
        }).then((result) => {
          navigate('/excel')
      })
      return false;
      }else
      if(keysExcel[9]!==keysTittle[9])
      {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong Sabi Charges invalid!',
      }).then((result) => {
        navigate('/excel')
    })
      return false;
      }
      else
      if(keysExcel[10]!==keysTittle[10])
      {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong Stamp Duty invalid!',
        }).then((result) => {
          navigate('/excel')
      })
      return false;
      }else
      if(keysExcel[11]!==keysTittle[11])
      {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong Brokerage Charge invalid!',
        }).then((result) => {
          navigate('/excel')
      })
      return false;
      }
      else
      if(keysExcel[12]!==keysTittle[12])
      {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong Remark invalid!',
        }).then((result) => {
          navigate('/excel')
      })
      return false;
      }
      const parsedData = jsonData.map((item) => {
        const transformedItem = {};
        for (const originalHeading in item) {
          if (originalHeading in headingMappings) {
            const desiredKey = headingMappings[originalHeading];
            transformedItem[desiredKey] = item[originalHeading];
          } else {
          }
        }
        return transformedItem;
      });
      setJsonData(parsedData);
      setFormData(parsedData);
    };
    reader.readAsArrayBuffer(file);
  };
  
  const handleInputChange = (name, value, rowIndex) => {
    const updatedData = [...formData];
    //updatedData[rowIndex].b_admin_id = admin.user_id;
    updatedData[rowIndex] = {
      ...updatedData[rowIndex],
      [name]: value,
     
    };
    //console.log(updatedData);
    setFormData(updatedData);
  };


function validation(){
  return true;


}




const sendDataToAPI = async () => {
  if (!broker) {
    Swal.fire('Please select a Account before submitting.');
    return false;
  }

  // Check for duplicate data
  // const duplicateData = formData.filter((item) =>
  //   jsonData.some((existingItem) =>
  //     Object.keys(item).every(
  //       (key) =>
  //         item[key] === existingItem[key] && key !== "b_admin_id" && key !== "broker_id"
         
  //     )
   
  //   )
  // );
  //      //&& key !== "buy_sell_date"&& key !== "st_name" && key !== "st_deal_type"
  //     //  &&  key !== "st_trans_type"&& key !== "st_qty"&& key !== "st_price"
  //     //  && key !== "stt_paid"&& key !== "trans_charge_sebi"
  //     //  && key !== "stamp_duty"
  //     //  && key !== "broker_charge"&& key !== "st_remark"
  // if (duplicateData.length > 0) {
  //   Swal.fire({
  //     icon: "warning",
  //     title: "Duplicate Data",
  //     text: "Some of the data you're trying to submit is already in the database.",
  //   });
  //   return false;
  // }

  const newObj = { ...formData };
  newObj.b_admin_id = admin.user_id;

  if (validation()) {
   
    const requestBody = JSON.stringify({
      Tablename: "stockinfo_log",
      b_admin_id: admin.user_id,
      broker_id: broker,
      body: formData,
    });

    try {
      const result = await axios.post(`${URL}stockInfoexcel&bpuser=10891&action=stockDetailInsertCheck`,requestBody );

      if (result.data.res_code === 1) {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Add Stock Successfully",
          showConfirmButton: false,
          timer: 1600,
        }).then((result) => {
          navigate("/Dashboard");
        });
      }
      else if(result.data.res_code === 2)
      {

        Swal.fire({
          position: "top-center",
          icon: "alert",
          title: result.data.msg,
          showConfirmButton: false,
          timer: 1600,
        }).then((result) => {
          navigate("/excel");
        });



      } else {
        // Handle other cases, e.g., when the submission was not successful
      }
    } catch (error) {
      
      console.error("Error submitting data:", error);
      // Handle the error appropriately, e.g., show an error alert

    }
  }
};








  const keys = Object.keys(titleData);

  const  refreshPage=()=>{
  
    window.location.reload(false);
  
  }
  
  
  const brokerrr=(bid,bnm)=>{
  
    var body={bro_id:bid,bro_name:bnm}
    var bi ='broker'
  
    dispatch({type:'brokeridset',payload:[bi,body]})
    
    // setBrokerid(bid)
    // setBrokerName(bnm)
   
  }




  return (
    <div>
     <div className="wrapper" >
    <Header   refresh={refreshPage}   brokerr={brokerrr} />
    <div   className="content-wrapper">
    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-12">
            <h1> Upload Excel File</h1>
          </div>
          <div className="col-sm-12">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="/Dashboard" >Home</a></li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section className="content">
      <div className="container-fluid">
    <div className='row'>
    <div className="col-sm-12">
    <div className="card card-default">
         <div class="card-body">
          <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',flexDirection:'row',width:'850px'}}>
          <div className="form-group">
            <label>ACCOUNT</label>
            {/* <select   onChange={(e)=>setBroker(e.target.value)}  className="form-control">
              <option value={null} >--Select Broker--</option>
              {showbroker()}
            </select> */}
            <select
          id="brokerSelect"
          onChange={(e) => setBroker(e.target.value)}
          className="form-control"
        >
          <option value={null}>--Select Account--</option>
          {showbroker()}
        </select>
            </div>
             <div style={{display:'flex',paddingTop:'15px'}}>
              <input type="file"    className=" mb-2 bg-secondary text-white form control"  onChange={handleFileUpload} />
             </div>
            <b style={{display:'flex',justifyContent:'center',alignItems:'center'}}>Sample file for
              <a  href='./excelformat/stock_info.xlsx' download  style={{ margin: '5px', float:'right' , color:'red',fontWeight:'bold' }} >
                 download
              </a> to Excel
              </b>
          </div>
      {jsonData.length > 0 && (
        <div class="table-responsive" style={{padding:'30px 0px 30px 0px'}}>
          <table id='customers'>
            <thead>
              <tr>
                {/* {console.log(titleData)} */}
                {keys.map((keyk, index) => (
                  <th key={index}>{keyk}</th>
                ))}
                {/* {Object.keys(jsonData[0]).map((key, columnIndex) => (
                  <th key={key}>{key}</th>
                ))} */}
              </tr>
            </thead>
            <tbody>
              {jsonData.map((item, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.keys(item).map((key, columnIndex) => (
                    <td key={key}>
                         <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id={`${key}-${rowIndex}`}
                        name={`${key}-${rowIndex}`}
                        value={formData[rowIndex][key]}
                        onChange={(e) =>
                          handleInputChange(key, e.target.value, rowIndex)
                        }
                      />
                    </div>
                   </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <button class="btn btn-secondary" style={{marginTop:'40px'}} type="button" onClick={sendDataToAPI}>
            Submit Data to API
          </button>
          </div>
        )}
        </div>
        <div className="card-footer">
      </div>
      </div>
    </div>
    </div>
    </div>
    </section>
    </div>
    <Footer/>
    </div>
    </div>
  );
}
export default ExcelUpload;