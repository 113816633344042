  import React from 'react';
  import {useState, useRef, useEffect, useMemo, useCallback}  from 'react';
  import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
  import 'ag-grid-enterprise';
  import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
  import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
  import { useSelector } from 'react-redux'
  import axios from 'axios';
  import { URL } from '../service/url';
import { AgCharts } from 'ag-charts-community';


  const Mis = () => {



    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [mis,setMis]=useState([])
    const [brokerdata,setBrokerdata]=useState([])
    const [broker,setBroker]=useState('ACCOUNTS')

    const gridRef = useRef();
    const admindetail=JSON.parse(localStorage.getItem('admindata'))
    // var bro  = useSelector((state)=>state.broker) 
    


    const [chartOptions, setChartOptions] = useState({
      data: [],
      title: {
        text: 'Your Chart Title',
      },
      series: [
        {
          xKey: 'x',
          yKey: 'y',
        },
      ],
    });


    // const fetchDataAndUpdateChart = async () => {
    //   try {
        
    //     const response = await axios.get(`${URL}your_api_endpoint`);
    //     const data = response.data;
    //     console.log('Fetched Data:', data); // Log fetched data for debugging
    //     setChartOptions((prevOptions) => ({
    //       ...prevOptions,
    //       data: data,
    //     }));
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };
    


  

    const formatDate = (date) => {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      return `${year}-${month}-${day}`;
    };


  

  const containerStyles = {
      width: '100vw',
      height: '100vh' 
    };
    

    const headingStyles = {
      backgroundColor: '#007bff',
      color: '#fff',
      padding:'20px',
      marginBottom: '20px',
      display:'flex',
      justifyContent:'center',
      fontSize: '24px'
    };

    const rowStyles = {
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'space-between' // Align elements evenly in a row
    };

    const colStyles = {
      flex: '1',
      marginLeft: '10px',
      marginRight: '10px'
    };

    const dateLabelStyles = {
      fontSize: '16px',
      marginBottom: '5px'
    };

    const buttonStyles = {
      padding: '8px 16px',
      fontSize: '16px',
      backgroundColor: '#007bff',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      width: '100%' // Make buttons occupy full width of column
    };

  



    const getToday = () => {
      return new Date();
    };

    const getYesterday = () => {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      return yesterday;
    };

    const handleLast7Days = () => {
      const today = getToday();
      const last7Days = new Date(today);
      last7Days.setDate(last7Days.getDate() - 6); // Subtract 6 days to get 7 days including today
      setStartDate(last7Days);
      setEndDate(today);
      
    };

    const handleCurrentMonth = () => {
      const today = getToday();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      setStartDate(firstDayOfMonth);
      setEndDate(today);
    };



    const getbroke=async()=>{
      var formdata=new FormData()
      formdata.append('admin_id',admindetail.user_id) 
      const result=await axios.post(`${URL}brokerinfo&bpuser=10891&action=getbrokers`,formdata)
      setBrokerdata(result.data.data)
      
      }
    
    
      const dropdown=()=>{
    
      return  brokerdata.map((broker)=>{
    
          return (
    
              <option value={broker.user_id} >{broker.user_name}</option>
            
              )
    
    
        })
    
    
      }
      
      
      useEffect(()=>{
      
        getbroke()
      
      },[])


      
  const call=(bid)=>{
  
    
    setBroker(bid)

  }



    const [columnDefs, setColumnDefs] = useState([

      { field: 'Date', 
        resizable: true ,   
        suppressSizeToFit: true ,
        minWidth:200,
        cellStyle: { textAlign: 'center' },
        enablePivot: true,
        chartDataType: "category",
      
    

      
      },
      { field: 'stockName', 
        resizable: true ,   
        suppressSizeToFit: true ,
        minWidth:200,
        cellStyle: { textAlign: 'center' },
        enablePivot: true,
        enableRowGroup: true,
      
        chartDataType: "category",
        
      
      
      },
        { field: 'IndustryName', 
        resizable: true ,
        suppressSizeToFit: true , 
        filter:true,
        minWidth:200,
        cellStyle: { textAlign: 'center' },
        enablePivot: true,
        enableRowGroup: true,
        chartDataType: "category",
      
      },
        { field: 'MarketCap', 
        resizable: true ,
        suppressSizeToFit: true , 
        minWidth:150,
        cellStyle: { textAlign: 'center' },
        enablePivot: true,
        enableRowGroup: true,
        chartDataType: "category",
        

      },
        { field: 'CMP', 
        resizable: true ,
        suppressSizeToFit: true ,
        minWidth:100,
        enablePivot: true,
          enableValue: true,
          defaultAggFunc: 'sum',
          allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'],
        chartDataType: "series",
        

      
      },
        { field: 'QTY', 
        resizable: true ,
        suppressSizeToFit: true , 
        minWidth:200,
        enablePivot: true,
          enableValue: true,
          defaultAggFunc: 'sum',
          allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'],
        chartDataType: "series",

      },
      { field: 'Total',
        resizable: true , 
        suppressSizeToFit: true ,
        minWidth:200,
        enablePivot: true,
          enableValue: true,
          defaultAggFunc: 'sum',
          allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'],
        chartDataType: "series",
        
      },
        { field: 'avg_rate', 
        resizable: true , 
        suppressSizeToFit: true ,
        minWidth:180, 
        enablePivot: true,
          enableValue: true,
          defaultAggFunc: 'sum',
          allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'],
        chartDataType: "series",
      
      },
        { field: 'cumulative_value', 
        resizable: true , 
        suppressSizeToFit: true ,
        minWidth:120,
        enablePivot: true,
        enableValue: true,
        defaultAggFunc: 'sum',
        allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'],
        chartDataType: "series",
        
      },
        { field: 'cumulative_qty', 
        resizable: true ,  
        suppressSizeToFit: true ,
        minWidth:150,
        enablePivot: true,
        enableValue: true,
        defaultAggFunc: 'sum',
        allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'],
        chartDataType: "series",

      },
        { field: 'Realized',
        resizable: true,
        suppressSizeToFit: true ,
        minWidth:150, 
        enablePivot: true,
          enableValue: true,
          defaultAggFunc: 'sum',
          allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'],
        chartDataType: "series",
      },
    
        { field: 'Unrealized',
        resizable: true,
        suppressSizeToFit: true ,
        minWidth:150, 
        enablePivot: true,
          enableValue: true,
          defaultAggFunc: 'sum',
          allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'],
        chartDataType: "series",
      }
      // ,
      //   { field: 'percentageGain',
      //   resizable: true,
      //   suppressSizeToFit: true ,
      //   minWidth:150, 
      //   enablePivot: true,
      //   enableValue: true,
      //   aggFunc: ["sum","avg","max","min"],
      //   chartDataType: "series",

        
      // },
      
    
    ]);



    // DefaultColDef sets props common to all Columns


    const defaultColDef = useMemo(() => ({

      resizable: true,
      sortable: true,
      filter:true,
      floatingFilter:true,
      cellRenderer:false,
      flex:1,
      maxWidth:300,
      suppressSizeToFit: true,
     
    
    
      
    
  }), []);

  const autoGroupColumnDef = useMemo(() => {
      return {
        minWidth: 250,
      };
    }, []);


    const handleDateRangeSelect = async() => {


      var formdata=new FormData()
    
      formdata.append('b_admin_id',admindetail.user_id) 
    
      formdata.append('broker_id',broker)
    
      formdata.append('from_date',formatDate(startDate))
      
      formdata.append('to_date',formatDate(endDate))
    
      
    
      var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=allstockdata`,formdata)
      
      
      setMis(result.data.data)
    
    
    };
    
    
    useEffect(()=>{
    
      handleDateRangeSelect()
    
    
    },[])








    return (
      <div style={containerStyles}>
   
          
        <h1 style={headingStyles}>MIS REPORT</h1>
        

        <div style={rowStyles}>
      
          <div style={colStyles}>
            <label style={dateLabelStyles} htmlFor="start-date">From Date:</label>
            <input type="date" id="start-date" className="form-control" value={startDate ? formatDate(startDate) : ''} onChange={(e) => setStartDate(new Date(e.target.value))} />
          </div>
          <div style={colStyles}>
            <label style={dateLabelStyles} htmlFor="end-date">To Date:</label>
            <input type="date" id="end-date" className="form-control" value={endDate ? formatDate(endDate) : ''} onChange={(e) => setEndDate(new Date(e.target.value))} />
          </div>
          <div style={colStyles}>
          <div >
                <div className="btn btn-block btn-primary">
                    <label >Account's</label>
                    <select   onChange={(event)=>call(event.target.value)}  className="form-control">
                        <option value={'ACCOUNTS'} >ACCOUNTS</option>
                        {dropdown()}
                    </select>
                </div>
              </div>  
            
          </div>

        </div>



        <div style={rowStyles}>
        
        
          <div style={colStyles}>
            <button style={buttonStyles} onClick={() => {setStartDate(getToday()); setEndDate(getToday())}}>Today</button>
          </div>
          <div style={colStyles}>
            <button style={buttonStyles} onClick={() => {setStartDate(getYesterday()); setEndDate(getYesterday())}}>Yesterday</button>
          </div>
          <div style={colStyles}>
            <button style={buttonStyles} onClick={handleLast7Days}>Last 7 Days</button>
          </div>
          <div style={colStyles}>
            <button style={buttonStyles} onClick={handleCurrentMonth}>Current Month</button>
          </div>
          <div style={colStyles}>
            <button style={buttonStyles} onClick={handleDateRangeSelect}>Search</button>
          </div>
        
        
        </div>


        

              <div className="card-body">

             
              
                  <div className="ag-theme-alpine" style={{width: '100%', height: 820}} >
                  
                  <AgGridReact className="ag-theme-alpine" 
                  
                          
                    ref={gridRef} 
                    rowData={mis} 
                    columnDefs={columnDefs} 
                    defaultColDef={defaultColDef} 
                    enableRangeSelection={true}
                    enableCharts={true}
                    animateRows={true} 
                    rowSelection='multiple' 
                    pagination={true}
                    onGridReady={mis}
                    autoGroupColumnDef={autoGroupColumnDef}
                    sideBar={'columns'}
                    pivotMode={true}
                    chartMenuTabs={['chartSettings', 'chartData']}
                    paginationPageSize={500}
                    
                  
                  
                  
                          
                  />


                  </div>

              </div>




        
      </div>
    );
  };

  export default Mis;
