import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
//import { createRoot } from 'react-dom/client';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import axios from 'axios';
import { URL } from '../service/url';
import mqtt from 'mqtt'


export default function IndustriesHolding(props) {
 
  const admindetail=JSON.parse(localStorage.getItem('admindata'))
  const [industryname,setIndustryName]=useState('')
  const [showModal, setShowModal] = useState(false);
  const [currentholdingdatabackup,setCurrentHoldingDataBackUp]=useState([])
  const [idustrywisestock,setIdustryWiseStock]=useState([])
  const [setbrokeridformqtt,setBrokerIdForMqtt]=useState(`2222`)
  
  const columnDefs = useMemo(() => [
    { headerName:'INDUSTRY', field: 'INDUSTRY' , cellStyle: { textAlign: 'left' }},
    { headerName:'HOLDING', field: 'HOLDING' , cellStyle: { textAlign: 'left' }},
  
  ], []);


const defaultColDef = useMemo(() => ({
  resizable: true,
  sortable: true,
  filter:true,
  floatingFilter:true,
  cellRenderer:false,
  flex:1,
  minWidth: 100,
  editable:true
}), []);

const [rowData, setRowData] = useState();



// // .....................................

const [brokerid,setBrokerid]=useState()
const [brokername,setBrokerName]=useState()
const [brokertype,setBrokerType]=useState()


useEffect(()=>{

  setBrokerid(props.body[0].brokerid)
  setBrokerName(props.body[0].brokername)
  setBrokerType(props.body[0].brokertype)
  



},[props])



// .............................................CURRENT HOLDING... 


const getholdingdata=async()=>{

  var formdata=new FormData()
  

  formdata.append('bro_id',brokerid)
  formdata.append('admin_id',admindetail.user_id)
  let result = await axios.post(`${URL}stockInfo&bpuser=10891&action=holdingIndustry`,formdata)
  let filteredData = result.data.data.filter(val => Number(val.HOLDING) > 0);
  setRowData(result.data.data)
 
  
 }

useEffect(()=>{

getholdingdata()


},[brokerid])

// mqtt...code .................................
 

const MQTT_HOST = 'wss://test.mosquitto.org:8081';
const [MQTT_TOPIC,setMqtt_Topic]=useState(`testtopic/amitjha/2222`)
const [mqttClient, setMqttClient] = useState(null);
const [mqttdata,setMqttData]=useState([])




useEffect(()=>{

  
  const zeronodata=mqttdata.filter((val)=>val.QTY !==0)
  setCurrentHoldingDataBackUp(mqttdata)


},[mqttdata])



useEffect(()=>{


  if(brokerid===undefined || brokerid===null)
    {
      setMqtt_Topic(`testtopic/amitjha/2222`)
    }
  else
  {
     setMqtt_Topic(`testtopic/amitjha/${brokerid}`)
  }

},[brokerid])




useEffect(() => {
const client = mqtt.connect(MQTT_HOST);

client.on('connect', () => {

  setMqttClient(client);

  client.subscribe(MQTT_TOPIC);

});

return () => {

  client.end(); 

};

}, [MQTT_TOPIC]);






useEffect(() => {


const handleMessage = (topic, message) => {

  setMqttData(JSON.parse(message.toString()));
  
};

if (mqttClient) {
  mqttClient.on('message', handleMessage);
}

return () => {
  if (mqttClient) {
    mqttClient.off('message', handleMessage); 
  }
};

}, [mqttClient]); 



// filter mqtt data industry wise ................

const Filtermqttbyindus=(indty)=>{
  

  const industrydata=currentholdingdatabackup.filter((val)=>val.INDUSTRY===indty)
  setIdustryWiseStock(industrydata)

}



// filter mqtt data industry wise ................


const handleRowallClicked =async(event) =>{
  
  setBrokerIdForMqtt(brokerid)
  Filtermqttbyindus(event.data.INDUSTRY)
  setIndustryName(event.data.INDUSTRY)
  setShowModal(true) 


}



const Modal = ({ showModal }) => {


  const [columnDefs, setColumnDefs] = useState([

    { 
      field: 'STOCKS', 
      resizable: true ,   
      suppressSizeToFit: true ,
   
      minWidth:250,
      cellStyle: { textAlign: 'left' },
      
    },
    { 
      field: 'INVESTED_HOLDING',
      resizable: true,
      suppressSizeToFit: true ,
      cellStyle: { textAlign: 'center' },
      minWidth:250, 
    }
    
  
  ]);

  // DefaultColDef sets props common to all Columns
  
  const defaultColDef = useMemo(() => ({

    resizable: true,
    sortable: true,
    filter:true,
    floatingFilter:true,
    cellRenderer:false,
    flex:1,
    maxWidth:400,
    suppressSizeToFit: true

  }), []);


  const Doempty=()=>{


  


  }


  return ( 
   
    <div className={`modal fade ${showModal ? 'show d-block' : ''}`} id="exampleModalToggle" aria-hidden="true" tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>

      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">

        <div className="modal-content">

          <div className="modal-header">

          <h5 className="modal-title">REALISED</h5>

          
           
            <button  type="button" style={{background:'#ffff',border:'none',padding:'10px'}} aria-label="Close" onClick={() => {setShowModal(false)
             Doempty()
            }}>
             
             
              <img  style={{width:'20px'}}  src='../assets/cancel.png'/>

            </button>

          </div>

          <div className="modal-body">
          

            <div className="ag-theme-alpine" style={{ height: '400px', width: '765px' }}>
            
              <AgGridReact
                rowData={idustrywisestock}
                columnDefs={columnDefs} 
                defaultColDef={defaultColDef} 
              />

            </div>

    

          </div>

         
        </div>


      </div>


    </div>


  );

};



// .........................................END CURRENT HOLDING...

 return (
   <>
    <div className="card">
      <div className="card-body">

        <div className="ag-theme-alpine" style={{width: '100%', height: 430}}>
          <AgGridReact 
          className="ag-theme-alpine"    
          pagination={true}  
          enableRangeSelection={true}  
          animateRows={true} 
          columnDefs={columnDefs} 
          defaultColDef={defaultColDef}  
          rowData={rowData} 
          rowSelection="multiple"   
          suppressRowClickSelection="true"  
          onRowClicked={handleRowallClicked}


          />
        </div>
        
      </div>

      <Modal showModal={showModal}  />  
    </div>
   </>
  )
}
