import React, { useState,useEffect, useMemo, useCallback} from 'react';
//import { createRoot } from 'react-dom/client';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import axios from 'axios'
import { URL } from '../service/url';
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
// import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';




export default function CurrentHoldingSingleScriptAccount() {

 

  const dispatch=useDispatch()
  const admindetail=JSON.parse(localStorage.getItem('admindata'))
  const [stocklist,setStockList]=useState([])
  const [rowData, setRowData] = useState()
  const [rowDat,setRowDat]=useState([])
  const [reduxdata,setReduxData] =useState(null)
  const [stockdata,setStockData]=useState()
  const [brokerid,setBrokerid]=useState(null)
  const [total,setTotal]=useState()
  const [industry,setIndustry]=useState([])
  const [marketcap,setMarketCap]=useState([])
  const Swal = require('sweetalert2')



    const columnDefs = useMemo(() => [
      
      { field: 'DATE',
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:120,
      cellStyle: { textAlign: 'center' }
    },
      { field: 'FUND_TYPE',
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:150,
      cellStyle: { textAlign: 'center' }
    },
    { field: 'BUY_QUANTITY',
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:150,
      cellStyle: { textAlign: 'right' }
    },
      { field: 'BUY_RATE',
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:120,
      cellStyle: { textAlign: 'right' }
    },
      { field: 'BUY_VALUE',
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:120,
      cellStyle: { textAlign: 'right' }
    },
      { field: 'SELL_QUANTITY',
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:150,
      cellStyle: { textAlign: 'right' }
    },
      { field: 'SELL_RATE',
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:120,
      cellStyle: { textAlign: 'right' }
    },
      { field: 'SELL_VALUE',
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:120,
      cellStyle: { textAlign: 'right' }
    },
      { field: 'CUMULATIVE_VALUE',
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:180,
      cellStyle: { textAlign: 'right' }
    },
      { field: 'CUMULATIVE_QTY',
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:180,
      cellStyle: { textAlign: 'right' }
    },
      { field: 'REALISED',
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:120,
      cellStyle: { textAlign: 'right' }
    },
      { field: 'UNREALISED',
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:150,
      cellStyle: { textAlign: 'right' }
    },
    { field: 'BROKERAGE',
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:120,
      cellStyle: { textAlign: 'right' }
    }

    ], []);

 
    
    
    const defaultColDef = useMemo(() => ({
      editable:true,
      resizable: true,
      sortable: true,
      filter:true,
      floatingFilter:false,
      cellRenderer:true,
      flex:1,
      maxWidth: 300,
      suppressSizeToFit: true,


  
    
    }), []);


    const columnDef = useMemo(() => [
      { field: 'SNO',
        resizable: true ,   
        suppressSizeToFit: true ,
        minWidth:80,
        cellStyle: { textAlign: 'center' }
      },

      { field: 'ACCOUNT_NAME', 
        resizable: true ,   
        suppressSizeToFit: true ,
        minWidth:200,
        cellStyle: { textAlign: 'center' }
     
      } ,
      { field: 'STOCKS',width:100,
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:200,
      cellStyle: { textAlign: 'center' }
   
    } ,
      { field: 'QTY',width:100,resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:200,
      cellStyle: { textAlign: 'right' }
   
    } ,
      { field: 'AVERAGE_PRICE',width:100,resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:200,
      cellStyle: { textAlign: 'right' }
   
    } ,
    { field: 'CMP',width:100,resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:200,
    cellStyle: { textAlign: 'right' }
 
  },
      // { field: 'CMP',width:100,},
      { field: 'INVESTED_HOLDING',width:100,resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:200,
      cellStyle: { textAlign: 'right' }
   
    } ,
      { field: 'TOTAL_REALISE_PROFIT',width:100,resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:200,
      cellStyle: { textAlign: 'right' }
   
    } ,
    { field: 'INVESTED',width:100,resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:200,
    cellStyle: { textAlign: 'right' }
 
  },
      { field: 'CURRENT_HOLDING',width:100,resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:200,
      cellStyle: { textAlign: 'right' }
   
    },
    { 
      field: 'PER_GAIN',width:100,resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:200,
      cellStyle: { textAlign: 'right' }
 
    }  

      
      // { field: 'MARKET VALUE',width:100,},
      // { field: 'UNREALISED',width:100,},
      // { field: 'REALISED',width:100,}
  ], []);
  
  
  const defaultColDe = useMemo(() => ({
    editable:true,
    resizable: true,
    sortable: true,
    filter:true,
    floatingFilter:true,
    cellRenderer:true,
    flex:1,
    maxWidth: 300,
    suppressSizeToFit: true,
    

  }), []);
  
    
 


    const getallstocklist=async()=>{

      var formdata=new FormData()
      
      formdata.append('admin_id',admindetail.user_id)
     
      var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=getadminwiseStock`,formdata)
      
      

      setStockList (result.data.data)
      
  
    }

    const  Setcapandindus=async(stockname)=>{
      
      let formdat=new FormData()

      formdat.append('stock_name',stockname)
      formdat.append('broker_id',null)
      formdat.append('admin_id',admindetail.user_id)
 
      var result = await axios.post(`${URL}stockInfoexcel&bpuser=10891&action=stockCheckExits`,formdat)
      setMarketCap(result.data.data[0].st_type)
      setIndustry(result.data.data[0].industry_name)


    }

// ......................................................brokerid
var bro  = useSelector((state)=>state.broker) 


useEffect(()=>{

if(!bro.broker)
{
  setBrokerid(null)
  
}
else
{
  setBrokerid(bro.broker.bro_id)
 
}
  
},[bro.broker])

// .......................................................broker id



   const showstocklist=()=>{

   return stocklist.map((val)=>{

    return (

      <option value={val.StockName} >{val.StockName}</option>

    )


   })


   }





  

  const stockscripting=(item)=>{

    var body={stockname_name:item}

    var so='stockname'

    dispatch({type:'brokeridset',payload:[so,body]})

    update()

  }


var sto  = useSelector((state)=>state.broker ) 

const update=async()=>{

  var formdata= new FormData()

  formdata.append('stock_name',sto.stockname.stockname_name)
  formdata.append('admin_id',admindetail.user_id)
  formdata.append('broker_id',brokerid)

  
  if(brokerid)
  {
    var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=singleScriptStock`,formdata)
 
    setRowData(result.data.data)
      
    var response = await axios.post(`${URL}stockInfo&bpuser=10891&action=multipleScriptStock`,formdata)
    setRowDat(response.data.data)

  }
 else{
     
      Swal.fire({
        icon: 'error',
        title: 'Alert',
        text: 'Please First Select Account',
      }).then((result) => {
        window.location.reload(false);
    })
   

  }

}


 useEffect(()=>{

  getallstocklist()
 

},[])




// row edit .....

// const [excelrowdata,setExcelRowdata]=useState()
//   console.log('excelrowdataoutfuntion',excelrowdata)
const [startingrate,setStartingRate]=useState(null)
const [startingqty,setStartingQty]=useState(null)
const [startingstockid,setStartingStockid]=useState(null)

const [rate,setRate]=useState(null)
const [qty,setQty]=useState(null)
const [stockid,setStockid]=useState(null)
// ---------------------lpt state----------

const [date,setDate]=useState('')
const [time,setTime]=useState('')
const [ltpstock,setLtpStock]=useState('')
const [ltp,setLtp]=useState('')
  
const [error,setError]=useState({})

// ---------------------lpt state----------


const onCellEditingStarted =useCallback((event) => {

  if(event.data.BUY_RATE!==0  &&  event.data.BUY_QUANTITY!==0 )
  {
    setStartingRate(event.data.BUY_RATE);
    setStartingQty(event.data.BUY_QUANTITY);
    setStartingStockid(event.data.stock_id) 
}
  else 
  {
    setStartingRate(event.data.SELL_RATE );
    setStartingQty(event.data.SELL_QUANTITY);
    setStartingStockid(event.data.stock_id) 
}



 
},[])

const onCellEditingStopped = useCallback(async(event) => {

  if(event.data.BUY_RATE!==0  &&  event.data.BUY_QUANTITY!==0 )
  {
    setRate(event.data.BUY_RATE);
    setQty(event.data.BUY_QUANTITY);
    setStockid(event.data.stock_id) 
}
  else 
  {
    setRate(event.data.SELL_RATE );
    setQty(event.data.SELL_QUANTITY);
    setStockid(event.data.stock_id) 
}

 

}, []);



const Cellupdate=async()=>{

if(rate===startingrate && qty===startingqty)
  {
     
     
      Swal.fire({
        icon: 'error',
        title: 'Alert',
        text: 'Cell value is not changed',
        showConfirmButton: true,
      })
     

 }
 else 
 {
   const formdata=new FormData()
  formdata.append("st_price",rate)
  formdata.append("st_qty",qty)
  formdata.append("stock_id",stockid)
  let result=await axios.post(`${URL}stockInfo&bpuser=10891&action=update`,formdata)
  if(result.data.res_code===1)
  {
             Swal.fire({
              icon: 'success',
              title: 'success',
              text: 'Cell Update succesfully',
              timer: 1600
            }).then((result) => {
              window.location.reload();
            })
  }
 }


}


var sumofholding=0
      
rowDat.map((data)=>{
   
  sumofholding+=data.INVESTED_HOLDING
  sumofholding=sumofholding.replace(0,'')


  
 })

 


 var sumofquantity=0
      
rowDat.map((data)=>{
   
  sumofquantity+=data.QTY
  sumofquantity=sumofquantity.replace(0,'')

  
 
 })


 // handle error function 

const handleerror=(input,value)=>{

  setError((prev)=>({...prev,[input]:value}))
  
}



const validation=()=>{
  
  var isvalid=true
  
  
  if(date=="")
  {
    handleerror("date","Please Select Date")
    isvalid=false
  }

  if(time=="")
  {
    handleerror("time","Please Select Time")
    isvalid=false
  }


  if(ltpstock=="")
  {
    handleerror("stock","Please Select Stock ")
    isvalid=false
  }
 

  if(ltp=="")
  {
    handleerror("ltp","Please Enter Last Transaction Price")
    isvalid=false
  }
  else if(isNaN(ltp))
  {
    handleerror("ltp","Please Enter Valid Last Transaction Price")
    isvalid=false
  }

  return isvalid

}

 const Submiteltp=async()=>{

  
if(validation()){
  
 
 

  const formdata=new FormData()
  formdata.append("time",time)
  formdata.append("date",date)
  formdata.append("ltp_stock",ltpstock)
  formdata.append("ltp",ltp)
  formdata.append("industry",industry)
  formdata.append("marketcap",marketcap)
  formdata.append('admin_id',admindetail.user_id)
  // formdata.append('broker_id',brokerid)
  let respo=await axios.post(`${URL}stockInfo&bpuser=10891&action=ltpData`,formdata)

  if(respo.data.res_code==1)
  {
    Swal.fire({
      position: 'top-center',
      icon: 'success',
      title: 'LTP Submitted Successfully',
      showConfirmButton: false,
      timer: 1600
    })

    window.location.reload()
  }
  else
  {
    Swal.fire({
      position: 'top-center',
      icon: 'error',
      title: 'server error please try again',
      showConfirmButton: false,
      timer: 1600
    })

    window.location.reload()
  }
 

 
}


 }


  return (
   <div style={{display:'flex',flexDirection:'column'}}>
     <h5>Stock Price Update</h5>

   {/* .................................................................ltp */}

   <div className='row card-footer mb-3' style={{paddingBottom:'20px'}}>

   
    <div className='col-sm-6'>
      <div className='form-group'>
        <label>Date</label><input onFocus={()=>setError('date',null)} onChange={(e)=>setDate(e.target.value)} type='date' className='form-control'/>
      </div>
      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      
        {error.date} 
                    
      </div>
    </div>
    <div className='col-sm-6'>
      <div className='form-group'>
        <label>Time</label><input onFocus={()=>setError('time',null)} onChange={(e)=>setTime(e.target.value)} class="form-control"  type="time" step="1"  />
      </div>
      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      
        {error.time} 
                    
      </div>
    </div>

    <div className='col-sm-6'>
      <div className='form-group'>
      <label>Stock</label>
      <select onFocus={()=>setError('stock',null)}  onChange={(e)=>{setLtpStock(e.target.value);Setcapandindus(e.target.value)}} className="form-control">
        <option value={''} >--Select Stock--</option>
        {showstocklist()}
      </select>
      </div>
      
      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      
        {error.stock} 
                    
      </div>

    </div>
    <div className='col-sm-6'>
      <div className='form-group'>
        <label>CMP</label><input onFocus={()=>setError('ltp',null)} onChange={(e)=>setLtp(e.target.value)} placeholder='Last Transaction Price' type='text' className='form-control'/>
      </div>
      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      
        {error.ltp} 
                    
      </div>
    </div>
    <div className='col-sm-6'>
      <div className='form-group'>
        <input type='submit' className='btn btn-primary' onClick={()=>Submiteltp()} value="Save"/>
      </div>
    </div>
    </div>


   {/* .................................................................ltp */}

    <div style={{paddingBottom:'20px'}}>
      <select  onChange={(e)=>stockscripting(e.target.value)} className="form-control">
        <option value={'null'}  >--Selecte Stock--</option>
        {showstocklist()}
      </select>
    </div>

    {rowData?<>
      <div className="ag-theme-alpine" style={{width: '100%', height: 400}}>
        
        {/* { rate!==null && qty!==null && stockid!==null ? <> <button onClick={()=>Cellupdate()} type="button" className="btn  btn-primary" style={{marginBottom:'10px'}}>UPDATE CELL</button></>:<> </>} */}
         <AgGridReact
          
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          className="ag-theme-alpine"
          animateRows="true"
          enableRangeSelection="true"
          rowSelection="multiple"
          suppressRowClickSelection="true"
          pagination={true}
          // onCellEditingStarted={onCellEditingStarted}
          // onCellEditingStopped={onCellEditingStopped}
       
        />

      </div>
    
      <div style={{paddingTop:'60px'}}>
        
      </div>

        <div className="ag-theme-alpine" style={{width: '100%', height: 400}}>
        <AgGridReact
                className="ag-theme-alpine"
                animateRows="true"
                columnDefs={columnDef}
                defaultColDef={defaultColDe}
                enableRangeSelection="true"
                rowData={rowDat}
                rowSelection="multiple"
                suppressRowClickSelection="true"
                pagination={true}
            />
            
        </div>

        <div className="ag-theme-alpine"  style={{width: '100%', height: 100,display:'flex',alignItems:'center',justifyContent:'space-evenly'}}>

           {/* <h3>TOTAL &nbsp; QUANTITY</h3>&nbsp;&nbsp;<h3>:</h3>&nbsp;&nbsp;<h3>{sumofquantity}</h3>
           <h3>TOTAL &nbsp; HOLDING</h3>&nbsp;&nbsp;<h3>:</h3>&nbsp;&nbsp;<h3>{sumofholding}</h3> */}
           
          <table class="table table-bordered" >
            <thead   >
              <tr >
                <th style={{fontSize:'15px'}} scope="col">TOTAL QUANTITY</th>
                <th style={{fontSize:'15px'}}  scope="col">TOTAL HOLDING</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row"  style={{fontWeight:'bold',fontSize:'18px'}}>{sumofquantity}</td>
                <td scope="row"  style={{fontWeight:'bold',fontSize:'18px'}} >{sumofholding}</td>
                
              </tr>
            </tbody>
          </table>


        </div>

    
    </>:<></>}

    
    </div>

  )
}
