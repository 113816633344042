import React from 'react'
import { useState } from 'react'
import axios, { AxiosError } from 'axios'
import { URL } from '../service/url'
import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

export default function WithDraw() {

  const [error,setError]=useState({})
  const [date,setDate]=useState('')
  const [ammount,setAmmount]=useState('')
  const [brokerdata,setBrokerdata]=useState([])
  const [broker,setBroker]=useState('')
  const [tranid,setTranId]=useState('')
  const [fundtype,setFundtype]=useState('')
 
  const Swal = require('sweetalert2')

  const admindetail=JSON.parse(localStorage.getItem('admindata'))

  var navigate=useNavigate()


  // ...............api get broker 

  const getbroke=async()=>{
    var formdata=new FormData()
    formdata.append('admin_id',admindetail.user_id) 
    const result=await axios.post(`${URL}brokerinfo&bpuser=10891&action=getbrokers`,formdata)
    setBrokerdata(result.data.data)
    
    }
    
    
    useEffect(()=>{
    
      getbroke()
     
     },[])
     
     
    
     
  const showbroker=()=>{
  
    return  brokerdata.map((val)=>{
    
         return (
           <><option value={val.user_id} >{val.user_name}</option> </>
          ) })
   
   }
  
  


    // handleerror function 

    const handleerror=(input,value)=>{

      setError((prev)=>({...prev,[input]:value}))
      
    }

      // validation function 

  const validation=()=>{
    var isvalid=true
    
    if(!date)
    {
      handleerror("adfunddate","Please Enter Date")
      isvalid=false
    }
    if(!ammount)
    {
      handleerror("tranammount","Please Enter Amount ")
      isvalid=false
    }
    else if(isNaN(ammount))
    {
      handleerror("tranammount","Please Enter Amount (Only Number) ")
      isvalid=false

    }

    if(!broker)
    {
      handleerror("s_broker","Please Select Account")
      isvalid=false
    }

    if(!tranid)
    {
      handleerror("tran_id","Please Enter Transaction ID")
      isvalid=false
      
    }
    else if(isNaN(tranid))
    {
      handleerror("tran_id","Please Enter Transaction ID (Only Number)")
      isvalid=false
    }

    if(fundtype==='')
    {

      handleerror("s_fundtype","Please Select Fund Type")
      isvalid=false
    }
    
  
    return isvalid
    
   }



   

  const  Submit=async()=>{

    if(validation())
    {
      var formdata= new FormData()
      formdata.append('t_id',tranid)
      formdata.append('trasn_type','Withdraw fund')
      formdata.append('dr_cr','DR')
      formdata.append('cr_amnt',0)
      formdata.append("dr_amnt",ammount)
      formdata.append('payment_mode','Internet banking')
      formdata.append('fund_type',fundtype)
      formdata.append('tans_date',date)
      formdata.append('admin_id',admindetail.user_id)
      formdata.append('broker_id',broker)


      const result=await axios.post(`${URL}stockfund&bpuser=10891&action=addfund`,formdata)
      
      if(result.data.res_code==1){

        // Swal.fire({
        //   title: 'Data Submitted sucsessfully',
        //   confirmButtonText: 'ok',
        // }).then((result) => {
         
        //   if (result.isConfirmed) {
        //     navigate('/Dashboard')
        //   } 
        // })
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: 'Withdraw Fund Sucsessfully',
          showConfirmButton: false,
          timer: 1600
        }).then((result) => {
         
        
              navigate('/Dashboard')
         
          })


      }
         

    }



  }
    


  return (
    <div className='row'>
    <div className="col-sm-12">

                  <div className="row">
                    
                    
                    <div className="col-sm-6">
                      
                      <div className="form-group">
                        <label>Date(DD/MM/YYYY)</label>
                        <input  onFocus={()=>setError('adfunddate',null)}   onChange={(e)=>setDate(e.target.value)} type="date" className="form-control" placeholder="Enter ..."/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                       {error.adfunddate}
                      </div>
                    </div>


                   
                </div>


                <div className="row">

                  <div className="col-sm-6">
                          
                    <div className="form-group">
                      <label>Fund Type</label>
                      <select   onFocus={()=>handleerror('s_fundtype',null)}   onChange={(e)=>setFundtype(e.target.value)}  className="form-control">
                        <option value={''} >--Select Fund Type--</option>
                        <option value={'NML'} >NML</option>
                        <option value={'MTF'} >MTF</option>
                        
                      </select>
                    </div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      {error.s_fundtype}
                    </div>
              
                  </div>

                </div>
  

              
                  
                <div className="row">
                  <div className="col-sm-6">
                    
                    <div className="form-group">
                      <label>Amount</label>
                      <input onFocus={()=>setError('tranammount',null)}    onChange={(e)=>setAmmount(e.target.value)}   type="text" className="form-control" placeholder="Amount"/>
                    </div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      {error.tranammount}
                    </div>
                  </div>
                
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    
                    <div className="form-group">
                      <label>Transaction Id </label>
                      <input   onFocus={()=>setError('tran_id',null)}    onChange={(e)=>setTranId(e.target.value)}   type="text" className="form-control" placeholder="Transaction ID"/>
                    </div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      {error.tran_id}
                    </div>
                  </div>
                
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    
                    <div className="form-group">
                      <label>Account</label>
                      <select   onFocus={()=>handleerror('s_broker',null)}  onChange={(e)=>setBroker(e.target.value)}  className="form-control">
                        <option value={null} >--Select Account--</option>
                        {showbroker()}
                      </select>
                    </div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      {error.s_broker}
                    </div>

                  </div>
                </div>
                  
                  

                  <div className="row">
                  <div className="col-sm-6">
                
               <button  onClick={()=>Submit()}  type="button" className="btn btn-block btn-primary" style={{width:'200px', float:'none', margin:'0 auto'}}>Submit</button>
               
                </div>
              </div>
         
        </div>

    </div>
  )
}
