import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
//import { createRoot } from 'react-dom/client';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import mqtt from 'mqtt'


export default function TopGainers(props) {


  const admindetail=JSON.parse(localStorage.getItem('admindata'))
  const [currentholdingdatabackup,setCurrentHoldingDataBackUp]=useState([])
  const [disendingdata,setDisendingData]=useState([])





  const sortByPercentageChange = (data) => {
    const filteredData = data.filter(item => item.hasOwnProperty('%CHNG') && item.INVESTED_HOLDING!=0);
    const sortedData = filteredData.sort((a, b) => {
    
      const percentageA = parseFloat(a['%CHNG']) || 0; 
      const percentageB = parseFloat(b['%CHNG']) || 0;
      return percentageB - percentageA;
    });
  
    return sortedData; 
  };

  useEffect(()=>{

    const data=sortByPercentageChange(currentholdingdatabackup)
    setDisendingData(data)
  
  },[currentholdingdatabackup])




  const columnDefs = useMemo(() => [
    { field: 'STOCKS' },
   
    { field: '%CHNG', cellClass: params => ['text-success'] , cellRenderer: params => (
      <div className="text-end">
        {params.value}
         <i style={{marginLeft:'10px'}} className="ti ti-trending-up text-success" />
      </div>
    )},
    // { field: 'INVESTED_HOLDING', cellRenderer: params => (
    //   <div className="text-end">
    //     {params.value}
    //      <i className="ti ti-trending-up text-success" />
    //   </div>
    // ),},
    { field: 'INVESTED_HOLDING'},
    
], []);


const defaultColDef = useMemo(() => ({
  resizable: true,
  sortable: true,
  filter:true,
  floatingFilter:true,
  cellRenderer:false,
  flex:1,
  minWidth: 100,
  editable:true
}), []);

// var fakedata=[{stockname:}]

const [rowData, setRowData] = useState()

useEffect(() => {
  fetch('/stock_trading_api/top_gainers.json')
      .then(result => result.json())
      .then(rowData => setRowData(rowData))
      .catch(error => console.error('Error fetching data:', error));
}, []);



  // .....................................

  useEffect(()=>{

    setBrokerid(props.body[0].brokerid)
    setBrokerName(props.body[0].brokername)
    setBrokerType(props.body[0].brokertype)
  
  
  },[props])
  
  
  const [brokerid,setBrokerid]=useState()
  const [brokername,setBrokerName]=useState()
  const [brokertype,setBrokerType]=useState()
  
  // console.log('topgainerid',brokerid)
  // console.log('topgainername',brokername)
  // console.log('topgainertype',brokertype)
  
  // .....................................



  // .............................................CURRENT HOLDING... 


const getholdingdata=async()=>{

  var formdata=new FormData()
  

  formdata.append('bro_id',brokerid)
  formdata.append('admin_id',admindetail.user_id)

  // var result = await axios.post(`${URL}`,formdata)
 
  // setRowData(result.data.data)
  
  

 }

useEffect(()=>{

getholdingdata()

},[brokerid])



// .........................................END CURRENT HOLDING...


// mqtt...code .................................
 
  

const MQTT_HOST = 'wss://test.mosquitto.org:8081';


const [MQTT_TOPIC,setMqtt_Topic]=useState(`testtopic/amitjha/2222`)

const [mqttClient, setMqttClient] = useState(null);
const [mqttdata,setMqttData]=useState([])







useEffect(()=>{

   const zeronodata=mqttdata.filter((val)=>val.QTY !==0)
 

   setCurrentHoldingDataBackUp(mqttdata)


},[mqttdata])



useEffect(()=>{


  if(brokerid===undefined || brokerid===null)
    {
      setMqtt_Topic(`testtopic/amitjha/2222`)
    }
  else
  {
     setMqtt_Topic(`testtopic/amitjha/${brokerid}`)
  }

},[brokerid])




useEffect(() => {
const client = mqtt.connect(MQTT_HOST);

client.on('connect', () => {

  setMqttClient(client);

  client.subscribe(MQTT_TOPIC);

});

return () => {

  client.end(); 

};

}, [MQTT_TOPIC]);






useEffect(() => {


const handleMessage = (topic, message) => {

  setMqttData(JSON.parse(message.toString()));
  

};

if (mqttClient) {
  mqttClient.on('message', handleMessage);
}

return () => {
  if (mqttClient) {
    mqttClient.off('message', handleMessage); 
  }
};

}, [mqttClient]); 



const paginationPageSize = 6;

  return (
   <>
   <div className="card"> 
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col">                      
                  <h4 className="card-title">Top Gainers</h4>                    
                </div>{/*end col*/}
              </div>  {/*end row*/}                                  
            </div>{/*end card-header*/}
            <div className="card-body">  
              <div className="table-responsive dash-social">
              <div className="ag-theme-alpine" style={{width: '100%', height: 400}}>
                <AgGridReact
                        className="ag-theme-alpine"
                        animateRows="true"
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        enableRangeSelection="true"
                        rowData={disendingdata}
                        rowSelection="multiple"
                        suppressRowClickSelection="true"
                        pagination={true}
                        paginationPageSize={paginationPageSize}
                    />
        </div>

                {/* <table id="example2" className="table table-bordered">
                  <thead className="thead-light">
                    <tr>
                      <th>Company Name</th>
                      <th className="text-end">Stock Price</th>
                      <th className="text-end">Change</th>                                                    
                      <th className="text-end">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>ELECON<span className="text-muted font-10" /></td>
                      <td className="text-end">557.05 <i className="ti ti-trending-up text-success" /></td>
                      <td className="text-success text-end">51.55</td>
                      <td className="text-end text-success">6,089,671</td>
                    </tr> 

                    <tr>
                      <td>AIAENG<span className="text-muted font-10" /></td>
                      <td className="text-end">2,925.30 <i className="ti ti-trending-up text-success" /></td>
                      <td className="text-success text-end">25.65</td>
                      <td className="text-end text-success">4,089,569</td>
                    </tr> 

                    <tr>
                      <td>CHENNPETRO<span className="text-muted font-10" /></td>
                      <td className="text-end">357.65 <i className="ti ti-trending-up text-success" /></td>
                      <td className="text-success text-end">17.25</td>
                      <td className="text-end text-success">963,509</td>
                    </tr>                                                                                                  
                  </tbody>
                </table>                   */}
              </div>  
            </div> {/*end card-body*/}     
          </div>{/*end card*/}                      
   
   </>
  )
}
