import { useState } from 'react';
import { useEffect } from 'react'
import './_report.css';
import './_report_font.css';
import jsPDF from 'jspdf';
import Holding from '../rkp/Holding';
import MarketCap from '../rkp/Market Cap/MarketCap'
import axios from 'axios'
import { URL } from '../service/url'
import DynamicTable from './DynamicTable';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { parse, isSameDay } from 'date-fns';


export default function Report() {

    const { state } = useLocation()


    const brokerid1 = state?.id




    const admin = JSON.parse(localStorage.getItem('admindata'))
    const [invest, setInvest] = useState()

    // Ensure state is not null and extract brokerid
    const getmultiscriptapi = async () => {
        if (brokerid1) {
            var Investment = 0
            var formdata = new FormData()

            formdata.append('stock_name', '')
            formdata.append('admin_id', admin.user_id)
            formdata.append('broker_id', brokerid1)

            var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=multipleScriptStock`, formdata)

            result.data.data.map((val) => {
                console.log("holdiong amount ========" + val.INVESTED_HOLDING)
                Investment = Investment + val.INVESTED_HOLDING
            })
            setInvest(Investment)
        }
    }

    useEffect(() => {
        getmultiscriptapi()
    }, [])

    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [brokerid, setBrokerid] = useState(null)
    const [brokername, setBrokerName] = useState(admin.user_name)
    const [brokertype, setBrokerType] = useState(admin.user_type)
    const [currentholdingdata, setCurrentHoldingData] = useState([])


    const [total, setTotal] = useState();
    const [avragePrice, setAvragePrice] = useState();
    const [portfolioValue, setPortfolioValue] = useState();
    const [realisedPer, setRealisedPer] = useState();
    const [realisedPerNot0, setRealisedPerNot0] = useState();
    const [unRealisedPer, setUnRealisedPer] = useState();
    const [buyValue, setBuyValue] = useState();
    const [transaction, setTransaction] = useState();
    const [invested, setInvested] = useState();

    const [investedNot0, setInvestedNot0] = useState();
    const [currentHold, setCurrentHold] = useState();
    const [perGainValue, setPerGainValue] = useState();
    const [currentMonthData, setCurrentMonthData] = useState([]);

    const body = [{ brokerid: brokerid, brokername: brokername, brokertype: brokertype }]


    function getDate() {
        const today = new Date();
        return today.toDateString();
    }

    const [entries, setEntries] = useState([]);

    console.log(avragePrice, setBrokerName, setBrokerType, entries);

    function genratePDF() {
        var doc = new jsPDF();

        // Source HTMLElement or a string containing HTML.
        var elementHTML = document.querySelector("#sectiontoprint");

        doc.html(elementHTML, {
            callback: function (doc) {
                // Save the PDF
                doc.save('portfoilo.pdf');
            },
            margin: [10, 10, 10, 10],
            autoPaging: 'text',
            x: 0,
            y: 0,
            width: 100, //target width in the PDF document
            windowWidth: 675 //window width in CSS pixels
        });
    }

    const getholdingdata = async () => {

        var formdata = new FormData()
        formdata.append('bro_id', brokerid1)
        formdata.append('admin_id', admin.user_id)
        var result = await axios.post(`${URL}stockinfo&bpuser=10891&action=retrieve`, formdata)

        setCurrentHoldingData(result.data.data)


        console.log("log data data ========", result.data.data)

        // holding
        const values = result.data.data.map(item => item.CURRENT_HOLDING);
        const numbers = values.map(value => parseFloat(value?.replace(/,/g, '')));
        const sum = numbers.reduce((acc, val) => acc + val, 0);

        // quantity
        const quantity = result.data.data.map(item => item.QTY);
        const quantityNumber = quantity.map(value => parseFloat(value));
        const sumQuantity = quantityNumber.reduce((acc, val) => acc + val, 0);

        // current holding

        const currHold = result.data.data.map(item => item.CURRENT_HOLDING !== '0' ? item.CURRENT_HOLDING : "0");

        const currHoldNumber = currHold.map(value => parseFloat(value?.replace(/,/g, '')));
        const totalCurrentholding = currHoldNumber.reduce((acc, val) => acc + val, 0);

        // invested_holding
        const investedHolding = result.data.data.map(item => item.INVESTED_HOLDING);
        const investedHoldingNumber = investedHolding.map(value => parseFloat(value?.replace(/,/g, '')));
        const totalinvestedHolding = investedHoldingNumber.reduce((acc, val) => acc + val, 0);


        // invested_holding not 0
        const investedHoldingNot0 = result.data.data.map(item => item.INVESTED_HOLDING !== '0' ? item.INVESTED_HOLDING : "0");
        const investedHoldingNumberNot0 = investedHoldingNot0.map(value => parseFloat(value?.replace(/,/g, '')));
        const totalinvestedHoldingNot0 = investedHoldingNumberNot0.reduce((acc, val) => acc + val, 0);


        // avarage price
        const avaragePricePur = result.data.data.map(item => item.CURRENT_MARKET_PRICE);
        const avaragePricePurNumber = avaragePricePur.map(value => parseFloat(value?.replace(",", "")));
        const totalValueAvrage = avaragePricePurNumber.reduce((acc, val) => acc + val, 0);

        // Realised Per
        const realisedPer = result.data.data.map((item) => /*item.QTY !== '0' ?*/ item.REALISED_PER /*: "0.00"*/);

        const realisedPerNumber = realisedPer.map(value => parseFloat(value?.replace(/,/g, '')));
        const totalRealisedPer = realisedPerNumber.reduce((acc, val) => acc + val, 0);

        // Realised Per not 0
        const realisedPerNot0 = result.data.data.map((item) => item.QTY !== '0' ? item.REALISED_PER : "0.00");
        const realisedPerNumberNot0 = realisedPerNot0.map(value => parseFloat(value?.replace(/,/g, '')));
        const totalRealisedPerNot0 = realisedPerNumberNot0.reduce((acc, val) => acc + val, 0);

        // Realised Per
        const unRealisedPer = result.data.data.map(item => item.UNREALISED_PER);
        const unRealisedPerNumber = unRealisedPer.map(value => parseFloat(value?.replace(/,/g, '')));
        const totalUnRealisedPer = unRealisedPerNumber.reduce((acc, val) => acc + val, 0);


        // Per gain
        const perGain = result.data.data.map(item => item.PER_GAIN);
        const perGainNumber = perGain.map(value => parseFloat(value));
        const totalPerGain = perGainNumber.reduce((acc, val) => acc + val, 0);

        let netValue = 0;
        let totalNetValue = 0;
        for (var i = 0; i < result.data.data.length; i++) {
            netValue = result.data.data[i].QTY * result.data.data[i].CURRENT_MARKET_PRICE;
            totalNetValue = totalNetValue + netValue;
        }

        const avaragevalue = totalPerGain/result.data.data.length

        setTotal(sumQuantity)
        setAvragePrice(totalValueAvrage)
        setPortfolioValue(sum)
        setRealisedPer(totalRealisedPer)
        setRealisedPerNot0(totalRealisedPerNot0)
        setUnRealisedPer(totalUnRealisedPer)
        setInvested(totalinvestedHolding)

        setInvestedNot0(totalinvestedHoldingNot0)
        setCurrentHold(totalCurrentholding)
        setPerGainValue(avaragevalue)


    }

    useEffect(() => {
        getholdingdata()
    }, [brokerid])



    const [fromdate, setFromDate] = useState('')
    const [todate, setToDate] = useState('')
    const [rowData, setRowData] = useState([]);

    const getdate = () => {
        let dates = document.getElementById('reservation').value
        let fromdate = dates.split('-')
        setFromDate(fromdate[0])
        setToDate(fromdate[1])
    }

    const getholding = async () => {

        var formdata = new FormData()
        formdata.append('bro_id', brokerid1)
        formdata.append('admin_id', admin.user_id)
        formdata.append('firsttime', 0)
        var result = await axios.post(`${URL}stockinfo&bpuser=10891&action=getsummary`, formdata)

        setRowData(result.data.data)

        // BUY VALUE
        const buyValue = rowData.map(item => item.BUY_VALUE);
        const buyValueNumber = buyValue.map(value => parseFloat(value?.replace(",", "")));
        const totalBuyValue = buyValueNumber.reduce((acc, val) => acc + val, 0);
        setBuyValue(totalBuyValue)
        // console.log("log value buy ==== ",totalBuyValue)
    }

    useEffect(() => {
        getholding()
    }, [fromdate, todate])

    useEffect(() => {

        var formdata = new FormData()
        formdata.append('broker_id', brokerid1)
        formdata.append('admin_id', admin.user_id)

        console.log("form date =-==================", formdata)

        const apiUrl = `${URL}stockinfo&bpuser=10891&action=transctionhistoryforreport`



        axios.post(apiUrl, formdata)
            .then(response => {
                setData(response.data);
                setEntries(response.data);
                console.log("value data ======", response.data.data)
                // var dataAllValue = Object.entries(data.data)
                var TotalTransactionTypeValue = 0
                for (let i = 0; i < response.data.data.length; i++) {
                    if (response.data.data[i].Transaction_Type === "Buy") {
                        TotalTransactionTypeValue += parseFloat(response.data.data[i].Transaction_Value);


                    }
                }
                console.log("tran ======= ", TotalTransactionTypeValue)
                setTransaction(TotalTransactionTypeValue)


            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    var kun = Object.entries(data.data)

    var a = kun.flat();
    const processedData = [];

    // Loop through the array
    for (let i = 1; i < a.length; i += 2) {
        const item = a[i];

        // Access the date property and push it to the dates array
        const processedItem = {
            index: data[i - 1],
            date: item.DATE,
            transactionType: item.Transaction_Type,
            company: item.Company,
            price: item.Price,
            qty: item.Qty,
            TransactionValue: item.Transaction_Value

        };

        // Push the processed item to the array
        processedData.push(processedItem);
    }

    // // Ensure that entries is an array before using filter
    // const decemberEntries = Array.isArray(entries)
    //     ? entries.filter(entry => {
    //         const entryDate = new Date(entry.date);
    //         return entryDate.getMonth() === decemberIndex;
    //     })
    //     : [];

    // const januaryEntries = Array.isArray(entries)
    //     ? entries.filter(entry => {
    //         const entryDate = new Date(entry.date);
    //         return entryDate.getMonth() === actualMonth;
    //     })
    //     : [];


    // Now, processedData contains an array of processed items

    // Assuming the data structure, adjust the code accordingly
    const sortedData = processedData.sort((a, b) => b.TransactionValue - a.TransactionValue);

    //   // Get the top 5 gainers and top 5 losers
    const top5Gainers = sortedData.slice(0, 5);

    const top5Losers = sortedData.slice(-5);

    // top 5 gainers
    const top5 = top5Gainers.map(item => item.TransactionValue);


    const top5Number = top5.map(value => parseFloat(value));


    const totaltop5Gainer = top5Number.reduce((acc, val) => acc + val, 0);

    // top 5 losers
    const top5Per = top5Losers.map(item => item.TransactionValue);
    const top5PerNumber = top5Per.map(value => parseFloat(value));
    const totaltop5Loser = top5PerNumber.reduce((acc, val) => acc + val, 0);

    console.log(totaltop5Gainer, totaltop5Loser)

    const currDate = new Date().toLocaleDateString();
    const currTime = new Date().toLocaleTimeString();
    console.log("date ppppp", currDate)
    console.log("date time     ", currTime, getdate)

    // let percentageChangeFormatted;

    // // Check if buyValue is 0
    // if (buyValue !== 0) {
    // Calculate the percentage change
    // const percentageChange = (((holding - buyValue) / buyValue) * 100);

    // Format the percentage change with plus/minus sign
    // percentageChangeFormatted = (percentageChange >= 0 ? '+' : '') + percentageChange.toFixed(2) + '%';
    const percentageChangeFormatted = (((portfolioValue - invested) / invested) * 100)?.toFixed(2);
    // } else {
    //     // Handle division by zero case
    //     percentageChangeFormatted = "Cannot calculate percentage change: division by zero";
    // }



    // // Calculate the percentage change and handle Infinity case
    // const percentageChange = (((holding - buyValue) / buyValue) * 100);
    // // Format the percentage change with plus/minus sign
    // const percentageChangeFormatted = (percentageChange >= 0 ? '+' : '') + percentageChange.toFixed(2);


    return (
        <>
            <div className="wrap-new">
                <div style={{ width: "100%", float: "left", textalign: "left", marginbottom: "4px", paddingleft: "6px" }}>
                    <br />
                    {/* <button onClick={handlePrint} className="btn btn-primary" style={{marginBottom:"30px"}}><i className="fa fa-save"></i> Save as PDF</button> */}
                    <button onClick={genratePDF} className="btn btn-primary" type="primary" style={{ marginBottom: "30px" }}><i className="fa fa-save"></i> Save as PDF</button>
                </div>

            </div>

            <div id="sectiontoprint" style={{ width: "1750px" }}>
                <div className="wrap page-break page">

                    <div className="top-main_cover page-break page">
                        <div className="maindivpage coverbg">
                            <div className="stcover1">
                                <div className="stcover2">
                                    <img src="./dist/img/cover-head.png" className='img-fluid' alt='' />
                                </div>
                                <div className="stcover3">
                                    Report as on {getDate()}</div>
                                <div className="stcover4">

                                </div>
                                <div className="stcover7">
                                    <div className="row">
                                        <div className="col-md-6 stcover8">
                                            <div className="stcover12">
                                                <p className="stcover11">Portfolio Value:</p>
                                                <p className="stcover10">Rs {portfolioValue?.toFixed(2)}</p>
                                                {/* <p className="stcover10">Rs 0 (Upcoming)</p> */}
                                            </div>
                                            <div className="stcover12">
                                                <p className="stcover11">Net Investment:</p>
                                                <p className="stcover10">Rs {invested?.toFixed(2)}</p>
                                                {/* <p className="stcover10">
                                                    Rs {invest?.toFixed(2)}</p> */}
                                            </div>
                                            <div className="stcover12">
                                                <p className="stcover11">Total Returns:</p>
                                                <p className="stcover10">Rs {realisedPer?.toFixed(2)}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6 stcover13">

                                        </div>
                                    </div>
                                    <div className="row">

                                    </div>
                                </div>
                            </div>
                            <div className="stcover21">

                            </div>
                        </div>
                    </div>
                    <div className="top-main page-break page">
                        <div className="maindivpage">
                            <div className="streporthead4">
                            </div>
                            <div className="pagecontent">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="pageheading">Your Portfolio Performance Report</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">

                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="graphcontent">
                                            {/* <p>Net Investment: <strong>Rs {invest?.toFixed(2)}</strong></p> */}
                                            <p>Net Investment: <strong>Rs {invested?.toFixed(2)}</strong></p>
                                            <p>Portfolio Value: <strong>Rs {portfolioValue?.toFixed(2)}</strong></p>
                                            {/* <p>*Including Cash of Rs 363</p> */}
                                            <p>Portfolio Return: <strong>{percentageChangeFormatted} %</strong></p>
                                            <p>Realized Return: <strong>Rs {realisedPer?.toFixed(2)}</strong></p>
                                            {/* <p>Unrealized Return: <strong>Rs {unRealisedPer}</strong></p> */}
                                            <p>Unrealized Return: <strong>Rs {unRealisedPer?.toFixed(2)}</strong></p>
                                            <p>Dividend: <strong>Rs 0.00</strong></p>
                                            <p>Total Return: <strong>Rs {realisedPer?.toFixed(2)}</strong></p>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div id="stockscore" className="highcharts-container" data-highcharts-chart="2">
                                            <div id="highcharts-rhzhvws-6" dir="ltr" className="highcharts-container ">
                                                {/* <img src="./dist/img/top-gainer.jpg" className='img-fluid' alt='' /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pagecontent">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="mt1">Top 5 Contributors gainer and Looser</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mt5">
                                            <table className="table">
                                                <tbody>
                                                    {top5Gainers.map((listItems) => {
                                                        return (<tr>
                                                            <td className="mt4">{listItems.company}</td>
                                                            <td><i className="icon-caret-up"></i> <span className="green">Rs {listItems.TransactionValue} </span></td>
                                                        </tr>)
                                                    })}
                                                    {/* <tr>
                                                        <td className="mt4">AIAENG</td>
                                                        <td><i className="icon-caret-up"></i> <span className="green">Rs 2,44,818 </span></td>
                                                    </tr>
                                                     <tr>
                                                        <td className="mt4">ACE</td>
                                                        <td><i className="icon-caret-up"></i> <span className="green">Rs 2,294</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="mt4">CHENNPETRO</td>
                                                        <td><i className="icon-caret-up"></i> <span className="green">Rs 1,12,367</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="mt4">ELECON</td>
                                                        <td><i className="icon-caret-up"></i> <span className="green">Rs 2,40,504</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="mt4">IDFCFIRSTB</td>
                                                        <td><i className="icon-caret-up"></i> <span className="green">Rs 24,180</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="mt4">INDTONER</td>
                                                        <td><i className="icon-caret-up"></i> <span className="green">Rs 48,255</span></td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mt5">
                                            <table className="table">
                                                <tbody>
                                                    {top5Losers.map((items) => {
                                                        return (<tr>
                                                            <td className="mt4">{items.company}</td>
                                                            <td ><i className="icon-caret-up"></i> <span className="red">Rs {items.TransactionValue}</span></td>
                                                        </tr>)
                                                    })}
                                                    {/* <tr>
                                                        <td className="mt4">ACCEELYA</td>
                                                        <td ><i className="icon-caret-up"></i> <span className="red">Rs -7,089</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="mt4">ANDHRPAP</td>
                                                        <td ><i className="icon-caret-up"></i> <span className="red">Rs -13,334</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="mt4">ASHOKA</td>
                                                        <td ><i className="icon-caret-up"></i> <span className="red">Rs -105,124</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="mt4">BOSCHLTD</td>
                                                        <td ><i className="icon-caret-up"></i> <span className="red">Rs -135,944</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="mt4">GRSE</td>
                                                        <td ><i className="icon-caret-up"></i> <span className="red">Rs -631,390</span></td>
                                                    </tr>

                                                    <tr>
                                                        <td className="mt4">KPITTECH</td>
                                                        <td ><i className="icon-caret-up"></i> <span className="red">Rs -18,775</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="mt4">NESTEIND</td>
                                                        <td ><i className="icon-caret-up"></i> <span className="red">Rs -35,111</span></td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pagecontent">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="pagecontent3">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="mt6">ALLOCATIONS</p><br /><br />
                                                </div>
                                            </div>
                                            <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div className="col-md-5">
                                                    <h3>Holding</h3>
                                                    <div style={{ paddingTop: '50px' }}>
                                                        <Holding body={body} />
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <h3>Margin Cap</h3>
                                                    <MarketCap body={body} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="top-main page-break page">
                        <div className="maindivpage">
                            <div className="pagecontent">
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="pagecontent3">
                                            <div className="row">

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pagecontent">
                                <div className="pagecontent2 border_not_bottom">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p className="mt8">TRANSACTION HISTORY (Last 1 Month)</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="sttable14_2">
                                                <table className="table">
                                                    <tbody>
                                                        <tr className="no-mob no-tab2">
                                                            <td className="borderleft mt9 mt10_1">Date</td>
                                                            <td className="mt9 text-left">Transaction Type </td>
                                                            <td className="mt9 text-left mt10">Company</td>
                                                            <td className="mt9 text-right mt10_3">Price</td>
                                                            <td className="mt9 text-right mt10_3">Qty</td>
                                                            <td className="mt10_2 borderright text-right mt9">Transaction Value</td>
                                                        </tr>


                                                        {Object.entries(data.data).map(([index, item]) => (
                                                            <tr key={index}>
                                                                <td className="borderleft">{item.DATE}</td>
                                                                <td className="text-left"><span className={item.Transaction_Type == "Sell" ? "red-text" : "green-text"}>{item.Transaction_Type}</span></td>
                                                                <td className="text-left mt10">{item.Company}</td>
                                                                <td className="text-right mt10_3">{item.Price}</td>
                                                                <td className="text-right mt10_3">{item.Qty}</td>
                                                                <td className="borderright text-right no-mob no-tab2">{item.Transaction_Value}</td>
                                                            </tr>
                                                        ))}

                                                        {/* {currentMonthData.length === 0 ?

                                                            <tr><td colSpan={6} align='center'><h1>Not Found Data</h1></td></tr>
                                                            : currentMonthData.slice(0, 30).map(([key, entry], index) => (
                                                                <tr key={key}>
                                                                    <td className="borderleft">{entry.DATE}</td>
                                                                    <td className="text-left"><span className={entry.Transaction_Type === "Sell" ? "red-text" : "green-text"}>{entry.Transaction_Type}</span></td>
                                                                    <td className="text-left mt10">{entry.Company}</td>
                                                                    <td className="text-right mt10_3">{entry.Price}</td>
                                                                    <td className="text-right mt10_3">{entry.Qty}</td>
                                                                    <td className="borderright text-right no-mob no-tab2">{entry.Transaction_Value}</td>
                                                                </tr>

                                                            ))} */}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="top-main page-break page">
                        <div className="maindivpage">
                            <div className="streporthead4">

                            </div>
                            <div className="top-main page-break page">

                                <div className="maindivpage">
                                    <div className="pagecontent">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mt19">
                                                    CURRENT HOLDINGS
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <DynamicTable data={currentholdingdata} />
                                        </div>
                                        <div>
                                            <table className="table" border="0">
                                                <tbody>
                                                    <tr >
                                                        <td className="borderleft" style={{ color: "blue" }}><strong>Total</strong></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right" style={{ color: "blue" }}><strong>{currentHold?.toFixed(2)}</strong></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right" style={{ color: "blue" }}><strong>{investedNot0?.toFixed(2)}</strong></td>
                                                        <td className="text-right"></td>
                                                        <td className="text-right" style={{ color: "blue" }}><strong>{unRealisedPer?.toFixed(2)}</strong></td>
                                                        <td className="text-right" style={{ color: "blue" }}><strong>{realisedPerNot0?.toFixed(2)}</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pagecontent">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mt13">
                                            STOCK WISE RETURNS
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mt19 margin40">
                                            STOCKS SOLD
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="pagecontent4">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="sttable14_2">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr className="bordertop">
                                                                    <th className="borderleft mt9 mt10_10">Company</th>
                                                                    <th className="mt9 text-right">Price</th>
                                                                    <th className="mt9 text-right">Qty</th>
                                                                    <th className="mt9 text-right" >Realised<br /> Gain/Loss (INR)</th>
                                                                    {/* <th className="mt9 text-right borderright" >Realised<br /> Gain/Loss (%)</th> */}
                                                                </tr>
                                                                {
                                                                    currentholdingdata?.map((obj) => {

                                                                        return (

                                                                            <tr style={{ justifyItems: "center", alignItems: "center" }}>
                                                                                <td className="text-left">{obj.STOCKS}</td>
                                                                                <td className="text-right">{obj.CURRENT_MARKET_PRICE}</td>
                                                                                <td className="text-right">{obj.QTY}</td>
                                                                                <td className="text-right">{obj.REALISED_PER}</td>
                                                                                <td className="text-right">{obj.PER_GAIN?.toFixed(2)}</td>
                                                                            </tr>
                                                                        );

                                                                    })
                                                                }
                                                                <tr>
                                                                    <td className="borderleft mt9">Total</td>
                                                                    <td className="text-right" style={{ color: "blue" }}><b></b></td>
                                                                    <td className="text-right" style={{ color: "blue" }}><b></b></td>
                                                                    <td className="borderright text-right" style={{ color: "blue" }}><b>{realisedPer?.toFixed(2)}</b></td>

                                                                    <td className="borderright text-right" style={{ color: "blue" }}><b>{perGainValue?.toFixed(2)}</b></td>

                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class='col-sm-6'>
                        <div class="form-group">
                            <div class='input-group date' id='datetimepicker5'>
                                <input type='text' class="form-control" />
                                <span class="input-group-addon">
                                    <span class="glyphicon glyphicon-calendar"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}