import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
//import { createRoot } from 'react-dom/client';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import axios from 'axios';
import { URL } from './service/url';

export default function Consolidated(props) {


  const admindetail=JSON.parse(localStorage.getItem('admindata'))
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowTransData, setselectedRowTransData] = useState([]);




  const columnDefs = useMemo(() => [
    { field: 'STOCKS',width: 100, suppressSizeToFit: true, headerTooltip: 'Stocks' , cellStyle: { textAlign: 'left' } },
    { field: 'QTY',width: 80, suppressSizeToFit: true , cellStyle: { textAlign: 'left' }  },
    { field: 'CMP',width: 80, suppressSizeToFit: true , cellStyle: { textAlign: 'left' }  },
    // { field: 'CHANGES', width: 110, suppressSizeToFit: true , cellStyle: { textAlign: 'left' }},
 
], []);

const defaultColDef = useMemo(() => ({
  resizable: true,
  sortable: true,
  filter:true,
  floatingFilter:true,
  cellRenderer:false,
  flex:1,
  minWidth: 100,
  editable:true,
  suppressSizeToFit: true,
  sortable: true,
 // flex:1
}), []);

const [rowData, setRowData] = useState();




// .....................................

useEffect(()=>{

  setBrokerid(props.body[0].brokerid)
  setBrokerName(props.body[0].brokername)
  setBrokerType(props.body[0].brokertype)


},[props])


const [brokerid,setBrokerid]=useState()
const [brokername,setBrokerName]=useState()
const [selectedbrokername,setSelectedBrokerName]=useState('')
const [brokertype,setBrokerType]=useState()

// console.log('consolidateid',brokerid)
// console.log('consolidatename',brokername)
// console.log('consolidatetype',brokertype)

// .....................................

// .............................................CURRENT HOLDING... 

const getholdingdata=async()=>{
  

  var formdata=new FormData()
  

  
  formdata.append('admin_id',admindetail.user_id)
  formdata.append('bro_id',undefined)

  var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=getallaccountDetails`,formdata)
 
  // console.log('resultconsolidate.......',result)
  let data=result.data.data.filter((item)=>item.QTY!=0)
 
  // setRowData(result.data.data)
  setRowData(data)
  
 }

useEffect(()=>{

getholdingdata()

},[brokerid])



const handleRowClicked = (event) => {
  
  setSelectedRowData(event.data);
  setShowModal(true);

};



const handleRowallClicked =async(event) =>{
 
  

  setSelectedBrokerName(event.data.ACCOUNT_NAME)
  
  const formdata = new FormData();
  formdata.append('stock_name', event.data.STOCKS);
  formdata.append('admin_id', admindetail.user_id); 
  formdata.append('broker_id', event.data.broker_id); 
  const response = await axios.post(`${URL}stockInfo&bpuser=10891&action=singleScriptStock`, formdata);
  setselectedRowTransData(response.data.data)


}


const columnDefTran = useMemo(() => [
      
  { field: 'DATE',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:120,
  cellStyle: { textAlign: 'center' }
},
  { field: 'BUY_QUANTITY',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:150,
  cellStyle: { textAlign: 'right' }
},
  { field: 'BUY_RATE',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:120,
  cellStyle: { textAlign: 'right' }
},
  { field: 'BUY_VALUE',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:120,
  cellStyle: { textAlign: 'right' }
},
  { field: 'SELL_QUANTITY',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:150,
  cellStyle: { textAlign: 'right' }
},
  { field: 'SELL_RATE',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:120,
  cellStyle: { textAlign: 'right' }
},
  { field: 'SELL_VALUE',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:120,
  cellStyle: { textAlign: 'right' }
},
  { field: 'CUMULATIVE_VALUE',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:180,
  cellStyle: { textAlign: 'right' }
},
  { field: 'CUMULATIVE_QTY',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:180,
  cellStyle: { textAlign: 'right' }
},
  { field: 'REALISED',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:120,
  cellStyle: { textAlign: 'right' }
},
  { field: 'UNREALISED',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:150,
  cellStyle: { textAlign: 'right' }
},

], []);


const defaultColDeTran = useMemo(() => ({
  editable:true,
  resizable: true,
  sortable: true,
  filter:true,
  floatingFilter:true,
  cellRenderer:true,
  flex:1,
  maxWidth: 300,
  suppressSizeToFit: true,

}), []);







const Modal = ({ show, rowData }) => {

    


  const [stockInfo, setStockInfo] = useState([]);
  const [loading, setLoading] = useState(true);


   


  const columnD = useMemo(() => [
    { field: 'SNO',
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:80,
      cellStyle: { textAlign: 'center' }
    },

    { field: 'ACCOUNT_NAME', 
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:200,
      cellStyle: { textAlign: 'center' }
   
    } ,
    { field: 'STOCKS',width:100,
    resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:200,
    cellStyle: { textAlign: 'center' }
 
  } ,
    { field: 'QTY',width:100,resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:200,
    cellStyle: { textAlign: 'right' }
 
  } ,
    { field: 'AVERAGE_PRICE',width:100,resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:200,
    cellStyle: { textAlign: 'right' }
 
  } ,
  { field: 'CMP',width:100,resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:200,
  cellStyle: { textAlign: 'right' }

  },
    // { field: 'CMP',width:100,},
    { field: 'INVESTED_HOLDING',width:100,resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:200,
    cellStyle: { textAlign: 'right' }
 
  } ,
    { field: 'TOTAL_REALISE_PROFIT',width:100,resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:200,
    cellStyle: { textAlign: 'right' }
 
  } ,
  { field: 'INVESTED',width:100,resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:200,
  cellStyle: { textAlign: 'right' }

},
    { field: 'CURRENT_HOLDING',width:100,resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:200,
    cellStyle: { textAlign: 'right' }
 
  },
  { 
    field: 'PER_GAIN',width:100,resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:200,
    cellStyle: { textAlign: 'right' }

  }  

    
    // { field: 'MARKET VALUE',width:100,},
    // { field: 'UNREALISED',width:100,},
    // { field: 'REALISED',width:100,}
], []);


const defaultColD = useMemo(() => ({
  editable:true,
  resizable: true,
  sortable: true,
  filter:true,
  floatingFilter:true,
  cellRenderer:true,
  flex:1,
  maxWidth: 300,
  suppressSizeToFit: true,
  

}), []);





  useEffect(() => {
    const fetchStockInfo = async () => {
      try {
        const formdata = new FormData();
        formdata.append('stock_name', rowData.STOCKS);
        formdata.append('admin_id', admindetail.user_id); 
        formdata.append('broker_id', null); 

        // console.log('stock_name', rowData.STOCKS);
        // console.log('admin_id', admindetail.user_id);
        // console.log('broker_id', brokerid); 

        

        var response = await axios.post(`${URL}stockInfo&bpuser=10891&action=multipleScriptStock`,formdata)
        // console.log(response.data.data,'data.....')
        setStockInfo(response.data.data)
        // console.log( response.data.data,'dataaa'); 
        setLoading(false);
      } catch (error) {
        console.error('Error fetching stock information:', error);
        setLoading(false);
      }
    };

    if (show && rowData) {
      fetchStockInfo();
    }
  }, [show, rowData]);

  if (!show) {
    return null;
  }


  const Doempty=()=>{

    setselectedRowTransData([])
    setSelectedBrokerName('')

  }


  return (
    <div className={`modal fade ${show ? 'show d-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: show ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{selectedRowData.STOCKS}</h5>
        
            
              <button  type="button" style={{background:'#ffff',border:'none',padding:'10px'}} aria-label="Close" onClick={() => {setShowModal(false)
              Doempty()}}>
              <img  style={{width:'20px'}}  src='../assets/cancel.png'/>
            </button>
          </div>
          <div className="modal-body" style={{display:'flex',flexDirection:'column',height:selectedbrokername?'700px':'350px'}}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                {Array.isArray(stockInfo) && stockInfo.length > 0 && (
                  <div className="ag-theme-alpine" style={{ height: '300px', width: '100%' }}>
                    <AgGridReact
                      rowData={stockInfo}
                      columnDefs={columnD}
                      defaultColDef={defaultColD}
                      onRowClicked={handleRowallClicked}
                    />
                  </div>
                )}
                {Array.isArray(selectedRowTransData) && selectedRowTransData.length > 0 && (
                  <div className="ag-theme-alpine " style={{ height:'300px', width: '100%'}}>
                   <div style={{display:'flex',alignItems:'center',justifyContent:'left',margin:'10px'}}>
                   <h5 className="modal-title" >{selectedbrokername}</h5>
                   </div>
                  
                    <AgGridReact
                      rowData={selectedRowTransData}
                      columnDefs={columnDefTran}
                      defaultColDef={defaultColDeTran}
                    />
                  </div>
                )}
                {(!Array.isArray(stockInfo) || stockInfo.length === 0) && !loading && (
                  <p>No data available</p>
                )}
              </>
            )}
          </div>
          {/* <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};


// .........................................END CURRENT HOLDING...


  return (
    <div className="card" >
    <div className="card-header">
      {/* <div style={{fontSize:'15px',fontWeight:"500"}}>CONSOLIDATED STOCK'S</div> */}
      <h3 className="card-title">CONSOLIDATED STOCK'S</h3>
      <div className="card-tools">
{/* 
       <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
          <i className="fas fa-minus"></i>
        </button>
          */}
      </div>
    </div>
    <div className="card-body">  
    <div className="ag-theme-alpine" style={{width: '100%', height: 590}}>
    
        <AgGridReact className="ag-theme-alpine"

          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          enableRangeSelection="true"
          enableCharts={true}
          animateRows="true"
          rowSelection="multiple"
          suppressRowClickSelection="true"
          pagination={false}
          onRowClicked={handleRowClicked}

        />


    </div>
       

    
    </div>

    <Modal show={showModal} rowData={selectedRowData} />
    
  </div>
   
           

   
  )
}
