import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import { useState} from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { URL } from './service/url'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import inddustrytype from "./industrytype.json"
import { JSONURL } from './service/url'
import {  useRef,  useMemo, useCallback} from 'react';

// ag Grid....
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'ag-grid-enterprise';
// ag Grid


export default function Masterstock(props) {

// ag grid property functions ..............
  
  const columnDefs = useMemo(() => [
    { headerName:'STOCKS', field: 'STOCKS'},
    { headerName:'MARKETCAP', field: 'MARKETCAP'},
    { headerName:'INDUSTRY', field: 'INDUSTRY'},
  
  ], []);


  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true,
    flex:1,
    filter:true,
    floatingFilter:true,
    cellRenderer:false,
    

  }), []);

  const [rowData, setRowData] = useState([]);

  // ag grid property functions ..............

  
const dispatch=useDispatch()

const admin=JSON.parse(localStorage.getItem('admindata'))
const navigate=useNavigate()

const [brokerdata,setBrokerdata]=useState([])
const [broker,setBroker]=useState('')

const [trandate,setTranDate]=useState('')
const [trantype,setTranType]=useState('')
const [quantity,setQuantity]=useState('')
const [stockprice,setStockPrice]=useState('')
const [stockname,setStockName]=useState('')
const [industry,setIndustry]=useState('')

const [marketcap,setMarketCap]=useState('')
const [dealtype,setDealType]=useState('')
const [netvalue,setNetValue]=useState('')
const [stocksvalue,setStockvalue]=useState('')
const [netqty,setNetQty]=useState('')
const [cumulativeqty,setCumulativeQty]=useState('')
const [remark,setRemark]=useState('')
const [cumulativevalue,setCumulativevalue]=useState('')
const [error,setError]=useState({})
const [checked, setChecked] = useState(true);
const [swalProps, setSwalProps] = useState({});
const Swal = require('sweetalert2')
const [brokerid,setBrokerid]=useState()
const [brokername,setBrokerName]=useState()
const [ave,setAverage]=useState('')
const [industrylist,setIndustryList]=useState([])

// [0]...............................
const [newindustry,setNewIndustry]=useState('')
// [0]...............................




  



const admindetail=JSON.parse(localStorage.getItem('admindata'))




 // ...............api get broker 

 const getbroke=async()=>{
  var formdata=new FormData()
  formdata.append('admin_id',admindetail.user_id) 
  const result= await axios.post(`${URL}brokerinfo&bpuser=10891&action=getbrokers`,formdata)
  setBrokerdata(result.data.data)
  
  }

  const fetchindustry=async()=>{
    const formdata=new FormData()
    formdata.append('admin_id',admindetail.user_id)
    const response = await axios.post(`${URL}stockInfo&bpuser=10891&action=getIndustry`,formdata);
    const industry =  response.data.data
    setIndustryList(industry)
    
};


 const getstock=async()=>{
  var formdata=new FormData()
  formdata.append('admin_id',admindetail.user_id) 
  const result= await axios.post(`${URL}stockInfo&bpuser=10891&action=masterStocks`,formdata)
  setRowData(result.data.data)
  
  }
  
  
useEffect(()=>{

  getbroke()
  fetchindustry()
  getstock()
  
  },[])





const showbroker=()=>{

 return  brokerdata.map((val)=>{
 return (   
      <><option value={val.user_id} >{val.user_name}</option></>
      )})}

const showidustry=()=>{
return industrylist.map((val)=>{
   return (
     <>
        <option value={val.industry_name} >{val.industry_name}</option>
      </>
) })}
  


const handleerror=(input,value)=>{


  setError((prev)=>({...prev,[input]:value}))
  
  }


const stockset=async(value)=>{
  
 setStockName(value)



}


 

// Validation function 

const validation=()=>{
  
  var isvalid=true
  
  if(trandate=="")
  {
    handleerror("s_tran_date","Please Enter Transaction Date")
    isvalid=false
  }

  if(stockname=="")
  {
    handleerror("s_name","Please Enter Stock Name")
    isvalid=false
  }
  if(industry=="")
  {
    handleerror("s_industry","Please Enter Stock Industry")
    isvalid=false
  }

  if(marketcap=="")
  {
    handleerror("s_market_cap","Please Select Market Cap")
    isvalid=false
  }




  if(broker=="")
  {

    handleerror("s_broker","Please Select Broker")
    isvalid=false
    

  }
   


  return isvalid
  
  }


const Submit=async()=>{


  if(validation()){
 

  
  var formdata = new FormData

   var body=[{st_name:stockname,st_type:marketcap,st_trans_type:trantype,st_qty:quantity,st_price:stockprice,st_lpt:"",st_remark:remark,broker_charge:cumulativeqty,trans_charge_sebi:stocksvalue,stamp_duty:netqty,stt_paid:netvalue,total_charge:"",profit_loss:"",entrydate:"",editby:"",editdate:"",status:"",industry_name:industry,st_deal_type:dealtype,buy_sell_date:trandate}]

    
    var fullformat=JSON.stringify({
     
      Tablename:'stockinfo_log',
     
      b_admin_id:admindetail.user_id,
     
      broker_id:broker,
     
      body:body

    
    })


    

    var result = await axios.post(`${URL}stockInfoexcel&bpuser=10891&action=stockDetailInsert`,fullformat)
 



    if(result.data.res_code==1)
    {
      
     
      Swal.fire({
        position: 'top-center',
        icon: 'success',
        title: 'Add Stock Successfully',
        showConfirmButton: false,
        timer: 1600
      }).then((result) => {
         
         navigate('/Dashboard')
       
        })
   
   
    }
   
   
  }
  else
  {
    
  }

  
 
}



const  refreshPage=()=>{
  
  window.location.reload(false);

}


const brokerrr=(bid,bnm)=>{

  var body={bro_id:bid,bro_name:bnm}
  var bi ='broker'

  dispatch({type:'brokeridset',payload:[bi,body]})
  

 
}

var bro  = useSelector((state)=>state.broker) 

useEffect(()=>{

if(!bro.broker)
{
  setBrokerid(null)
  
}
else
{
  setBrokerid(bro.broker.bro_id)
  setBrokerName(bro.broker.bro_name)
 
}
  
},[bro.broker])








const showallredyexiststock=async()=>{

  var formdata=new FormData()
  formdata.append('stock_name',stockname)
  formdata.append('broker_id',broker)

  formdata.append('admin_id',admindetail.user_id)


  var result = await axios.post(`${URL}stockInfoexcel&bpuser=10891&action=stockCheckExits`,formdata)

  if(result.data.res_code == 1)
    {

      Swal.fire({
        position: 'top-center',
        icon: 'error',
        title: 'Stocks already exist',
        // showConfirmButton: true,
        
        timer: 1600
      }).then((result) => {
       
      
        window.location.reload(false);
           
       
        })
   
    }
    else
    
    {
      
    Submit()
    
  }


}




const addindustry=async(e)=>{
  e.preventDefault();
  if(newindustry!==''){
  const formdata=new FormData()
  formdata.append("industry_name",newindustry)
  formdata.append("admin_id",admindetail.user_id)




  const response = await axios.post(`${URL}stockInfo&bpuser=10891&action=industryAdd`,formdata)
 


  if(response.data.res_code==1){
  
    Swal.fire({
      position: 'top-center',
      icon: 'success',
      title: 'Industry Add Successfully',
      showConfirmButton: false,
      timer: 1600
    }).then((result) => {
     
      
      fetchindustry()
      setNewIndustry('')
  
     
      })
   
  }
  else 
  {

    
    Swal.fire({
      position: 'top-center',
      icon: 'error',
      title: 'Already Industry Exist',
      showConfirmButton: false,
      timer: 1600
    }).then((result) => {
     
    
      fetchindustry()
  
     
      })

  }


  }
  else
  {
  
    Swal.fire({
      position: 'top-center',
      icon: 'error',
      title: 'Please Enter Industry Type',
      showConfirmButton: true,
      
    })
   
  }
   
  };



  return (
    <>
    <div className="wrapper">
    <Header   refresh={refreshPage}   brokerr={brokerrr} />
    <div className="content-wrapper">
    <section className="content-header">
      <div className="container-fluid">
     
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1>Add Master Stock</h1>
          </div>
          <div className="col-sm-4">
            <h1>Master Stocks List</h1>
          </div>
          <div className="col-sm-2">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="/Dashboard">Home</a></li>
              <li className="breadcrumb-item active">Add Master Stock</li>
            </ol>
          </div>
        </div>
       

      </div>
    </section>
    <section className="content">
      <div className="container-fluid">
        
    <div className='row'>
    <div className="col-sm-6">
        <div className="card card-default">
         
         <div class="card-body" style={{ height: 440}} >


         

                  <div className="row">

                    <div className="col-sm-6">
                      
                      <div className="form-group">
                        
                        <label>DATE  &nbsp;&nbsp; BUY / SELL (DD/MM/YYYY)</label>
                        <input  onFocus={()=>handleerror('s_tran_date',null)}   onChange={(e)=>setTranDate(e.target.value)}  type="date" className="form-control" placeholder="Enter ..."/>
                     
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      
                       {error.s_tran_date} 
                      
                      </div>
                     
                    </div>

                    <div className="col-sm-6">
                      
                      <div className="form-group">
                        <label>ACCOUNT</label>
                        <select   onFocus={()=>handleerror('s_broker',null)}   onChange={(e)=>setBroker(e.target.value)}  className="form-control">
                          <option value={null} >--Select Account--</option>
                          {showbroker()}
                        </select>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.s_broker}
                      </div>

                    </div>

                    
                    
                    
                  </div> 

                  <div className="row">

                 

                  <div className="col-sm-6">
                      
                      <div className="form-group">
                        <label>STOCK NAME</label>
                        <input  onFocus={()=>handleerror('s_name',null)} onChange={(e)=>stockset(e.target.value)}   type="text" className="form-control" placeholder="Enter Stock Name"/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.s_name}
                      </div>
                    </div>


                    <div className="col-sm-6">
                      
                      <div className="form-group">


                        <label>Add New Industry Type</label> 
                        <a  data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1"> <img src="dist/img/plus.png"  alt="User Image" /> Add Industry</a> 
                        <div class="collapse multi-collapse" id="multiCollapseExample1">
                          <div class="card card-body">
                          <input  onChange={(e)=>setNewIndustry(e.target.value)}  type="text" className="form-control" placeholder="Enter New Industry"/>
                          <button style={{marginTop:'10px'}} type="button" class="btn btn-primary"  onClick={(e)=>addindustry(e)} >Add</button>
                          </div>
                          
                        </div>




                        <select  onFocus={()=>handleerror('s_industry',null)}  onChange={(e)=>setIndustry(e.target.value)}  className="form-control">
                           <option value={null} >--Select Industry--</option>
                        
                           {showidustry()}
                        </select>
                      </div>


                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.s_industry}
                      </div>
                    </div>

                    

                  
                  
                    
                  </div>

                  <div className='row'>
                  

                    <div className="col-sm-6">
                     
                     <div className="form-group">
                       <label>MARKET CAP</label>
                         <select onFocus={()=>handleerror('s_market_cap',null)}   onChange={(e)=>setMarketCap(e.target.value)} className="form-control">
                         <option value="Micro" >Select Market Cap</option>
                          <option value="Micro" >Micro</option>
                          <option value="Small" >Small</option>
                          <option value="Middle" >Middle</option>
                          <option value="Large" >Large</option>
                       </select>
                     </div>
                     <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                       {error.s_market_cap}
                     </div>
                   
                   </div>



                  </div>

            

                </div>
                <div className="card-footer">
                  <button  onClick={()=>showallredyexiststock()}    type="button" className="btn btn-block btn-primary" style={{width:'200px', float:'none', margin:'0 auto' }}>Submit</button>
              </div>
         </div>
        </div>


        {/* .............................master stock list */}
      
        <div className="col-sm-6" >
       
          
         
          <div className="card">
          {/* card-default */}
            
            
            <div class="card-body" style={{width: '100%', height: 500}}>
             <AgGridReact className="ag-theme-alpine"     paginationPageSize={200}   animateRows="true" columnDefs={columnDefs} defaultColDef={defaultColDef} enableRangeSelection="true" rowData={rowData} rowSelection="multiple" suppressRowClickSelection="true" />
            </div>


          </div>




        </div>

        {/* .............................master stock list */}
        


    </div>
    </div>
    </section>
    </div>
    {/* ............... */}
    <div>

      <div  class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div    class="modal-dialog modal-xl" role="document">
          <div  class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Stock Sheet</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div  style={{overflowX:'auto',overflowY:'auto',maxHeight:'70vh'}}>
            {/* ............. */}
           
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
             
            </div>
          </div>
        </div>
      </div>
      
    </div>
    {/* ......................... */}
    <Footer/>
    </div>
    </>
  )
}

