import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { URL ,IMGURL} from '../service/url'
import mqtt from 'mqtt'
// import DarkMode from './darkmode'
import { useSelector } from 'react-redux'





export default function Header({brokerr,refresh}) {


  


  let navigate=useNavigate()

  const admindetail=JSON.parse(localStorage.getItem('admindata'))

  const [brokerdata,setBrokerData]=useState([])
  const [brokerid,setBrokerid]=useState(null)
  // const [currentholdingdata,setCurrentHoldingData]=useState([])
  const [gttlist,setGttList]=useState([])
  const [fgttlist,FsetGttList]=useState([])

  const [pic,setPic]=useState('/assets/user.png')
  var bro  = useSelector((state)=>state.broker) 






  // mqtt code  ...................................................


  const MQTT_HOST = 'wss://test.mosquitto.org:8081';

  const [MQTT_TOPIC,setMqtt_Topic]=useState([`testtopic/amitjha/2222`,`testtopic/amitjha/0000`])

  const [mqttClient, setMqttClient] = useState(null);
  const [mqttdata,setMqttData]=useState([])
  const [mqttdatanifty,setMqttDataNifty]=useState([])
  const [alertdata,setAlertData]=useState([])
  const [newsalertdata,setNewsAlertData]=useState([])
  
  var notification=alertdata.length
  var newsnotification=newsalertdata.length



  
  
useEffect(() => {

  const client = mqtt.connect(MQTT_HOST);

  client.on('connect', () => {

    setMqttClient(client);

    MQTT_TOPIC.forEach(topic => client.subscribe(topic));
    // client.subscribe(MQTT_TOPIC);

  });

  return () => {

    client.end(); 

  };

}, [MQTT_TOPIC]);




useEffect(() => {
  

  const handleMessage = (topic, message) => {

    // setMqttData(JSON.parse(message.toString()));

    const parsedMessage = JSON.parse(message.toString());

    // Distinguish between topics and set data to appropriate states
    if (topic === MQTT_TOPIC[0]) {
      setMqttData(parsedMessage);
    } else if (topic === MQTT_TOPIC[1]) {
      setMqttDataNifty(parsedMessage);
    }
    
 
  };

  if (mqttClient) {
    mqttClient.on('message', handleMessage);
  }

  return () => {
    if (mqttClient) {
      mqttClient.off('message', handleMessage); 
    }
  };

}, [mqttClient,MQTT_TOPIC]); 






function filterMarketData(data) {

  return data.filter(entry => {
    // Convert to numbers for proper comparison
    const currentPrice = parseFloat(entry.CURRENT_MARKET_PRICE);
    const perchange = parseFloat(entry['%CHNG']);
    const wkHigh = parseFloat(entry['52Wk High']);
    const wkLow = parseFloat(entry['52Wk Low']);

    // Price Alert: Check if currentPrice is outside the 52Wk High or Low range
    const priceAlert = currentPrice > wkHigh || currentPrice < wkLow;

    // Percentage Change Alert: Check if perchange falls into specified ranges
    const perchangeAlert =
      // (perchange >= 1 && perchange <= 1.5) ||
      (perchange >= 2 && perchange <= 2.5) ||
      (perchange >= 3 && perchange <= 3.5) ||
      (perchange >= 5 && perchange <= 5.5) ||
      // (perchange >= 6 && perchange <= 6.5) ||
      (perchange >= 7 && perchange <= 7.5) ||
      (perchange >= 10 && perchange <= 15.5) ||
      // (perchange <= -1 && perchange >= -1.5) ||
      (perchange <= -2 && perchange >= -2.5) ||
      (perchange <= -3 && perchange >= -3.5) ||
      (perchange <= -5 && perchange >= -5.5) ||
      // (perchange <= -6 && perchange >= -6.5) ||
      (perchange <= -7 && perchange >= -7.5) ||
      (perchange <= -10 && perchange >= -15.5);



    return priceAlert || perchangeAlert;

  });
}





useEffect(() => {

 
 let alertdata = filterMarketData(mqttdata)
 setAlertData(alertdata)
  
}, [mqttdata]); 







  // mqtt code  ...................................................




  // const setimage=()=>{

  // if(admindetail.profile_img_url1)
  // {
  //   setPic(admindetail.profile_img_url1)
  // }
  // else
  // {
  //   setPic('/assets/user.png')
  // }


  // }

  // useEffect(()=>{

  //   setimage()

  // },[])

  
var bro  = useSelector((state)=>state.broker) 

useEffect(()=>{

if(!bro.broker)
{
  setBrokerid(null)
  
}
else
{
  setBrokerid(bro.broker.bro_id)
 

}
  
},[bro.broker])






  // ...............api get broker 

  const getbroke=async()=>{
    
    // console.log(`${URL}brokerinfo&bpuser=10891&action=getbrokers`);

    var formdata=new FormData()
    formdata.append('admin_id',admindetail.user_id) 
    
    const result=await axios.post(`${URL}brokerinfo&bpuser=10891&action=getbrokers`,formdata)
  
     
    setBrokerData(result.data.data)
    

  }

  useEffect(()=>{

    getbroke()

  },[])




  //.................api get broker end


  // ..........................notification data


 
//   const getholdingdata=async()=>{

//      var formdata=new FormData()
    

//     // formdata.append('bro_id',brokerid)
    
//     formdata.append('admin_id',admindetail.user_id)
   
   
 
//     var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=GetstockalertList`,formdata)

   
    
//     setCurrentHoldingData(result.data.data)
    

//    }

// useEffect(()=>{
  
//   getholdingdata()

// },[brokerid])





const showNotification=()=>{

  return alertdata.map((val)=>{
 
    return (
      <tr>
        <th  style={{justifySelf:'center'}} scope="">{val.STOCKS}</th>
        <td style={{color:'blue'}} >{val.CURRENT_MARKET_PRICE}</td>
        <td style={{color:'green'}}>{val["52Wk High"]}</td>
        <td style={{color:'red'}}>{val["52Wk Low"]}</td>
      </tr>
    )

  })


}


 //....................page refresh function 

     


  const logout=()=>{
   
   localStorage.clear()

   navigate('/')

  }



  const Call=(broker)=>{
   
   
   navigate('/Dashboard')
   
   brokerr(broker.user_id,broker.user_name,broker.user_type)

   
  
  }


// .....................................

  const showbroker=()=>{

   return brokerdata.map((broker)=>{

     
     return (
      
      <div className="nav-item" >
        <li  onClick={()=>Call(broker)} className={broker.user_id===brokerid?'nav-link active':'nav-link'}  id='active'>
          <i className="far fa-circle nav-icon" />
           <p>{broker.user_name}</p>
        </li>
      </div>
      
   
     )

   })

  }




const gostock=()=>{
 
  navigate('/Addstock')
  refresh()

}

const gobroker=()=>{
 
  navigate('/AddBroker')
  refresh()
}

const gofund=()=>{
 
  navigate('/Funds')
  refresh()
}

const gomasterentry=()=>{
 
  navigate('/Master')
  refresh()

}

const goregistration=()=>{

  navigate('/Registration')
  refresh()

}

const excel=()=>{
  
  navigate('/excel')
  refresh()

} 


const mis=()=>{
  
  navigate('/mis')


} 

const news=()=>{
  navigate("/news")
}


const viewReport=()=>{
  navigate('/Report',{state:{id:brokerid}})
}





const setimage=()=>{
  
  if(admindetail.profile_img_url1 == " ")
  {
      setPic('/assets/user.png')
      
  }
  else
  {
      setPic(admindetail.profile_img_url1)
  }
   
}





const getcurrentdatenews=async()=>{



    const getFormattedDate = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(now.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
  
 
  const currentDate = getFormattedDate();

  var formdata=new FormData()
  formdata.append('admin_id',admindetail.user_id) 
  formdata.append('cur_date',currentDate) 
  const result= await axios.post(`${URL}stockInfo&bpuser=10891&action=Getnews`,formdata)

 
  
  if(result.data.data)
  {
   
    setNewsAlertData(result.data.data)
      
  }
  else
  {
      console.log('No data found..')
  }
  
   

}


useEffect(()=>{
  getcurrentdatenews()
  setimage()
},[])



const NewsCard = ({ date, content,newsid }) => {

  const [showMore, setShowMore] = useState(false);

  return (
      <div onClick={()=>navigate('/news', { state: { news_id: newsid } })} className='shadow-lg card' style={{ width: '100%', border: '1px solid black', marginBottom: '10px' }}>
          <div className='row'>
              <div className="col-sm-12" style={{ padding: '15px' }}>
                  <h6>{date}</h6>
                  <div style={{
                      fontSize: '15px',
                      maxHeight: showMore ? 'none' : '3em', 
                      overflow: 'hidden',
                      position: 'relative'
                  }}>
                      {content}
                  </div>
                  {/* <button 
                      onClick={() => setShowMore(!showMore)}
                      style={{
                          border: 'none',
                          background: 'none',
                          color: '#0069d9',
                          cursor: 'pointer',
                          padding: 0,
                          marginTop: '5px'
                      }}
                  >
                      {showMore ? 'Less' : 'More'}
                  </button> */}
              </div>
          </div>
      </div>
  );
};


const NewsList = () => {

  return (
      <div className='container' style={{ padding: '20px' }}>
          {newsalertdata.map((news, index) => (
              <NewsCard 
                  key={index}
                  date={news.stock_name}
                  content={news.news}
                  newsid={news.news_id}
                  
              />
          ))}
      </div>
  );
};









const Getgttalert=async()=>{


  try {

    const formdata = new FormData();
    formdata.append('admin_id', admindetail.user_id); 
    const result = await axios.post(`${URL}stockInfo&bpuser=10891&action=GetgttList`, formdata);
    setGttList(result.data.data)
   
  } catch (error) {

    console.error('Error fetching gtt stock:', error);
   
  }



}

useEffect(()=>{

  Getgttalert()

},[])




const filterMqttData = (gttlist, mqttdata) => {
  // Convert gttlist to a Map for easier lookup, including all properties
  const gttMap = new Map(gttlist.map(item => [
    item.stock_name,
    {
      ...item, // Spread all properties from gttlist item
      gtt_trigger_price: parseFloat(item.gtt_trigger_price),
      gtt_per: parseFloat(item.gtt_per)
    }
  ]));

  // Filter mqttdata and combine with gttlist data
  return mqttdata
    .map(dataItem => {
      const gttItem = gttMap.get(dataItem.STOCKS);

      if (gttItem) {
        const { gtt_trigger_price, gtt_per } = gttItem;
        const ltp = parseFloat(dataItem.LTP);
        const percentChange = parseFloat(
          typeof dataItem['%CHNG'] === 'string'
            ? dataItem['%CHNG'].replace(/,/g, '')
            : dataItem['%CHNG']
        );

        const roundedGttTriggerPrice = Math.round(gtt_trigger_price);
        const roundedLtp = Math.round(ltp);

        
        if (Math.abs(roundedGttTriggerPrice - roundedLtp) <= 1.5) {
          
          return {
            ...dataItem, 
            ...gttItem 
          };
        }
      }

    
      return null;
    })
    .filter(item => item !== null); 
};



let filteredData = filterMqttData(gttlist, mqttdata);
let filtergttalertlength=filteredData.length





const GttCard = ({ cmp, broker ,stock ,action}) => {

  const [showMore, setShowMore] = useState(false);

  return (
      <div className='shadow-lg card' style={{ width: '100%', border: '1px solid black', marginBottom: '10px' }}>
          <div className='row'>
              <div className="col-sm-12" style={{ padding: '15px' }}>
                  
                  <div style={{ display:'flex',justifyContent:'space-between' }}>
                     
                    <h3>{stock}</h3>
                    <div> {broker}</div>
                        
                  </div>
                  <div style={{ display:'flex',justifyContent:'space-between' }}>
                     
                     <div>{action}</div>
                     <div> {cmp}</div>
                         
                   </div>
                  {/* <button 
                      onClick={() => setShowMore(!showMore)}
                      style={{
                          border: 'none',
                          background: 'none',
                          color: '#0069d9',
                          cursor: 'pointer',
                          padding: 0,
                          marginTop: '5px'
                      }}
                  >
                      {showMore ? 'Less' : 'More'}
                  </button> */}
              </div>
          </div>
      </div>
  );
};


const GttList = () => {



  return (
      <div className='container' style={{ padding: '20px' }}>
          {filteredData.map((gtt, index) => (
              <GttCard 
                  key={index}
                  cmp={gtt.CURRENT_MARKET_PRICE}
                  stock={gtt.STOCKS}
                  broker={gtt.user_name}
                  action={gtt.gtt_action}
                  
              />
          ))}
      </div>
  );
};



const AalertCardWKHGLW = ({ cmp,stock,wkhg,wklw}) => {



  return (
      <div className='shadow-lg card' style={{ width: '100%', border: '1px solid black', marginBottom: '10px' }}>
          <div className='row'>
              <div className="col-sm-12" style={{ padding: '15px' }}>
                  
              <div style={{ display:'flex',justifyContent:'space-between' }}>
                     
                    <h3>{stock}</h3>
                     
                         
                   </div>
                  <div style={{ display:'flex',justifyContent:'space-evenly' }}>

                    <div>
                      <div  style={{fontWeight:'bold'}}>CMP</div>
                      <div  > {cmp}</div>
                    </div>

                    <div>
                      <div style={{fontWeight:'bold'}}>52 Week High</div>
                      <div style={{color:wkhg < 0 ? "red":"green"}} >{wkhg}</div>
                    </div>

                  

                    <div>
                      <div  style={{fontWeight:'bold'}}>52 Week Low</div>
                      <div style={{color:wklw < 0 ? "red":"green"}} > {wklw}</div>
                    </div>
                          
                  </div>
                  {/* <button 
                      onClick={() => setShowMore(!showMore)}
                      style={{
                          border: 'none',
                          background: 'none',
                          color: '#0069d9',
                          cursor: 'pointer',
                          padding: 0,
                          marginTop: '5px'
                      }}
                  >
                      {showMore ? 'Less' : 'More'}
                  </button> */}
              </div>
          </div>
      </div>
  );
};



const AalertCardchng = ({ cmp, chng ,stock}) => {



  return (
      <div className='shadow-lg card' style={{ width: '100%', border: '1px solid black', marginBottom: '10px' }}>
          <div className='row'>
              <div className="col-sm-12" style={{ padding: '15px' }}>
                  
                  <div style={{ display:'flex',justifyContent:'space-between' }}>
                     
                    <h3>{stock}</h3>
                    
                        
                  </div>
                  <div style={{ display:'flex',justifyContent:'space-evenly' }}>

                    <div>
                     <div  style={{fontWeight:'bold'}}>CMP</div>
                     <div > {cmp}</div>
                    </div>
                    <div>
                     <div style={{fontWeight:'bold'}}>%CHNG</div>
                     <div style={{color:chng < 0 ? "red":"green"}} >{chng}</div>
                    </div>
     
                  </div>
                  {/* <button 
                      onClick={() => setShowMore(!showMore)}
                      style={{
                          border: 'none',
                          background: 'none',
                          color: '#0069d9',
                          cursor: 'pointer',
                          padding: 0,
                          marginTop: '5px'
                      }}
                  >
                      {showMore ? 'Less' : 'More'}
                  </button> */}
              </div>
          </div>
      </div>
  );
};


const AleartList = () => {
  return (
    <div className='container' style={{ padding: '20px' }}>
      {alertdata.map((gtt, index) => {
        // Condition to decide which component to render
        const shouldRenderAalertCardWKHGLW = gtt.LTP > gtt['52Wk High'] || gtt.LTP < gtt['52Wk Low'];

        return shouldRenderAalertCardWKHGLW ? (
          <AalertCardWKHGLW
            key={index}
            cmp={gtt.CURRENT_MARKET_PRICE}
            stock={gtt.STOCKS}
            wkhg={gtt['52Wk High']}
            wklw={gtt['52Wk Low']}
          />
        ) : (
          <AalertCardchng
            key={index}
            cmp={gtt.CURRENT_MARKET_PRICE}
            stock={gtt.STOCKS}
            chng={gtt['%CHNG']}
          />
        );
      })}
    </div>
  );
};





 
  return (
   <>



   {/* header start .................................................................................................. */}


   <nav className="main-header navbar navbar-expand navbar-white navbar-light" >
  {/* Left navbar links */}
  <ul className="navbar-nav">
    <li className="nav-item">
      <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
    </li>



     
    
    {/* <li className="nav-item d-none d-sm-inline-block">
  <a href="#" className="nav-link">Home</a>
</li>
<li className="nav-item d-none d-sm-inline-block">
  <a href="#" className="nav-link">Contact</a>
</li>*/}

  </ul> 
  {/* Right navbar links */}


  <ul className="navbar-nav ml-auto">


  <div style={{display:'flex',width:'63vw',justifyContent:'space-evenly',alignItems:'center',fontSize:'15px'}}>
    
    <div style={{display:'flex'}}>
    {mqttdatanifty[0] ? <>
      <div>NIFTY 50 &nbsp;:&nbsp; {mqttdatanifty[0].LTP}</div><div style={{marginLeft:'8px',color: mqttdatanifty[0]['Net Change'] < 0 ? 'red' : 'green',fontSize:'13px'}}>{mqttdatanifty[0]['Net Change'] < 0 ? <><img src='../assets/sdown.png' /></>:<><img src='../assets/sup.png'/></>}&nbsp;&nbsp;{mqttdatanifty[0]['Net Change']}&nbsp;&nbsp;{mqttdatanifty[0]['%Chng'] < 0 ? `(${mqttdatanifty[0]['%Chng']}%)`:`(+${mqttdatanifty[0]['%Chng']}%)`}</div>
    </>:<></>}
   
    </div>

    <div style={{display:'flex'}}>
    {mqttdatanifty[1] ? <>
      <div>BANK NIFTY &nbsp;:&nbsp; {mqttdatanifty[1].LTP}</div><div style={{marginLeft:'8px',color: mqttdatanifty[1]['Net Change'] < 0 ? 'red' : 'green',fontSize:'13px'}}>{mqttdatanifty[1]['Net Change'] < 0 ? <><img src='../assets/sdown.png' /></>:<><img src='../assets/sup.png'/></>}&nbsp;&nbsp;{mqttdatanifty[1]['Net Change']}&nbsp;&nbsp;{mqttdatanifty[1]['%Chng'] < 0 ?`(${mqttdatanifty[1]['%Chng']}%)`:`(+${mqttdatanifty[1]['%Chng']}%)`}</div>

    </>:<></>}
   
    </div>

  </div>

  

  
    {/* Navbar Search */}
    
    {/* Messages Dropdown Menu */}

    {/* <li className="nav-item-lp" style={{paddingRight:'50px',paddingTop:'5px'}} >
     <DarkMode/>
    </li> */}

    {/* <li className="nav-item dropdown" style={{marginRight:'50px'}}>
          
      <div  className="nav-link" data-toggle="dropdown">
      <span style={{fontSize:'15px',padding:'5px',fontWeight:'bold'}} className="badge badge-warning navbar-badge">Notification</span>
      </div>

      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">


        <div className='dropdown-item'>
          <h3>hello amit </h3>
        </div>

      </div>

    </li>  */}

    <li className="nav-item dropdown" >

      <button type="button"   style={{marginRight:'1.9vw'}} class="btn btn-primary position-relative" data-toggle="dropdown">
            
      <img src='/assets/bell.png' style={{width:'20px'}}/>

        {!filteredData || filteredData.length === 0 ? (
            <>
            
            </>
          ) : (
            <>

              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {filtergttalertlength}+
              </span>

            </>
        )}

            
      </button>


      <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right menu_height"   style={{ width: '100vw'}} >
        <h3 style={{marginLeft:'35%',marginTop:'1vh'}}>GTT TRIGGER</h3>
        <GttList />
        
      </div>



    </li>


    <li className="nav-item dropdown" >

      <button type="button"  style={{marginRight:'1.9vw'}} class="btn btn-primary position-relative" data-toggle="dropdown">
            
        <i className="fas fa-newspaper" />
      
        {!newsalertdata || newsalertdata.length === 0 ? (
            <>
            
            </>
          ) : (
            <>

              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {newsnotification}+
              </span>

            </>
        )}
    
            
      </button>


      <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right menu_height" >
      <h3 style={{marginLeft:'38%',marginTop:'1vh'}}>NEWS</h3>
       <NewsList/>
        
      </div>
  





    </li>




    <li>




      
    
      <button type="button"  style={{marginRight:'1.9vw'}} class="btn btn-primary position-relative" data-toggle="dropdown">
        
        <i className="fas fa-bell" />
      
        {!alertdata || alertdata.length === 0 ? (
            <>
            
            </>
          ) : (
            <>
              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {notification}+
              </span>
            </>
        )}

      
      </button>


      <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right menu_height" >
        {/* <table class="table" >
          <thead style={{padding:'10px'}} >
            <tr >
              <th scope="col">STOCK</th>
              <th scope="col">CMP</th>
              <th scope="col">52WH</th>
              <th scope="col">52WL</th>
            </tr>
          </thead>
          <tbody>
          {showNotification()}
            
            
          </tbody>
        </table> */}
        <AleartList/>

      </div>



    </li>


     
    <li className="nav-item dropdown">
      <button  type="button" class="btn btn-primary position-relative" data-toggle="dropdown">
        
        <span  >Menu</span>
      </button>
      
  <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
    {/* <span className="dropdown-item dropdown-header">15 Notifications</span> */}

    <div className="dropdown-divider"></div>
    <div  onClick={()=>goregistration()} className="dropdown-item">
      <i className="fas fa-plus-circle mr-2"></i> Add User
      {/* <span className="float-right text-muted text-sm">Closing Blance :- 75.5k</span> */}
    </div>


    <div className="dropdown-divider"></div>
    <div  onClick={()=>gobroker()}   className="dropdown-item">
      <i className="fas fa-plus-circle mr-2"></i> Add Account
      
      {/* <span className="float-right text-muted text-sm">3 mins</span> */}
    </div>

    {/* <div className="dropdown-divider"></div>
    <div onClick={()=>navigate('/StockAlert')}  className="dropdown-item">
      <i className="fas fa-plus-circle mr-2"></i> Stock Alert
      
      <span className="float-right text-muted text-sm">3 mins</span>
    </div> */}

    {/* <div className="dropdown-divider"></div>
    <div  onClick={()=>gostock()}   className="dropdown-item">
      <i className="fas fa-plus-circle mr-2"></i> Add Dividend 
      <span className="float-right text-muted text-sm">3 mins</span>
    </div>
     */}
     
    <div className="dropdown-divider"></div>
    <div  onClick={()=>gomasterentry()}   className="dropdown-item">
      <i className="fas fa-plus-circle mr-2"></i> Master Stock
      {/* <span className="float-right text-muted text-sm">3 mins</span> */}
    </div>


    <div className="dropdown-divider"></div>
    <div  onClick={()=>gofund()} className="dropdown-item">
      <i className="fas fa-plus-circle mr-2"></i> Add Funds
      {/* <span className="float-right text-muted text-sm">Closing Blance :- 75.5k</span> */}
    </div>

    <div className="dropdown-divider"></div>
    <div  onClick={()=>news()} className="dropdown-item">
      <i className="fas fa-plus-circle mr-2"></i> Add News
      {/* <span className="float-right text-muted text-sm">Closing Blance :- 75.5k</span> */}
    </div>

    

    <div className="dropdown-divider"></div>
    <div  onClick={()=>navigate("/ltpfeed")} className="dropdown-item">
      <i className="fas fa-plus-circle mr-2"></i> Add LTP
      {/* <span className="float-right text-muted text-sm">Closing Blance :- 75.5k</span> */}
    </div>
   

    <div className="dropdown-divider"></div>
    <div  onClick={()=>excel()} className="dropdown-item">
      <i className="fas fa-plus-circle mr-2"></i> Import Excel
      {/* <span className="float-right text-muted text-sm">Closing Blance :- 75.5k</span> */}
    </div>

   

    


    <div className="dropdown-divider"></div>
    <div  onClick={()=>mis()} className="dropdown-item">
      <i className="fas fa-plus-circle mr-2"></i> Mis Report
      {/* <span className="float-right text-muted text-sm">Closing Blance :- 75.5k</span> */}
    </div>
    
    
    {/* <div className="dropdown-divider"></div>
    <div  onClick={()=>viewReport()} className="dropdown-item">
      <i className="fas fa-plus-circle mr-2"></i> View Reprot
     
    </div> */}
   
   

    <div className="dropdown-divider"></div>
    <div className="dropdown-item" onClick={()=>navigate('/profile')}>
      <i className="fas fa-user mr-2"></i>
       
        
       <span style={{paddingLeft:'2px'}}></span> Profile
     
      
      <span className="float-right text-muted text-sm">{admindetail.user_name}</span>
    
    </div>
    <div className="dropdown-divider"></div>
    <div onClick={()=>logout()} className="dropdown-item dropdown-footer">Logout</div>
  </div>
</li>
    <li className="nav-item">
      <a className="nav-link" data-widget="fullscreen" href="#" role="button">
        <i className="fas fa-expand-arrows-alt" />
      </a>
    </li>
    
  </ul>

  
</nav>



{/* header end .................................................................................................. */}


{/* sidebar start .................................................................................................. */}


<aside className="main-sidebar sidebar-dark-primary elevation-4">
  {/* Brand Logo */}
  <div onClick={()=>navigate('/Dashboard')} className="brand-link">
    <img src="dist/img/AdminLTELogo.png" alt="Stock Trade" className="brand-image img-circle elevation-3" />
    <span className="brand-text font-weight-light">Stock Trade</span>
  </div>
  {/* Sidebar */}
  <div className="sidebar">
    {/* Sidebar user panel (optional) */}
    
    <div  onClick={()=>refresh()}  className="user-panel mt-3 pb-3 mb-3 d-flex">
      <div className="image">
      {/* src={admindetail.profile_img_url}
       */}
        <img   style={{
                borderRadius: "50%",
                width: 30,
                height: 30,
                background: "white",
                display: "block"
              }}      className="img-circle elevation-2" src={IMGURL+pic}  alt="User Image" />
        


      </div>
      <div className="info">
        <a  className="d-block">{admindetail.user_name} </a>
      </div>

    </div>
    
    {/* SidebarSearch Form */}
    {/* <div className="form-inline">
  <div className="input-group" data-widget="sidebar-search">
    <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
    <div className="input-group-append">
      <button className="btn btn-sidebar">
        <i className="fas fa-search fa-fw"></i>
      </button>
    </div>
  </div>
</div> */}
    {/* Sidebar Menu */}
    <nav className="mt-2">
      <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
        <li  className="nav-item menu-open">
         
          {/* <a href="#"    className="nav-link active" >
            <i className="nav-icon fas fa-tachometer-alt" />
            <p>
              Dashboard
              <i className="right fas fa-angle-left" />
            </p>
          </a> */}

         
          <a href="#"  style={{fontSize:'18px'}} className="nav-link active"  data-toggle="collapse" data-target="#collapseExample" >
           
            <i className="nav-icon fas fa-tachometer-alt" />

            <p style={{paddingLeft:'18px'}}>
              Dashboard 
              <i style={{paddingLeft:'50px'}} className="up fas fa-angle-down" />
            </p>
            
          </a>

          <div class="collapse" id="collapseExample" >
            
            <div>
             {showbroker()}
            </div>
          
          </div>
      

          {/* <ul className="nav nav-treeview" >
           
           {showbroker()}
        
           
          </ul> */}
        </li>
        {/* <li className="nav-item">
      <a href="pages/widgets.html" className="nav-link">
        <i className="nav-icon fas fa-th"></i>
        <p>
          Widgets
          <span className="right badge badge-danger">New</span>
        </p>
      </a>
    </li>
    <li className="nav-item">
      <a href="#" className="nav-link">
        <i className="nav-icon fas fa-copy"></i>
        <p>
          Layout Options
          <i className="fas fa-angle-left right"></i>
          <span className="badge badge-info right">6</span>
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <a href="pages/layout/top-nav.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Top Navigation</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/layout/top-nav-sidebar.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Top Navigation + Sidebar</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/layout/boxed.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Boxed</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/layout/fixed-sidebar.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Fixed Sidebar</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/layout/fixed-sidebar-custom.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Fixed Sidebar <small>+ Custom Area</small></p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/layout/fixed-topnav.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Fixed Navbar</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/layout/fixed-footer.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Fixed Footer</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/layout/collapsed-sidebar.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Collapsed Sidebar</p>
          </a>
        </li>
      </ul>
    </li>
    <li className="nav-item">
      <a href="#" className="nav-link">
        <i className="nav-icon fas fa-chart-pie"></i>
        <p>
          Charts
          <i className="right fas fa-angle-left"></i>
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <a href="pages/charts/chartjs.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>ChartJS</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/charts/flot.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Flot</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/charts/inline.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Inline</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/charts/uplot.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>uPlot</p>
          </a>
        </li>
      </ul>
    </li>
    <li className="nav-item">
      <a href="#" className="nav-link">
        <i className="nav-icon fas fa-tree"></i>
        <p>
          UI Elements
          <i className="fas fa-angle-left right"></i>
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <a href="pages/UI/general.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>General</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/UI/icons.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Icons</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/UI/buttons.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Buttons</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/UI/sliders.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Sliders</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/UI/modals.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Modals & Alerts</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/UI/navbar.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Navbar & Tabs</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/UI/timeline.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Timeline</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/UI/ribbons.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Ribbons</p>
          </a>
        </li>
      </ul>
    </li>
    <li className="nav-item">
      <a href="#" className="nav-link">
        <i className="nav-icon fas fa-edit"></i>
        <p>
          Forms
          <i className="fas fa-angle-left right"></i>
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <a href="pages/forms/general.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>General Elements</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/forms/advanced.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Advanced Elements</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/forms/editors.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Editors</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/forms/validation.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Validation</p>
          </a>
        </li>
      </ul>
    </li>
    <li className="nav-item">
      <a href="#" className="nav-link">
        <i className="nav-icon fas fa-table"></i>
        <p>
          Tables
          <i className="fas fa-angle-left right"></i>
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <a href="pages/tables/simple.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Simple Tables</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/tables/data.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>DataTables</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/tables/jsgrid.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>jsGrid</p>
          </a>
        </li>
      </ul>
    </li>
    <li className="nav-header">EXAMPLES</li>
    <li className="nav-item">
      <a href="pages/calendar.html" className="nav-link">
        <i className="nav-icon fas fa-calendar-alt"></i>
        <p>
          Calendar
          <span className="badge badge-info right">2</span>
        </p>
      </a>
    </li>
    <li className="nav-item">
      <a href="pages/gallery.html" className="nav-link">
        <i className="nav-icon far fa-image"></i>
        <p>
          Gallery
        </p>
      </a>
    </li>
    <li className="nav-item">
      <a href="pages/kanban.html" className="nav-link">
        <i className="nav-icon fas fa-columns"></i>
        <p>
          Kanban Board
        </p>
      </a>
    </li>
    <li className="nav-item">
      <a href="#" className="nav-link">
        <i className="nav-icon far fa-envelope"></i>
        <p>
          Mailbox
          <i className="fas fa-angle-left right"></i>
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <a href="pages/mailbox/mailbox.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Inbox</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/mailbox/compose.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Compose</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/mailbox/read-mail.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Read</p>
          </a>
        </li>
      </ul>
    </li>
    <li className="nav-item">
      <a href="#" className="nav-link">
        <i className="nav-icon fas fa-book"></i>
        <p>
          Pages
          <i className="fas fa-angle-left right"></i>
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <a href="pages/examples/invoice.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Invoice</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/examples/profile.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Profile</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/examples/e-commerce.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>E-commerce</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/examples/projects.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Projects</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/examples/project-add.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Project Add</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/examples/project-edit.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Project Edit</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/examples/project-detail.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Project Detail</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/examples/contacts.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Contacts</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/examples/faq.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>FAQ</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/examples/contact-us.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Contact us</p>
          </a>
        </li>
      </ul>
    </li>
    <li className="nav-item">
      <a href="#" className="nav-link">
        <i className="nav-icon far fa-plus-square"></i>
        <p>
          Extras
          <i className="fas fa-angle-left right"></i>
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <a href="#" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>
              Login & Register v1
              <i className="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul className="nav nav-treeview">
            <li className="nav-item">
              <a href="pages/examples/login.html" className="nav-link">
                <i className="far fa-circle nav-icon"></i>
                <p>Login v1</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="pages/examples/register.html" className="nav-link">
                <i className="far fa-circle nav-icon"></i>
                <p>Register v1</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="pages/examples/forgot-password.html" className="nav-link">
                <i className="far fa-circle nav-icon"></i>
                <p>Forgot Password v1</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="pages/examples/recover-password.html" className="nav-link">
                <i className="far fa-circle nav-icon"></i>
                <p>Recover Password v1</p>
              </a>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>
              Login & Register v2
              <i className="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul className="nav nav-treeview">
            <li className="nav-item">
              <a href="pages/examples/login-v2.html" className="nav-link">
                <i className="far fa-circle nav-icon"></i>
                <p>Login v2</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="pages/examples/register-v2.html" className="nav-link">
                <i className="far fa-circle nav-icon"></i>
                <p>Register v2</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="pages/examples/forgot-password-v2.html" className="nav-link">
                <i className="far fa-circle nav-icon"></i>
                <p>Forgot Password v2</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="pages/examples/recover-password-v2.html" className="nav-link">
                <i className="far fa-circle nav-icon"></i>
                <p>Recover Password v2</p>
              </a>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <a href="pages/examples/lockscreen.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Lockscreen</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/examples/legacy-user-menu.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Legacy User Menu</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/examples/language-menu.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Language Menu</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/examples/404.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Error 404</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/examples/500.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Error 500</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/examples/pace.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Pace</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/examples/blank.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Blank Page</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="starter.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Starter Page</p>
          </a>
        </li>
      </ul>
    </li>
    <li className="nav-item">
      <a href="#" className="nav-link">
        <i className="nav-icon fas fa-search"></i>
        <p>
          Search
          <i className="fas fa-angle-left right"></i>
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <a href="pages/search/simple.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Simple Search</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="pages/search/enhanced.html" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Enhanced</p>
          </a>
        </li>
      </ul>
    </li>
    <li className="nav-header">MISCELLANEOUS</li>
    <li className="nav-item">
      <a href="iframe.html" className="nav-link">
        <i className="nav-icon fas fa-ellipsis-h"></i>
        <p>Tabbed IFrame Plugin</p>
      </a>
    </li>
    <li className="nav-item">
      <a href="https://adminlte.io/docs/3.1/" className="nav-link">
        <i className="nav-icon fas fa-file"></i>
        <p>Documentation</p>
      </a>
    </li>
    <li className="nav-header">MULTI LEVEL EXAMPLE</li>
    <li className="nav-item">
      <a href="#" className="nav-link">
        <i className="fas fa-circle nav-icon"></i>
        <p>Level 1</p>
      </a>
    </li>
    <li className="nav-item">
      <a href="#" className="nav-link">
        <i className="nav-icon fas fa-circle"></i>
        <p>
          Level 1
          <i className="right fas fa-angle-left"></i>
        </p>
      </a>
      <ul className="nav nav-treeview">
        <li className="nav-item">
          <a href="#" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Level 2</p>
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>
              Level 2
              <i className="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul className="nav nav-treeview">
            <li className="nav-item">
              <a href="#" className="nav-link">
                <i className="far fa-dot-circle nav-icon"></i>
                <p>Level 3</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="#" className="nav-link">
                <i className="far fa-dot-circle nav-icon"></i>
                <p>Level 3</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="#" className="nav-link">
                <i className="far fa-dot-circle nav-icon"></i>
                <p>Level 3</p>
              </a>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link">
            <i className="far fa-circle nav-icon"></i>
            <p>Level 2</p>
          </a>
        </li>
      </ul>
    </li>
    <li className="nav-item">
      <a href="#" className="nav-link">
        <i className="fas fa-circle nav-icon"></i>
        <p>Level 1</p>
      </a>
    </li>
    <li className="nav-header">LABELS</li>
    <li className="nav-item">
      <a href="#" className="nav-link">
        <i className="nav-icon far fa-circle text-danger"></i>
        <p className="text">Important</p>
      </a>
    </li>
    <li className="nav-item">
      <a href="#" className="nav-link">
        <i className="nav-icon far fa-circle text-warning"></i>
        <p>Warning</p>
      </a>
    </li>*/}
    <li className="nav-item" >
    
    </li> 
      </ul>
    </nav>
    {/* /.sidebar-menu */}
  </div>
  {/* /.sidebar */}
</aside>
{/* sidebar start .................................................................................................. */}

   </>
  )
}
