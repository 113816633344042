import React from 'react'

import CanvasJSReact from '@canvasjs/react-charts'; 
// import { Chart } from "react-google-charts";
import { useState } from "react";

import { useEffect } from "react";
import axios from "axios";
import { URL } from "../service/url";


export default function Holding(props) {


  const admindetail=JSON.parse(localStorage.getItem('admindata'))
  const [rowdata,setRowData]=useState([])

  

    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;

    var data= [
    
      { y: 19, label: "Software & IT Serivces" },
    { y: 19, label: "Machinery Equipment & Components" },
    { y: 9, label: "Automobiles & Auto Parts" },
    { y: 1, label: "Oil & Gas" },
    { y: 5, label: "Construction & Engineering" },
    { y: 4, label: "Paper & Forest Products" },
    { y: 16, label: "Banking Services" },
    { y: 18, label: "Aerospace & Defense" }
  
  
  ]


        const options = {
            animationEnabled: true,
            exportEnabled: false,
            theme: "light1", // "light1", "dark1", "dark2"
            title:{
                text: "HOLDING"
            },
           
            data: [{
                type: "pie",
                indexLabel: "{label}: {y}%",		
                startAngle: -90,
                dataPoints: [
                    // { y: 19, label: "Software & IT Serivces" },
                    // { y: 19, label: "Machinery Equipment & Components" },
                    // { y: 9, label: "Automobiles & Auto Parts" },
                    // { y: 1, label: "Oil & Gas" },
                    // { y: 5, label: "Construction & Engineering" },
                    // { y: 4, label: "Paper & Forest Products" },
                    // { y: 16, label: "Banking Services" },
                    // { y: 18, label: "Aerospace & Defense" },

                    ...rowdata
                  

                  
                    
                    
                ]
            }]
        }
         



// .....................................

useEffect(()=>{

  setBrokerid(props.body[0].brokerid)
  setBrokerName(props.body[0].brokername)
  setBrokerType(props.body[0].brokertype)


},[props])


const [brokerid,setBrokerid]=useState()
const [brokername,setBrokerName]=useState()
const [brokertype,setBrokerType]=useState()

// console.log('holdingid',brokerid)
// console.log('holdingname',brokername)
// console.log('holdingtype',brokertype)

// .....................................



// .............................................CURRENT HOLDING... 

const getholdingdata=async()=>{

  var formdata=new FormData()
  

  formdata.append('bro_id',brokerid)
  formdata.append('admin_id',admindetail.user_id)
  
 
 

  var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=getPieChartDetails`,formdata)
  // console.log('chart_data..',result.data.data)
  setRowData(result.data.data)

 
  
  

 }

useEffect(()=>{

getholdingdata()

},[brokerid])



// .........................................END CURRENT HOLDING...
  

return (
    <CanvasJSChart chartType="PieChart"  data={data}  options={options}  width={"100%"} height={"450px"}/>
  );
}
