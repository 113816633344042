import React from 'react';

const DynamicTable = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No data available.</p>;
  }

  const columns = Object.keys(data[0]);

  console.log("value column =================", columns)

  return (
    <table className="table">
      <thead>
        <tr>


          <th>SNO</th>
          <th>STOCKS</th>
          <th>MARKET_CAP</th>
          <th>CURRENT_MARKET_PRICE</th>
          <th>CURRENT_HOLDING</th>
          <th>INVESTED_HOLDING</th>
          <th>AVG_VALUE</th>
          <th>UNREALISED</th>
          <th>REALISED</th>
        </tr>
      </thead>
      <tbody>


        {data.map((row, rowIndex) => {



          if (row.CURRENT_HOLDING !== '0.00') {

            return (
              <tr key={rowIndex}>

                <td>{rowIndex + 1}</td>
                <td>{row.STOCKS}</td>
                <td>{row.MARKET_CAP}</td>
                <td>{row.CURRENT_MARKET_PRICE}</td>
                <td>{row.CURRENT_HOLDING}</td>
                <td>{row.INVESTED_HOLDING}</td>
                <td>{row.AVG_VALUE}</td>
                <td>{row.UNREALISED_PER}</td>
                <td>{row.REALISED_PER}</td>




              </tr>
            )
          }

        })}
      </tbody>
    </table>
  );
};

export default DynamicTable;