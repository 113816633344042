import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
//import { createRoot } from 'react-dom/client';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
// import 'ag-grid-community/styles/ag-theme-quartz.css';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import DatePicker from "react-datepicker";



// mqtt...code .................................

import mqtt from 'mqtt'
// import live from './currentholding.json'

// mqtt...code .................................



import axios from 'axios';
import { URL } from '../service/url';

import { useSelector } from 'react-redux';
import { Column } from 'ag-grid-enterprise';


export default function CurrentHolding(props) {



  const gridRef = useRef();
  const navigate=useNavigate()





  const admindetail=JSON.parse(localStorage.getItem('admindata'))
  const [currentholdingdata,setCurrentHoldingData]=useState([])
  const [currentholdingdatabackup,setCurrentHoldingDataBackUp]=useState([])
  const [lastupdatetime,setLastUpdateTime]=useState('')
  const [watchlistbutton,setWatchListButton]=useState(false)
  const Swal = require('sweetalert2')
  const [showchart,setchartshow]=useState(false)






  // const {lastUpdateTime}=currentholdingdatabackup[1]


  useEffect(() => {
    if (currentholdingdatabackup.length >= 2) {
      const { "Date/Time": lastUpdateTime } = currentholdingdatabackup[0];
      if (lastUpdateTime) {
        const dateTime = lastUpdateTime.split('T'); // Split into date and time
        const date = dateTime[0]; // Get the date part
        const time = dateTime[1].split('.')[0]; // Get the time part, remove milliseconds
  
        // Combine date and time as a string
        const formattedDateTime = `${date} ${time}`;
        setLastUpdateTime(formattedDateTime);
      }
    }
  }, [currentholdingdatabackup]);

// 


 
 
 
  const [button,setButton]=useState('NORMAL MODE')
  const [showModal, setShowModal] = useState(false);
  const [showModalPass, setShowModalPass] = useState(false);
  const [showloading, setShowLoading] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [newslistdata,setNewsListData]=useState([])

  
useEffect(()=>{

    setBrokerid(props.body[0].brokerid)
    setBrokerName(props.body[0].brokername)
    setBrokerType(props.body[0].brokertype)


  },[props])

  
  const [brokerid,setBrokerid]=useState()
 
  const [brokername,setBrokerName]=useState()
  const [brokertype,setBrokerType]=useState()



  // mqtt...code .................................
 
  

  const MQTT_HOST = 'wss://test.mosquitto.org:8081';


  const [MQTT_TOPIC,setMqtt_Topic]=useState(`testtopic/amitjha/2222`)
 
  const [mqttClient, setMqttClient] = useState(null);
  const [mqttdata,setMqttData]=useState([])







  useEffect(()=>{

     const zeronodata=mqttdata.filter((val)=>val.QTY !==0)
   
     setCurrentHoldingData(zeronodata)
     setCurrentHoldingDataBackUp(mqttdata)


  },[mqttdata])



  useEffect(()=>{
  
  
    if(brokerid===undefined || brokerid===null)
      {
        setMqtt_Topic(`testtopic/amitjha/2222`)
      }
    else
    {
       setMqtt_Topic(`testtopic/amitjha/${brokerid}`)
    }

  },[brokerid])


  

useEffect(() => {
  const client = mqtt.connect(MQTT_HOST);

  client.on('connect', () => {

    setMqttClient(client);

    client.subscribe(MQTT_TOPIC);

  });

  return () => {

    client.end(); 

  };

}, [MQTT_TOPIC]);






useEffect(() => {
  

  const handleMessage = (topic, message) => {

    setMqttData(JSON.parse(message.toString()));
    
 
  };

  if (mqttClient) {
    mqttClient.on('message', handleMessage);
  }

  return () => {
    if (mqttClient) {
      mqttClient.off('message', handleMessage); 
    }
  };

}, [mqttClient]); 









  // Each Column Definition results in one Column.
  
  const [columnDefs, setColumnDefs] = useState([
    // { field: 'SNO',
    //    resizable: true , 
  
    // },
    { field: 'STOCKS', 
      headerName: 'STOCKS',
      resizable: true ,

      
      
    },
      { field: 'INDUSTRY', 
        headerName: 'INDUSTRY',
     
     
    },
      { field: 'MARKET_CAP', 
        headerName: 'MARKET_CAP',
      resizable: true , 
       
       
    },
      { field: 'QTY', 
        headerName: 'QTY',
      resizable: true ,
     
    },
      { field: 'CURRENT_MARKET_PRICE', 
        headerName: 'CURRENT_MARKET_PRICE',
        resizable: true , 
    
    },
    
    { field: 'INVESTED_HOLDING',
      headerName: 'INVESTED_HOLDING',
    resizable: true,
    suppressSizeToFit: true 
    },
    { field: 'CURRENT_HOLDING',
      headerName: 'CURRENT_HOLDING', 
      resizable: true ,

    },
      { field: 'AVG_VALUE',
        headerName: 'AVG_VALUE',
      resizable: true , 
     
    },
      { field: 'UNREALISED', 
        cellClassRules: {
            'text-danger': params => {
              const value = parseFloat(params.value.replace(/,/g, '')); 
              return value < 0;
            },
            'text-success': params => {
              const value = parseFloat(params.value.replace(/,/g, '')); 
              return value => 0;
            },
            
          },
        headerName: 'UNREALISED',
      resizable: true ,  
      
    },
      { field: 'REALISED',
        cellClassRules: {
        'text-danger': params => {
          const value = parseFloat(params.value.replace(/,/g, '')); 
          return value < 0;
        },
        'text-success': params => {
          const value = parseFloat(params.value.replace(/,/g, '')); 
          return value => 0;
        },
        
      },
        headerName: 'REALISED',
       resizable: true,
        
    },
    { 
      field: 'PER_GAIN',
      cellClassRules: {
            'text-danger': params => {
              const value = parseFloat(params.value.toString().replace(/,/g, '')); 
              return value < 0;
            },
            'text-success': params => {
              const value = parseFloat(params.value.toString().replace(/,/g, '')); 
              return value => 0;
            },
            
          }, 
      headerName: 'PER GAIN',
      resizable: true,
         
    },
    { 
      field: 'BROKERAGE',
      width: 80, 
      suppressSizeToFit: true,
      resizable: true,
      suppressSizeToFit: true 
    }
  
  ]);


  

  // DefaultColDef sets props common to all Columns


  const defaultColDef = useMemo(() => ({

    resizable: true,
    sortable: true,
    filter:true,
    floatingFilter:true,
    cellRenderer:false,
    flex:1,
    maxWidth:300,
    suppressSizeToFit: true,
  
    
  
}), []);







const popupParent = useMemo(() => {
  return document.body;
}, []);



  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
   }, []);



  // .............................................CURRENT HOLDING... 



//   const getholdingdata=async()=>{

//     var formdata=new FormData()
    

//     formdata.append('bro_id',brokerid)
    
//     formdata.append('admin_id',admindetail.user_id)
   
   
 
//     var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=retrieve`,formdata)

//     const zeronodata= result.data.data.filter((val)=>val.QTY !=='0')
    
//     setCurrentHoldingData(zeronodata)
//     setCurrentHoldingDataBackUp(result.data.data)
    

//    }

// useEffect(()=>{
  
//   getholdingdata()

// },[brokerid])



// .........................................END CURRENT HOLDING...


const autoGroupColumnDef = useMemo(() => {
  return {
    minWidth: 250,
  };
}, []);



  
  
const pivotgrid =()=>{

  // gridRef.current.api.setGridOption('pivotMode', false);
 setButton('PIVOT MODE')



  }
 
const normalgrid=()=>{
 
  setButton('NORMAL MODE')
  // console.log('Normal Mode',gridRef.current.api)


}

  
const grouping=()=>{

    setButton('GROUPING MODE')
  
}

const showall=()=>{

  const zeroono= currentholdingdatabackup.filter((val)=>val.QTY !==0)
  setCurrentHoldingData(zeroono)

}

const showonlyzero=()=>{


 const zeroqtydata= currentholdingdatabackup.filter((val)=>val.QTY===0)
 setCurrentHoldingData(zeroqtydata)


}







useEffect(()=>{

if(button=='NORMAL MODE')
{
  setColumnDefs([
    // { field: 'SNO', 
    //   resizable: true ,   
    //   suppressSizeToFit: true ,
    //   minWidth:80,
    //   cellStyle: { textAlign: 'center' }

     
    // },
    { field: 'STOCKS', 
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:200,
      cellStyle: { textAlign: 'left' },
     
     
    },
      { field: 'INDUSTRY', 
      resizable: true ,
      suppressSizeToFit: true , 
      filter:true,
      minWidth:200,
      cellStyle: { textAlign: 'center' }
    },
      { field: 'MARKET_CAP', 
      resizable: true ,
      suppressSizeToFit: true , 
      minWidth:150,
      cellStyle: { textAlign: 'center' }

    },
      { field: 'QTY', 
      resizable: true ,
      suppressSizeToFit: true ,
      minWidth:100,
    },
      { field: 'CURRENT_MARKET_PRICE', 
      resizable: true ,
      suppressSizeToFit: true , 
      filter:true ,
      minWidth:200,
    },
    { field: 'INVESTED_HOLDING',
      resizable: true , 
      suppressSizeToFit: true ,
      minWidth:200,
    },
      { field: 'CURRENT_HOLDING', 
      resizable: true , 
      suppressSizeToFit: true ,
      minWidth:180, 
    },
      { field: 'AVG_VALUE', 
      resizable: true , 
      suppressSizeToFit: true ,
      minWidth:120, 
    },
      { field: 'UNREALISED',
        cellClassRules: {
            'text-danger': params => {
              const value = parseFloat(params.value.toString().replace(/,/g, '')); 
              return value < 0;
            },
            'text-success': params => {
              const value = parseFloat(params.value.toString().replace(/,/g, '')); 
              return value => 0;
            },
            
          }, 
      resizable: true ,  
      suppressSizeToFit: true ,
      minWidth:150,
    },
      { field: 'REALISED',
        cellClassRules: {
        'text-danger': params => {
          const value = parseFloat(params.value.replace(/,/g, '')); 
          return value < 0;
            },
            'text-success': params => {
              const value = parseFloat(params.value.replace(/,/g, '')); 
              return value => 0;
            },
        
          },
          resizable: true,
          suppressSizeToFit: true ,
          minWidth:150, 
    },
    { field: 'PER_GAIN',
      cellClassRules: {
            'text-danger': params => {
              const value = parseFloat(params.value.toString().replace(/,/g, '')); 
              return value < 0;
            },
            'text-success': params => {
              const value = parseFloat(params.value.toString().replace(/,/g, '')); 
              return value => 0;
            },
            
          }, 
       resizable: true,
       suppressSizeToFit: true ,
       minWidth:150, 
    },
    { 
      field: 'BROKERAGE',
      resizable: true , 
      suppressSizeToFit: true ,
      minWidth:130, 
    }
  
  
  ])
}





else if(button=='PIVOT MODE')
{
  setColumnDefs(
        [
        //   { field: 'SNO', 
        //   resizable: true ,   
        //   suppressSizeToFit: true ,
        //   minWidth:80,
        //   cellStyle: { textAlign: 'center' }
        
        // },
          { field: 'STOCKS', 
          resizable: true ,  
          suppressSizeToFit: true ,
          rowGroup: false,
          enableRowGroup: true ,
          enablePivot: true ,
          minWidth:200,
          cellStyle: { textAlign: 'center' }
          // pivot: true
         
      
        },
          { field: 'INDUSTRY', 
          resizable: true , 
          filter:true , 
          rowGroup: false, 
          enableRowGroup: true ,
          enablePivot: true,
          minWidth:200,
          cellStyle: { textAlign: 'center' }
          // pivot: true
        },
          { field: 'MARKET_CAP', 
          resizable: true ,  
          width: 160, 
          suppressSizeToFit: true , 
          rowGroup: false, 
          enableRowGroup: true ,
          enablePivot: true, 
          minWidth:150,
          cellStyle: { textAlign: 'center' }
          // pivot: true
        },
          { field: 'QTY', 
          resizable: true ,
          suppressSizeToFit: true , 
          rowGroup: false, 
          enableRowGroup: true ,
          enablePivot: true,
          enableValue: true,
          defaultAggFunc: 'sum',
          allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'],
          minWidth:100,
        },
          { field: 'CURRENT_MARKET_PRICE', 
          resizable: true , 
          filter:true , 
          rowGroup: false, 
          enableRowGroup: true ,
          enablePivot: true,
          defaultAggFunc: 'sum',
          enableValue: true,
          allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'],
          minWidth:200,
        },
        
        { field: 'INVESTED_HOLDING',
          suppressSizeToFit: true , 
          rowGroup: false, 
          enableRowGroup: true ,
          enablePivot: true,
          defaultAggFunc: 'sum',
          enableValue: true,
          allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'],
          minWidth:200,
        },
          { field: 'CURRENT_HOLDING', 
          resizable: true ,
          suppressSizeToFit: true , 
          rowGroup: false, 
          enableRowGroup: true ,
          enablePivot: true,
          defaultAggFunc: 'sum',
          enableValue: true,
          allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'],
          minWidth:180, 
        },
        { field: 'AVG_VALUE', 
        resizable: true , 
        suppressSizeToFit: true ,
        rowGroup: false, 
        enableRowGroup: true ,
        enablePivot: true,
        defaultAggFunc: 'sum',
        enableValue: true,
        allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'],
        minWidth:120, 
        
        },
          { field: 'UNREALISED', 
            cellClassRules: {
            'text-danger': params => {
              const value = parseFloat(params.value.toString().replace(/,/g, '')); 
              return value < 0;
            },
            'text-success': params => {
              const value = parseFloat(params.value.toString().replace(/,/g, '')); 
              return value => 0;
            },
            
          }, 
          resizable: true ,  
          width: 160, 
          suppressSizeToFit: true , 
          rowGroup: false, 
          enableRowGroup: true ,
          enablePivot: true,
          defaultAggFunc: 'sum',
          enableValue: true,
          allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'] ,
          minWidth:150,
        },
          { field: 'REALISED',
            cellClassRules: {
        'text-danger': params => {
          const value = parseFloat(params.value.replace(/,/g, '')); 
          return value < 0;
            },
            'text-success': params => {
              const value = parseFloat(params.value.replace(/,/g, '')); 
              return value => 0;
            },
            
          }, 
          resizable: true, 
          width: 140, 
          suppressSizeToFit: true , 
          rowGroup: false, 
          enableRowGroup: true,
          enablePivot: true,
          defaultAggFunc: 'sum',
          enableValue: true,
          allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'],
          minWidth:150, 
        },
        { field: 'PER_GAIN',
          cellClassRules: {
            'text-danger': params => {
              const value = parseFloat(params.value.toString().replace(/,/g, '')); 
              return value < 0;
            },
            'text-success': params => {
              const value = parseFloat(params.value.toString().replace(/,/g, '')); 
              return value => 0;
            },
            
          },  
          resizable: true, 
          width: 140, 
          suppressSizeToFit: true , 
          rowGroup: false, 
          enableRowGroup: true,
          enablePivot: true,
          defaultAggFunc: 'sum',
          enableValue: true,
          allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'],
          minWidth:150, 
        }
        ,
        { 
          field: 'BROKERAGE',
          resizable: true , 
          suppressSizeToFit: true , 
          rowGroup: false, 
          enableRowGroup: true ,
          enablePivot: true,
          defaultAggFunc: 'sum',
          enableValue: true,
          allowedAggFuncs: ['sum', 'min', 'max','avg','count','first','last'] ,
          minWidth:130, 
        }
      
       
        ]
  
        
     )
}
else if (button=='GROUPING MODE')
{
 setColumnDefs(
      [
      //   { field: 'SNO', 
      //   resizable: true ,   
      //   suppressSizeToFit: true 
      
      // },
        { field: 'STOCKS', 
        resizable: true ,  
        suppressSizeToFit: true ,
        rowGroup: true,
        enableRowGroup: true ,
        enablePivot: true ,
        minWidth:200,
        cellStyle: { textAlign: 'center' }
        // pivot: true
       
    
      },
        { field: 'INDUSTRY', 
        resizable: true , 
        filter:true , 
        rowGroup: true, 
        enableRowGroup: true ,
        enablePivot: true,
        minWidth:200,
        cellStyle: { textAlign: 'center' }
        // pivot: true
      },
        { field: 'MARKET_CAP', 
        resizable: true ,  
        width: 160, 
        suppressSizeToFit: true , 
        rowGroup: true, 
        enableRowGroup: true ,
        enablePivot: true,
        minWidth:150,
        cellStyle: { textAlign: 'center' }
        // pivot: true
      },
        { field: 'QTY', 
        resizable: true ,
        suppressSizeToFit: true , 
        rowGroup: false, 
        enableRowGroup: true ,
        enablePivot: true,
        enableValue: true,
        allowedAggFuncs: ['sum', 'min', 'max','avg'],
        minWidth:100,
      },
        { field: 'CURRENT_MARKET_PRICE', 
        resizable: true , 
        filter:true , 
        rowGroup: false, 
        enableRowGroup: true ,
        enablePivot: true,
        
        enableValue: true,
        allowedAggFuncs: ['sum', 'min', 'max','avg'],
      },
        { field: 'INVESTED_HOLDING',
        resizable: true , 
        suppressSizeToFit: true , 
        rowGroup: false, 
        enableRowGroup: true ,
        enablePivot: true,
        enableValue: true,
        allowedAggFuncs: ['sum', 'min', 'max','avg'],
        },
        { field: 'CURRENT_HOLDING', 
        resizable: true , 
        suppressSizeToFit: true , 
        rowGroup: false, 
        enableRowGroup: true ,
        enablePivot: true,
        enableValue: true,
        allowedAggFuncs: ['sum', 'min', 'max','avg'],
      },
      { field: 'AVG_VALUE', 
      resizable: true ,  
        width: 160, 
        suppressSizeToFit: true , 
        rowGroup: false, 
        enableRowGroup: true ,
        enablePivot: false,
        enableValue: true,
        allowedAggFuncs: ['sum', 'min', 'max','avg'],
      
      },
        { field: 'UNREALISED',
          
        resizable: true ,  
        width: 160, 
        suppressSizeToFit: true , 
        rowGroup: false, 
        enableRowGroup: true ,
        enablePivot: false,
      
        enableValue: true,
        allowedAggFuncs: ['sum', 'min', 'max','avg'],
      },
        { field: 'REALISED', 
        resizable: true, 
        width: 140, 
        suppressSizeToFit: true , 
        rowGroup: false, 
        enableRowGroup: true,
        enablePivot: true,
       
        enableValue: true,
        allowedAggFuncs: ['sum', 'min', 'max','avg'],
      },
      { field: 'PER_GAIN', 
        resizable: true, 
        width: 140, 
        suppressSizeToFit: true , 
        rowGroup: false, 
        enableRowGroup: true,
        enablePivot: true,
       
        enableValue: true,
        allowedAggFuncs: ['sum', 'min', 'max','avg'],
      }
    
     
      ]
    )
}

  },[button])






// mqttClient###############################






//   const call=async()=>{

//     const options = {
//       method: 'GET',
//       url: 'https://latest-stock-price.p.rapidapi.com/any',
//       headers: {
//         'X-RapidAPI-Key': '44620cb19emshb07aac6eec7281ep111f20jsn044c6ed84d8c',
//         'X-RapidAPI-Host': 'latest-stock-price.p.rapidapi.com'
//       }
//     };

//     try {

//       const response = await axios.request(options);
      
//       setLiveData(response.data)

//     } catch (error) {

//       console.error(error);

//     }
  
//   }


// useEffect(()=>{

// setTimeout(() => {

//   call()
  
// }, 5000);

// },[])


// function addLastTransactionPrice(arr1, arr2) {
//   // Create a map for faster lookup of symbols in the second array
//   const symbolMap = new Map(arr2.map(item => [item.symbol, item.lastPrice]));

//   // Map over the first array and add last_transaction_price field
//   const result = arr1.map(item => {
//       // Check if the symbol exists in the second array
//       if (symbolMap.has(item.STOCKS)) {
//           // Add last_transaction_price field from the second array
//           item.last_transaction_price = symbolMap.get(item.STOCKS);
//       }
//       return item;
//   });

//   return result;
// }



// const mqttfire=()=>{

//   // call()

//   const resultArray = addLastTransactionPrice(currentholdingdata, mqttdata);
//   setCurrentHoldingData(resultArray);

   


   
// }
   




// useEffect(() => {
//   const client = mqtt.connect(MQTT_HOST);

//   client.on('connect', () => {

//     setMqttClient(client);

//     client.subscribe(MQTT_TOPIC);

//   });

//   return () => {

//     client.end(); 

//   };

// }, []);


// const publishData = () => {
//   if (mqttClient) {
//     const data = JSON.stringify(livedata); 
//     mqttClient.publish(MQTT_TOPIC, data);
//   }
// };

// useEffect(() => {
//   const intervalId = setInterval(publishData, 1 * 60 * 1000);

//   return () => clearInterval(intervalId); 
// }

// , [mqttClient]


// ); 


// useEffect(() => {
  

//   const handleMessage = (topic, message) => {

//     setMqttData(JSON.parse(message.toString()));
    
 
//   };

//   if (mqttClient) {
//     mqttClient.on('message', handleMessage);
//   }

//   return () => {
//     if (mqttClient) {
//       mqttClient.off('message', handleMessage); 
//     }
//   };

// }, [mqttClient]); 





// mqttClient###############################






    
// function clickEffect() {
//   var img = document.getElementById('clickImage');
//   img.style.transition = 'transform 1.0s ease';
//   img.style.transform = 'rotate(360deg)';
//   window.open('https://concorderp.com/mail_panel/stockMqtt.php', '_blank');
//   setTimeout(function() {
//     img.style.transform = 'none';

//   }, 500);

// }



function createAndAppendIframe() {
  var iframe = document.createElement('iframe');
  iframe.src = 'https://concorderp.com/mail_panel/stockMqtt.php';
  iframe.title = 'liveapi';
  iframe.style.width = '100%';
  iframe.style.height = '500px';
  document.body.appendChild(iframe); 
}

function closeIframe() {
  var iframe = document.querySelector('iframe'); 
  if (iframe) {
    iframe.parentNode.removeChild(iframe); 
  }
}




function clickEffect() {

  
  var img = document.getElementById('clickImage');
  img.style.transition = 'transform 1.5s ease';
  img.style.transform = 'rotate(360deg)';
  setShowModalPass(true)
//  setTimeout(function() {
//         img.style.transform = 'none';
         
//   }, 1700);


}






const handleRowClicked = (event) => {
  
  
  setSelectedRowData(event.data);
  setShowModal(true);


};



 
    

const ModalPass =({showpass})=>{

  const [number, setNumber] = useState('')
  const [error,setError]=useState({})
  const handleerror=(input,value)=>{

  setError((prev)=>({...prev,[input]:value}))
  
}


	  // Validation function 

    const validation=()=>{
  
      var isvalid=true
      
    
    if(number == "")
      {
        handleerror("numberr","Please Enter Admin Mobile Number")
        isvalid=false
      }
      else if(isNaN(number))
      {
        handleerror("numberr","Please Enter Number (Only Digit)")
        isvalid=false
      }
      else if(number.length!==10)
        {
          handleerror("numberr","Please Enter 10 Digit Valid Number")
          isvalid=false
        }
      else if(admindetail.b_mobile_no !== number)
      {
          handleerror("numberr","Number Does Not Match With Admin Number")
          isvalid=false
      }
    
    
    
      return isvalid
    
      
    }


   


  const handleSubmit = () => {
    
    const Swal = require('sweetalert2')

   


  if(validation()){

   
      setShowModalPass(false)
      createAndAppendIframe()
      setShowLoading(true)


      

      setTimeout(function() {

        
        closeIframe()
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: 'Live Data Update Successfully',
          showConfirmButton: false,
          timer: 2000
        })
        
        setShowLoading(false)
        
      }, 25000);


   
  }
 

  };




  return (
    <div className={`modal fade ${showpass ? 'show d-block' : ''}`} id="exampleModalToggle" aria-hidden="true" tabIndex="-1" role="dialog" style={{ display: showpass ? 'block' : 'none' }}>
    <div className="modal-dialog modal-dialog-centered modal-xl" role="document" style={{ maxWidth: '40%' }}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Admin Authentication</h5>
          <button  type="button" style={{background:'#ffff',border:'none',padding:'10px'}} aria-label="Close" onClick={() => setShowModalPass(false)}>
            <img  style={{width:'20px'}}  src='../assets/cancel.png'/>
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label htmlFor="passwordInput">Mobile No</label>
            <input
              type="text"
              className="form-control"
              id="passwordInput"
              placeholder="Enter Admin Mobile Number"
              value={number}
              onChange={(e)=>setNumber(e.target.value)}
              onFocus={()=>setError('numberr',null)}
            />
          </div>
          <div style={{display:'flex',alignItems:'center',color:'#c23616',padding:'5px'}}>
          {error.numberr} 
          </div>
        </div>

      

        <div className="modal-footer">
          {/* <button type="button" className="btn btn-secondary" onClick={() => setShowModalPass(false)}>Close</button> */}
          <button type="button" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </div>
  </div>

  )
}



const getnewlist=async()=>{




  var formdata=new FormData()
  formdata.append('admin_id',admindetail.user_id) 
  formdata.append('stock_name',selectedRowData.STOCKS) 
  const result= await axios.post(`${URL}stockInfo&bpuser=10891&action=Getnews`,formdata)
  
  if(result.data.data)
  {
      setNewsListData(result.data.data)
      
  }
  else
  {
      console.log('No data found..')
  }

 
}

useEffect(()=>{

getnewlist()

},[selectedRowData])









const NewsCard = ({ date, content ,newsid }) => {

  const [showMore, setShowMore] = useState(false);

  return (
      <div  className='shadow-lg card' style={{ width: '100%', border: '1px solid black', marginBottom: '10px' }}>
          <div className='row'>
              <div className="col-sm-12" style={{ padding: '15px' }}>
                  <h6>{date}</h6>
                  <div style={{
                      fontSize: '15px',
                      maxHeight: showMore ? 'none' : '3em', 
                      overflow: 'hidden',
                      position: 'relative'
                  }}
                  
                  onClick={()=>navigate('/news', { state: { news_id: newsid } })}
                  
                  >
                      {content}
                  </div>
                  <button 
                      onClick={() => setShowMore(!showMore)}
                      style={{
                          border: 'none',
                          background: 'none',
                          color: '#0069d9',
                          cursor: 'pointer',
                          padding: 0,
                          marginTop: '5px'
                      }}
                  >
                      {showMore ? 'Less' : 'More'}
                  </button>
              </div>
          </div>
      </div>
  );
};



const NewsList = () => {

  return (
      <div className='container' style={{ padding: '20px' }}>
          {newslistdata.map((news, index) => (
              <NewsCard 
                  key={index}
                  date={news.cur_date}
                  content={news.news}
                  newsid={news.news_id}
              />
          ))}
      </div>
  );
};


// console.log(newslistdata,'newslistdata')



const getwatchlist = async (stocknm) => {
  var formdata = new FormData();
  formdata.append('admin_id', admindetail.user_id);
  formdata.append('stock_name', stocknm);


  
  try {
    const result = await axios.post(`${URL}stockInfo&bpuser=10891&action=addwatchlist`, formdata);
    if(result.data.res_code===1)
      {
        setWatchListButton(true)
      }
      
  } 
  catch (error) 
  {
    console.error('Error fetching watchlist:', error);
  }

};


// Create a debounced version of getwatchlist
const debouncedGetWatchlist = useCallback(debounce(getwatchlist, 300), []);





const getcheckwatchlist = async (stocknm) => {
  var formdata = new FormData();
  formdata.append('admin_id', admindetail.user_id);
  formdata.append('stock_name', stocknm);

  try {
    const result = await axios.post(`${URL}stockInfo&bpuser=10891&action=checkwatchlist`, formdata);
    if(result.data.res_code===1)
    {
      setWatchListButton(true)
    }
    else if(result.data.res_code===2)
    {
      setWatchListButton(false)
    }

  } 
  catch (error) 
  {
    console.error('Error fetching watchlist:', error);
  }

};


// Create a debounced version of getwatchlist
const debouncedGetCheckWatchlist = useCallback(debounce(getcheckwatchlist, 400), []);






const Modal = ({ show, rowData }) => {


   

  // console.log(selectedRowData.CURRENT_MARKET_PRICE,'selectedRowData...')

  

  const [stockInfo, setStockInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error,setError]=useState({})
  const [quantity,setQuantity]=useState('')
  const [trantype,setTranType]=useState('0')
  const [broker,setBroker]=useState('')
  const [per,setPer]=useState('')
  const [price,setTargetPrice]=useState('')
  const [gttshow,setGttShow]=useState(false)
  const [newsshow,setNewsShow]=useState(false)
  const [brokerdata,setBrokerdata]=useState([])
  const [transformedData,setTransformedData]=useState([])
  const [chartperameeter,setChartPeraMeeter]=useState('CUMULATIVE_VALUE')
  const [daysbutton,setDaysButton]=useState('')

  
  // CHART..................................................STATE

  

  const oneMonthAgo  = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 2);

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  const currentMonth  = new Date();
  currentMonth.setMonth(currentMonth.getMonth() - 1);

  const [fromDate,setFromDate] = useState(oneMonthAgo);
  const [toDate,setToDate] = useState(new Date());
  


  
function transformData(data,button) {

 

  return data.map(item => {
    // Convert the DATE to a timestamp
    const dateParts = item.DATE.split('/');
    const dateObj = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
    const timestamp = dateObj.getTime();

    // Return an array with the timestamp and CUMULATIVE_VALUE
    return [timestamp, parseFloat(item[button].replace(/,/g, ''))];
  });
}





useEffect(()=>{

  setTransformedData(transformData(stockInfo,chartperameeter))

 

},[stockInfo,chartperameeter])


// console.log(transformedData,'stockInfo')


  // CHART..................................................STATE
  
 


  React.useEffect(() => {

    if (show) {

      debouncedGetCheckWatchlist(selectedRowData.STOCKS);

    }

  }, [show, selectedRowData.STOCKS, debouncedGetCheckWatchlist]);




  const getbroke=async()=>{
    var formdata=new FormData()
    formdata.append('admin_id',admindetail.user_id) 
    const result= await axios.post(`${URL}brokerinfo&bpuser=10891&action=getbrokers`,formdata)
    setBrokerdata(result.data.data)
    
    }

    useEffect(()=>{

      getbroke()


    },[])

    const showbroker=()=>{

      return  brokerdata.map((val)=>{
      return (   
            <><option value={val.user_id} >{val.user_name}</option></>
            )
          }
    )}




  const columnDef = useMemo(() => [
      
    { field: 'DATE',
    resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:120,
    cellStyle: { textAlign: 'center' }
  },
    { field: 'BUY_QUANTITY',
    resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:150,
    cellStyle: { textAlign: 'right' }
  },
    { field: 'BUY_RATE',
    resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:120,
    cellStyle: { textAlign: 'right' }
  },
    { field: 'BUY_VALUE',
    resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:120,
    cellStyle: { textAlign: 'right' }
  },
    { field: 'SELL_QUANTITY',
    resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:150,
    cellStyle: { textAlign: 'right' }
  },
    { field: 'SELL_RATE',
    resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:120,
    cellStyle: { textAlign: 'right' }
  },
    { field: 'SELL_VALUE',
    resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:120,
    cellStyle: { textAlign: 'right' }
  },
    { field: 'CUMULATIVE_VALUE',
    resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:180,
    cellStyle: { textAlign: 'right' }
  },
    { field: 'CUMULATIVE_QTY',
    resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:180,
    cellStyle: { textAlign: 'right' }
  },
    { field: 'REALISED',
      cellClassRules: {
            'text-danger': params => {
              const value = parseFloat(params.value.replace(/,/g, '')); 
              return value < 0;
            },
            'text-success': params => {
              const value = parseFloat(params.value.replace(/,/g, '')); 
              return value => 0;
            },
            
          }, 
      
    resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:120,
    cellStyle: { textAlign: 'right' }
  },
    { field: 'UNREALISED',
      cellClassRules: {
            'text-danger': params => {
              const value = parseFloat(params.value.toString().replace(/,/g, '')); 
              return value < 0;
            },
            'text-success': params => {
              const value = parseFloat(params.value.toString().replace(/,/g, '')); 
              return value => 0;
            },
            
          }, 
    resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:150,
    cellStyle: { textAlign: 'right' }
  },

  ], []);


  const defaultColDe = useMemo(() => ({
    editable:true,
    resizable: true,
    sortable: true,
    filter:true,
    floatingFilter:true,
    cellRenderer:true,
    flex:1,
    maxWidth: 300,
    suppressSizeToFit: true,
  
  }), []);




  useEffect(() => {



  
    const fetchStockInfo = async () => {

   

      try {
        const formdata = new FormData();
        formdata.append('stock_name', selectedRowData.STOCKS);
        formdata.append('admin_id', admindetail.user_id); 
        formdata.append('broker_id', brokerid); 
        const response = await axios.post(`${URL}stockInfo&bpuser=10891&action=singleScriptStock`,formdata);
     
        setStockInfo(response.data.data); 
        setLoading(false);
      } catch (error) {
        console.error('Error fetching stock information:', error);
        setLoading(false);
      }

    };

    if (show && mqttdata) {

      fetchStockInfo();

    }
  }, [show, mqttdata]);



  if (!show) {
    return null;
  }


  const handleerror=(input,value)=>{

    setError((prev)=>({...prev,[input]:value}))
    
  }



  
const validation=()=>{
  
  var isvalid=true

 
  
  if(trantype=='0')
  {
    handleerror("s_tran_type","Please Select Transaction Type")
    isvalid=false
  }

  if(quantity=="")
    {
      handleerror("s_qty","Please Enter Stock Quantity")
      isvalid=false
    }
    else if(isNaN(quantity))
    {
      handleerror("s_qty","Please Enter Stock Quantity (Only Number)")
      isvalid=false
    }

    if(per=="" && price=="")
      {
        handleerror("s_per","Please Enter Stock Per% or Price")
        isvalid=false
      }
      else if(isNaN(per) || isNaN(price))
      {
        handleerror("s_per","Please Enter Stock Per% or price (Only Number)")
        isvalid=false
      }


    if(broker=="")
      {
    
        handleerror("s_broker","Please Select Broker")
        isvalid=false
        
    
      }
         


  
  return isvalid
}


  const Submit=async()=>{

    if(validation())
    {


      var formdata = new FormData();
      formdata.append('admin_id', admindetail.user_id);
      formdata.append('broker_id',broker)
      formdata.append('stock_name',selectedRowData.STOCKS)
      formdata.append('gtt_per',per)
      formdata.append('gtt_trigger',price)
      formdata.append('gtt_action',trantype)
      formdata.append('gtt_qty',quantity)
    
      try {
        const result = await axios.post(`${URL}stockInfo&bpuser=10891&action=gttaddalert`, formdata);
       
        if(result.data.res_code==1)
          {
            
           
            Swal.fire({
              position: 'top-center',
              icon: 'success',
              title: 'GTT Set Successfully',
              showConfirmButton: false,
              timer: 1600
            }).then((result) => {
               
              
              setQuantity('')
              setTranType('0')
              setBroker('')
              setPer('')
              setTargetPrice('')
              

             
            })
         
         
          }
         
      } 
      catch (error) 
      {
        console.error('Error fetching watchlist:', error);
      }
       
    

    }
  
  }


// .....................................................................ApexChart............



const ApexChart = () => {


  const [selection, setSelection] = useState('one_year');

  const options = useMemo(() => ({
    chart: {
      id: 'area-datetime',
      type: 'area',
      height: 200,
      zoom: {
        autoScaleYaxis: true
      }
    },
    annotations: {
      yaxis: [{
        y: 30,
        borderColor: '#999',
        label: {
          show: true,
          text: 'Support',
          style: {
            color: "#fff",
            background: '#00E396'
          }
        }
      }],
      xaxis: [{
        x: new Date(fromDate).getTime(),
        borderColor: '#999',
        yAxisIndex: 0,
        label: {
          show: false,
          text: 'Rally',
          style: {
            color: "#fff",
            background: '#775DD0'
          }
        }
      }]
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
      style: 'hollow',
    },
    xaxis: {
      type: 'datetime',
      min: new Date(fromDate).getTime(),
      tickAmount: 6,
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    },
  }), [fromDate]);

  
  const series = [{

    data:transformedData
    
  }];


  // const updateData = (timeline) => {


  //   setSelection(timeline);

  //   // const startDate = {
  //   //   one_month: new Date('28 Jan 2013').getTime(),
  //   //   six_months: new Date('27 Sep 2012').getTime(),
  //   //   one_year: new Date('27 Feb 2012').getTime(),
  //   //   ytd: new Date('01 Jan 2013').getTime(),
  //   //   all: new Date('23 Jan 2012').getTime(),
  //   //   to_date:new Date(toDate).getTime()
  //   // };
  
  //   const startDate= new Date(fromDate).getTime();
  //   const endDate = new Date(toDate).getTime();

  //   ApexCharts.exec(
  //     'area-datetime',
  //     'zoomX',
  //     startDate,
  //     endDate
  //   );


  // };

  return (
    <div>
      <div id="chart">
        {/* <div className="toolbar">
          <button
            id="one_month"
            onClick={() => updateData('one_month')}
            className={selection === 'one_month' ? 'active' : ''}
          >
            1M
          </button>

          <button
            id="six_months"
            onClick={() => updateData('six_months')}
            className={selection === 'six_months' ? 'active' : ''}
          >
            6M
          </button>

          <button
            id="one_year"
            onClick={() => updateData('one_year')}
            className={selection === 'one_year' ? 'active' : ''}
          >
            1Y
          </button>

          <button
            id="ytd"
            onClick={() => updateData('ytd')}
            className={selection === 'ytd' ? 'active' : ''}
          >
            YTD
          </button>

          <button
            id="all"
            onClick={() => updateData('all')}
            className={selection === 'all' ? 'active' : ''}
          >
            ALL
          </button>

        </div> */}

        <div id="chart-timeline">
          <ReactApexChart options={options} series={series} type="area" height={350} />
        </div>
      </div>
      <div id="html-dist"></div>
    </div>
  );
};




// ........................testing table

	
// const [series] = useState([{
//   name: 'MTF',
//   data: [31, 40, 28, 51, 42, 109, 100]
// }, {
//   name: 'NML',
//   data: [11, 32, 45, 32, 34, 52, 41]
// },
// {
//   name: 'GAIN',
//   data: [11, 15, 7, 20, 12, 5, 16]
// },
// {
//   name: 'BROKERAGE',
//   data: [11, 20, 14, 32, 20, 10, 11]
// },
// {
//   name: 'REALISED',
//   data: [330, 250, 153, 260, 105, 310, 330]
// },
// {
//   name: 'UNREALISED',
//   data: [350, -200, 140, 320, 200, 100, 110]
// }
// ]);


// ........................testing table



// .....................................................................ApexChart............


  return ( 
   
    <div className={`modal fade ${show ? 'show d-block' : ''}`} id="exampleModalToggle" aria-hidden="true" tabIndex="-1" role="dialog" style={{ display: show ? 'block' : 'none' }}>

      <div className="modal-dialog modal-dialog-centered modal-xl" style={{width:'90vw'}} role="document">

        <div className="modal-content">

          <div className="modal-header">

          <h5 className="modal-title">{selectedRowData.STOCKS}</h5>

          
            <h6 style={{display:'flex'}} className="modal-title">CMP :&nbsp;<div style={{color:'#'}}>{selectedRowData.CURRENT_MARKET_PRICE}</div></h6>
            <h6 style={{display:'flex'}} className="modal-title">Day High :&nbsp;<div style={{color:'green'}}>{selectedRowData.High}</div></h6>
            <h6 style={{display:'flex'}} className="modal-title">Day Low :&nbsp;<div style={{color:'red'}}>{selectedRowData.Low}</div></h6>
            
            <h6 style={{display:'flex'}} className="modal-title">52 Week High :&nbsp;<div style={{color:'green'}}>{selectedRowData["52Wk High"]}</div></h6>
            <h6 style={{display:'flex'}} className="modal-title">52 Week Low :&nbsp;<div style={{color:'red'}}>{selectedRowData["52Wk Low"]}</div></h6>
            <div style={{marginTop:'5px'}}>
             
             {watchlistbutton ? <>
              <img  onClick={()=>debouncedGetWatchlist(selectedRowData.STOCKS)} style={{width:'20px'}}  src='../assets/fillbookmark.png'/>
             </>:<>
             <img  onClick={()=>debouncedGetWatchlist(selectedRowData.STOCKS)} style={{width:'20px'}}  src='../assets/unfillbookmark.png'/>
             </>}


             
            </div>

            <button  type="button" style={{background:'#ffff',border:'none',padding:'10px'}} aria-label="Close" onClick={() => {setShowModal(false)
            setchartshow(false)
            }}>
             
             
              <img  style={{width:'20px'}}  src='../assets/cancel.png'/>

            </button>

          </div>

          <div className="modal-body" style={{height:"20%",width:"100%",overflowY: "auto"}}>

          


          { gttshow || newsshow ? <></> :<>

            <button onClick={()=>setchartshow(!showchart)} style={{margin:'0px 20px 5px 0px',background:'#007bff',color:'#ffff',padding:'5px'}}>CHART</button>
          
          {
            
            
            showchart ?<>
          
          
          <DatePicker  selected={fromDate} onChange={(date) => setFromDate(date)} /> &nbsp; - &nbsp;
          <DatePicker  selected={toDate} onChange={(date) => setToDate(date)} />
         

          
          <button  
            onClick={()=>setChartPeraMeeter('BUY_RATE')}
            style={{
            margin:'0px 0px 0px 20%',
            width:'7%',
            background:chartperameeter==='BUY_RATE'?'#007bff':'#66b2ff',color:'#ffff',padding:'5px'}}>BUY RATE

          </button>
          <button 
            onClick={()=>setChartPeraMeeter('SELL_RATE')} 
            style={{
            margin:'0px 0px 0px 2%',
            width:'7%',
            background:chartperameeter==='SELL_RATE'?'#007bff':'#66b2ff',color:'#ffff',padding:'5px'}}>SELL RATE
            
          </button>
          <button  
            onClick={()=>setChartPeraMeeter('CUMULATIVE_VALUE')}
            style={{
            margin:'0px 0px 0px 2%',
            width:'8.5%',
            background:chartperameeter==='CUMULATIVE_VALUE'?'#007bff':'#66b2ff',color:'#ffff',padding:'5px'}}>CUM_VALUE

          </button>
          <button  
            onClick={()=>setChartPeraMeeter('CUMULATIVE_QTY')}
            style={{
            margin:'0px 0px 0px 2%',
            width:'8.5%',
            background:chartperameeter==='CUMULATIVE_QTY'?'#007bff':'#66b2ff',color:'#ffff',padding:'5px'}}>CUM_QTY

          </button>
          <hr/>
          <button  
            onClick={()=>{setFromDate(oneWeekAgo)
            setDaysButton('WEEK')}
            }
            style={{
            margin:'0px 0px 0px 7%',
            width:'7%',
            background:daysbutton==='WEEK'?'#007bff':'#66b2ff',color:'#ffff',padding:'5px'}}>WEEK

          </button>
          <button  
            onClick={()=>{setFromDate(currentMonth)
              setDaysButton('MONTH')}
            }
            style={{
            margin:'0px 0px 0px 2%',
            width:'7%',
            background:daysbutton==='MONTH'?'#007bff':'#66b2ff',color:'#ffff',padding:'5px'}}>MONTH

          </button>
          <hr/>
          
         

       </>:<></>}
        

        {showchart ? <>

          <ApexChart/>


         </>:<>


          {loading ? (

            <p>Loading...</p>

            ) : (

            <div className="ag-theme-alpine" style={{ height: '342px'}}>
              {Array.isArray(stockInfo) && stockInfo.length > 0 ? (
                <AgGridReact
                  rowData={stockInfo}
                  columnDefs={columnDef} 
                  defaultColDef={defaultColDe}
                  pagination={false}
                  paginationPageSize={4} 
                />
              ) : (
                <p>No data available</p>
              )}
            </div>

          )}




        </>}



            </>}

           



          

          </div>
     
          <div className='row' style={{display:'felx',justifyContent:'center',padding:'20px 22px 0px 22px',marginTop:'1vh'}}>

            <div className='card' style={{ width: '50%',height:gttshow?"40vh":"4vh", borderRight: '2px solid black',display:'flex'}} >
              <div style={{display:'flex',justifyContent:'center'}}>
              <h6 style={{cursor:"pointer",color:'#0069d9'}} onClick={() =>setGttShow(!gttshow)}  >CREATE GTT</h6>
              {/* <h3  style={{background:'#0069d9',color:'#fff',padding:'0px 2px 0px 2px',borderRadius:'5px',marginLeft:'10px',cursor:'pointer'}}>+</h3> */}
              </div>
              
              {gttshow ? <>

             
                <div className='row' style={{padding:'10px',position:'relative'}}>


                  <div className="col-sm-6">

                    <div className="form-group">
                      <label>Action</label>
                        <select   onFocus={()=>setError('s_tran_type',null)}  onChange={(e)=>setTranType(e.target.value)}  className="form-control">
                          
                        <option value={'0'} >--Selecte Action--</option> 
                        <option value="Buy" >Buy</option>
                        <option value="Sell">Sell</option>

                      </select>
                    </div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>

                      {error.s_tran_type} 

                    </div>

                  </div>

                  <div className="col-sm-6">
                      
                      <div className="form-group">
                        <label>ACCOUNT</label>
                        <select   onFocus={()=>handleerror('s_broker',null)}   onChange={(e)=>setBroker(e.target.value)}  className="form-control">
                          <option value={null} >--Select Account--</option>
                          {showbroker()}
                        </select>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.s_broker}
                      </div>

                    </div>

                  <div className="col-sm-6">
                          
                    <div className="form-group">
                      <label>Quantity</label>
                      <input  onFocus={()=>setError('s_qty',null)}   onChange={(e)=>setQuantity(e.target.value)}   type="text" className="form-control" placeholder="Enter Quantity"/>
                    </div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                    
                      {error.s_qty} 
                    
                    </div>
                    
                  </div>

                  {/* <div className="col-sm-6">
                          
                    <div className="form-group">
                      <label>Per%</label>
                      <input  onFocus={()=>setError('s_per',null)}   onChange={(e)=>setPer(e.target.value)}   type="text" className="form-control" placeholder="Enter Per%"/>
                    </div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                    
                      {error.s_per} 
                    
                    </div>
                    
                  </div> */}
                  <div className="col-sm-6">
                          
                    <div className="form-group">
                      <label>Target Price</label>
                      <input  onFocus={()=>setError('s_per',null)}   onChange={(e)=>setTargetPrice(e.target.value)}   type="text" className="form-control" placeholder="Enter Target Price"/>
                    </div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                    
                      {error.s_per} 
                    
                    </div>
                          
                  </div>



                  <div className="col-sm-6" style={{marginTop:'10px'}}>

                
                    <button onClick={()=>Submit()}  style={{width:'100%'}}  type="button" className="btn btn-primary">Submit</button>


                  </div>





                </div>

              </>:<></>}


            </div>

            <div className='card' style={{ width: '50%',height:newsshow ?"40vh":"4vh", borderLeft: '2px solid black',display:'flex' ,padding:'0px 20px 20px 20px', overflowX: 'hidden', overflowY: 'auto',flexDirection: 'column'}}>
              
              <div style={{display:'flex',justifyContent:'center',alignItems:'center'}} >
                <h6 onClick={() =>setNewsShow(!newsshow)} style={{cursor:"pointer",color:'#0069d9'}}>NEWS</h6> 
                {/* <h3  style={{background:'#0069d9',color:'#fff',padding:'0px 2px 0px 2px',borderRadius:'5px',marginLeft:'10px',cursor:'pointer'}}>+</h3> */}
              </div>

               
               {newsshow ? <>

                

                <NewsList/>

                {/* <div className='shadow-lg card' style={{width:'100%',border:'1px solid black'}}>

                  <div className='row'  >
                    
                    <div  className="col-sm-12" style={{padding:'15px'}} >

                      <h6>
                          2024-06-24
                      </h6>

                      <div style={{fontSize:'15px'}}>
                        adsfsdfasdfasdfasdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdddddddddddddccccccfadsfsdfasdfasdfasdgasdgasdgfasvsadfdfasdfsdfasd
                      </div>

                      

                    </div>

                  </div>
                  

                </div> */}



               </>:<></>}


            </div>



          </div>   

          <div className="modal-footer">

            <div style={{display:'flex',flexDirection:"Column",justifyContent:'space-between',width:'100%',padding:'10,10,10,10'}}>
            
            {Array.isArray(stockInfo) && stockInfo.length > 0 ? (
              <>
              <div style={{display:'flex',justifyContent:'space-between',width:'100%',padding:'10,10,10,10'}}>
              <h6  style={{display:'flex'}} className="modal-title">Invested Holding :&nbsp;<div >{selectedRowData.INVESTED_HOLDING}</div></h6>
              <h6 style={{display:'flex'}} className="modal-title">Current Holding :&nbsp;<div >{selectedRowData.CURRENT_HOLDING.toLocaleString()}</div></h6>
              <h6 style={{display:'flex'}} className="modal-title">Avg. Value :&nbsp;<div >{selectedRowData.AVG_VALUE}</div></h6>
              <h6 style={{display:'flex'}} className="modal-title">Number of Shares :&nbsp;<div >{selectedRowData.QTY}</div></h6>
              </div>
              <div style={{display:'flex',justifyContent:'space-between',width:'100%',padding:'10,10,10,10',marginTop:"20px"}}>
               <h6 style={{display:'flex'}} className="modal-title">Unrealized :&nbsp;<div style={{color: selectedRowData.UNREALISED < 0 ?"red":'green'}} >{selectedRowData.UNREALISED.toLocaleString()}</div></h6>
               <h6 style={{display:'flex'}} className="modal-title">P&L :&nbsp;<div style={{color: selectedRowData.REALISED.includes('-') ?"red":'green'}}>{selectedRowData.REALISED}</div></h6>
               {/* <h6 style={{display:'flex'}} className="modal-title">P&L :&nbsp;<div style={{color: selectedRowData.REALISED.includes('-') ?"red":'green'}}>{selectedRowData.REALISED}</div></h6> */}
              </div>
              {/* <div style={{display:'flex',justifyContent:'left',width:'100%',padding:'10,10,10,10',marginTop:"10px"}}>
               
               <h6 style={{display:'flex'}} className="modal-title">P&L :&nbsp;<div style={{color: selectedRowData.REALISED.includes('-') ?"red":'green'}}>{selectedRowData.REALISED}</div></h6>
              </div> */}
              </>
                ) : (
                  <p>No data available</p>
                )}
             

            </div>
          
            {/* <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button> */}
          
          </div>


        </div>


      </div>

   


    </div>




  );

};







  
  return (
   
   
   <>

          <div className="card" >
            
            <div className="card-header"  style={{display:'flex',fontSize:'15px',justifyContent:'space-between',alignItems:'center',borderRadius:'5px',width:'100%'}}>

              {/* <div   style={{fontWeight:'400',display:'flex'}}>HOLDING'S</div> */}
              <h3 className="card-title">HOLDING'S</h3>

                <div style={{fontWeight:'400',marginLeft:'17%'}}>
                  
                  {`${button}`}

                </div>

                <div style={{fontWeight:'400',marginLeft:'8%'}} >

                 {`LAST UPDATE : ${lastupdatetime}`}

                </div>
                
               {showloading ?<>

                <img   id="clickImage" style={{width:'30px'}}  src='../assets/load1.gif'/>

               </>:<>

                <img  onClick={()=>{clickEffect();}} id="clickImage" style={{width:'30px'}}  src='../assets/refresh.png'/>
               
               </>}

               
           
             
              {/* 
              <div className="card-tools">
                
                <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                  <i className="fas fa-minus"></i>
                </button>
                
              </div> */}
           
            </div>


            <div className="card-header">

              <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>

                <button className='btn btn-primary buttons-excel buttons-html5'  onClick={(e)=>showall(e)}   style={{marginRight:'10px'}} >Stock All</button>
                <button className='btn btn-primary buttons-excel buttons-html5'  onClick={(e)=>showonlyzero(e)}   style={{marginRight:'10px'}} >Stock Zero Qty</button>
                <button className='btn btn-primary buttons-excel buttons-html5' value={'gridmode'} onClick={(e)=>normalgrid(e)}  style={{marginRight:'10px'}} >Grid Mode Active</button>
                <button className='btn btn-primary buttons-excel buttons-html5'  onClick={(e)=>pivotgrid(e)}   style={{marginRight:'10px'}} >Pivot Mode Active</button>
                {/* <button className='btn btn-primary buttons-excel buttons-html5'  onClick={(e)=>grouping(e)}   style={{marginRight:'10px'}} >Grouping Mode Active</button> */}
            
              </div>


            </div>


            {/* /.card-header */}
            <div className="card-body">
               
              <div className="ag-theme-alpine" style={{width: '100%', height: 470}}>

              {button=='normal'?<>
                <AgGridReact 
                 
                  ref={gridRef} 
                  rowData={currentholdingdata} 
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef} 
                  enableRangeSelection={true}
                  enableCharts={true}
                  animateRows={true} 
                  rowSelection='multiple' 
                  pagination={true}
                  onGridReady={currentholdingdata}
                  autoGroupColumnDef={autoGroupColumnDef}
                  sideBar={'columns'}
                  pivotMode={true}
                  onRowClicked={handleRowClicked}
                
                 
                />

              </>:<>
              
              
              <AgGridReact 
                
                ref={gridRef} 
                rowData={currentholdingdata} 
                columnDefs={columnDefs} 
                defaultColDef={defaultColDef} 
                enableRangeSelection={true}
                enableCharts={true}
                animateRows={true} 
                rowSelection='multiple' 
                pagination={true}
                autoGroupColumnDef={autoGroupColumnDef}
                sideBar={'columns'}
                onGridReady={currentholdingdata}
                onRowClicked={handleRowClicked}
                
              />

              </>
                


              }

               
              
              </div>

              <div>
         
                <button href="#" className='btn btn-secondary buttons-excel buttons-html5'  style={{ margin: '5px', float:'right' }} data-toggle="modal" data-target="#modal-xl">
                 Manage Stock
                </button>
         
                <button className='btn btn-secondary buttons-excel buttons-html5' style={{ margin: '5px', float:'right' }} onClick={onBtExport}>
                  Export to Excel
                </button>
              
              </div>
            
            </div>
            <Modal show={showModal} rowData={selectedRowData} />
            <ModalPass showpass={showModalPass}  />
          </div>
   </>
  )
}
 