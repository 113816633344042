import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { URL } from './service/url'
import { useNavigate } from 'react-router-dom'
import { Alert } from 'react-bootstrap'


export default function CurrentHoldingForm(props) {
  
  const admindetail=JSON.parse(localStorage.getItem('admindata'))
  const navigate=useNavigate()

  const [trandate,setTranDate]=useState('')
  const [time,setTime]=useState('') 
  const [trantype,setTranType]=useState('')
  const [quantity,setQuantity]=useState('')
  const [stockprice,setStockPrice]=useState('')
  const [stockavgprice,setStocAvgPrice]=useState('')
  const [brokerage,setBrokerage]=useState('')
  const [sabicharge,setSabiCharge]=useState('')
  const [stampduty,setStampDuty]=useState('')
  const [fundtype,setFundtype]=useState('')
  // const [sttstatus,setSttStatus]=useState('')
  const [sttcharge,setSttCharge]=useState('')
  // const [totalcharges,setTotalCharges]=useState('')
  const [broker,setBroker]=useState('')




  const [stockid,setStockId]=useState('')

  // console.log('trantype',trantype)

  
  const [error,setError]=useState({})
  // const [brokerageinalltax,setBrokerageInAllTax]=useState('')
  const [remark,setRemark]=useState('')
  const [industry,setIndustry]=useState([])
  // const [newindustry,setNewIndustry]=useState('')
  const [marketcap,setMarketCap]=useState([])
  const [dealtype,setDealType]=useState('')
  const [stocklist,setStockList]=useState([])
  const [brokerdata,setBrokerdata]=useState([])
  // const [checked, setChecked] = useState(true);
  // const [swalProps, setSwalProps] = useState({});
  const Swal = require('sweetalert2')

  

  





const [brokerid,setBrokerid]=useState()
const [brokername,setBrokerName]=useState()
const [brokertype,setBrokerType]=useState()






 // ...............api get broker 

 const getbroke=async()=>{

  var formdata=new FormData()
  formdata.append('admin_id',admindetail.user_id) 
  const result=await axios.post(`${URL}brokerinfo&bpuser=10891&action=getbrokers`,formdata)
  setBrokerdata(result.data.data)
  
  }


  
  useEffect(()=>{
  
    getbroke()
    // getstock()
   
   },[])


   const getstocklist = async (broker)=>{
    
  
     var fordata = new FormData()

      fordata.append('broker_id',broker)
      fordata.append('buy_sell',trantype)
      fordata.append('admin_id',admindetail.user_id)
  
      var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=getBrokerwiseStock`,fordata)
  
      if(result.data.data)
      {
        setStockList(result.data.data)
      }

    
  }

    useEffect(()=>{

      getstocklist(broker)

    },[broker])






   const showstocklist=()=>{

    return stocklist.map((val)=>{

      return (
       
        <option value={val.StockName} >{val.StockName}</option>
     
        )


    })

  }

  
  const showbroker=()=>{

    return  brokerdata.map((val)=>{
    
         return (
             
           <>

            <option value={val.user_id} >{val.user_name}</option>
   
           </>
   
         )
    
   
     })
   
   
   }


   const setStock=async(value)=>{

   
      setStockId(value);
      let formdat = new FormData();
      formdat.append('stock_name', value);
      formdat.append('broker_id', broker);
      formdat.append('admin_id', admindetail.user_id);
  
      try {
        var result = await axios.post(`${URL}stockInfoexcel&bpuser=10891&action=stockCheckExits`, formdat);
        
        // Ensure data and data[0] are defined before accessing
        const data = result.data?.data || [];
        if (data.length > 0) {
          setMarketCap(data[0]?.st_type || []);
          setIndustry(data[0]?.industry_name || []);
        } else {
          setMarketCap([]);
          setIndustry([]);
        }
      } catch (error) {
        console.error('Error fetching stock information:', error);
        setMarketCap([]);
        setIndustry([]);
      }
  
   
    }
   

    // console.log('industry',industry)
    // console.log('cappp',marketcap)
  // const showindus=()=>{

  //  return industry.map((val)=>{


  //   return(

  //       <option value={val} >{val}</option>

  //   )
      
  //   })
  // }
   

  // const showcap=()=>{

  //   return marketcap.map((val)=>{
 
 
  //    return(
 
  //        <option value={val} >{val}</option>
 
  //    )
       
  //    })
  //  }
    

  const settime=(value)=>{

    setTime(value)
  
  }
    
  


  



// handle error function 

const handleerror=(input,value)=>{

  setError((prev)=>({...prev,[input]:value}))
  
}


// Validation function 

const validation=()=>{
  
  var isvalid=true
  
  
  if(trandate=="")
  {
    handleerror("s_tran_date","Please Enter Transaction Date")
    isvalid=false
  }

  if(trantype=="")
  {
    handleerror("s_tran_type","Please Select Transaction Type")
    isvalid=false
  }


  if(quantity=="")
  {
    handleerror("s_qty","Please Enter Stock Quantity")
    isvalid=false
  }
  else if(isNaN(quantity))
  {
    handleerror("s_qty","Please Enter Stock Quantity (Only Number)")
    isvalid=false
  }

  if(time=="")
  {
    handleerror("time","Please Choose Date")
    isvalid=false
  }


  if(stockprice=="")
  {
    handleerror("s_price","Please Enter Stock Price")
    isvalid=false
  }
  else if(isNaN(stockprice))
  {
    handleerror("s_price","Please Enter Stock Price (Only Number)")
    isvalid=false
  }

  if(stockavgprice=="")
    {
      handleerror("sa_price","Please Enter Stock Avg. Price")
      isvalid=false
    }
    else if(isNaN(stockavgprice))
    {
      handleerror("sa_price","Please Enter Stock Avg. Price (Only Number)")
      isvalid=false
    }

  // if( brokerageinalltax =="")
  // {
  //   handleerror("s_brokerinalltax","Please Enter Stock Price Brokerage Inclusive all taxes")
  //   isvalid=false
  // }
  // else if(isNaN(brokerageinalltax))
  // {
  //   handleerror("s_brokerinalltax","Please Enter Brokerage Inclusive all taxes (Only Number)")
  //   isvalid=false

  // }
 

  if(stockid=="")
  {
    handleerror("s_name","Please Select Stock")
    isvalid=false
  }
 

  if(sttcharge=="")
  {
    handleerror("s_stt_charges","Please Enter STT Charges")
    isvalid=false
  }
  else if(isNaN(sttcharge))
  {
    handleerror("s_stt_charges","Please Enter STT Charges(Only Number)")
    isvalid=false
  }


  
  // if(sttstatus=="")
  // {
  //   handleerror("s_stt_status","Please Enter Cumulative Value")
  //   isvalid=false
  // }
  // else if(isNaN(sttstatus))
  // {
  //   handleerror("s_stt_status","Please Enter Cumulative Value (Only Number)")
  //   isvalid=false
  // }

  

  

  if(sabicharge=="")
  {
    handleerror("s_sabi_charges","Please Enter SEBI Charges")
    isvalid=false
  }
  else if(isNaN(sabicharge))
  {
    handleerror("s_sabi_charges","Please Enter SEBI Charges (Only Number)")
    isvalid=false
  }


  if(stampduty=="")
  {
    handleerror("s_stampduty_charges","Please Enter Stamp Duty")
    isvalid=false
  }
  else if(isNaN(stampduty))
  {
    handleerror("s_stampduty_charges","Please Enter Stamp Duty(Only Number)")
    isvalid=false
  }


  if(remark=="")
  {
    handleerror("s_remark","Please Enter Remark")
    isvalid=false
  }


  
  if(brokerage=="")
  {
    handleerror("s_brok_charges","Please Enter Brokerage Charges")
    isvalid=false
  }
  else if(isNaN(brokerage))
  {
    handleerror("s_brok_charges","Please Enter Brokerage Charges (Only Number)")
    isvalid=false
  }


  if(fundtype==='')
    {

      handleerror("s_fundtype","Please Select Fund Type")
      isvalid=false
    }
  

  // if(totalcharges=="")
  // {
  //   handleerror("s_t_charges","Please Enter Average Rate")
  //   isvalid=false
  // }
  // else if(isNaN(totalcharges))
  // {
  //   handleerror("s_t_charges","Please Enter Average Rate (Only Number)")
  //   isvalid=false
  // }

  
  if(broker == "")
  {

    handleerror("s_broker","Please Select Account")
    isvalid=false
    

  }

  if(industry=="")
   {
    handleerror("s_industry","Please Select Industry")
    isvalid=false
   }

   if(marketcap=="")
   {
    handleerror("s_market_cap","Please Select MarketCap")
    isvalid=false
   }

   if(dealtype=="")
   {
    handleerror("s_deal_type","Please Select Deal Type")
    isvalid=false
   }


  return isvalid

  
  }


  const  refreshPage=()=>{
  
    window.location.reload(false);
  
  }


// const sub=async()=>{


//   if(validation()){

//   if(trantype=="Sell")
//   { 
//     let formedata=new FormData()
//     formedata.append("b_admin_id",admindetail.user_id)
//     formedata.append('broker_id',broker)
//     formedata.append('st_name',stockid)
//     formedata.append('buy_sell',trantype)
//     var resulte = await axios.post(`${URL}stockInfo&bpuser=10891&action=checkTotalQty`,formedata)
   
//     if(resulte.data.res_code == 1)
//     {
      
//      if(quantity>resulte.data.data.availableQTY){
//       Swal.fire({
//         position: 'top-center',
//         icon: 'error',
//         title: 'Quantity is more then Available Quantity',
//         showConfirmButton: false,
//         timer: 1600
//       }).then((result) => {
       
      
//         window.location.reload(false);
       
//         })


//      }
//      else
//      {

//       Submit()
//      }
      
   
   
//     }



//   }
  


//   }



// }




  

  const Submit=async()=>{
   

    if(validation()){

    
       
        let formdata=new FormData()
        var body=[{ broker_charge:brokerage,buy_sell_date:`${trandate} ${time}`,editby:"",editdate:"",entrydate:"",profit_loss:"",st_deal_type:dealtype, st_lpt:"",st_name:stockid,st_price:stockprice,sta_price:stockavgprice,
        st_qty:quantity,
        st_remark:remark,
        st_trans_type:trantype,
        // st_type:marketcap,
        stamp_duty:stampduty,
        status:"",
        stt_paid:sttcharge,
        total_charge:"",
        trans_charge_sebi:sabicharge,
        fund_type:fundtype

        }]
       
        var fullformat=JSON.stringify({Tablename:'stockinfo_log', b_admin_id:admindetail.user_id,broker_id:broker,body:body})
        var result = await axios.post(`${URL}stockInfoexcel&bpuser=10891&action=stockDetailInsertCheck`,fullformat)

      if(result.data.res_code==1)
      {
        
       
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: 'Data Submitted Successfully',
          showConfirmButton: false,
          timer: 1600
        }).then((result) => {
         window.location.reload(false);
         
        })
     
     
      }
   
    }
    else
    {

    }

  }



   // .....................................

 useEffect(()=>{

  setBrokerid(props.body[0].brokerid)
  setBrokerName(props.body[0].brokername)
  setBrokerType(props.body[0].brokertype)


},[props])

// .....................................

 return (
          <div>

            <div className="row">


                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Action</label>
                          <select   onFocus={()=>setError('s_tran_type',null)}  onChange={(e)=>setTranType(e.target.value)}  className="form-control">
                           
                          <option value={null} >--Selecte Action--</option> 
                            <option value="Buy" >Buy</option>
                            <option value="Sell">Sell</option>
                        </select>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      
                        {error.s_tran_type} 
                    
                      </div>
                    </div>



                    <div className="col-sm-6">
                      
                      <div className="form-group">
                        <label>Transaction Date (DD/MM/YYYY)</label>
                        <input   onFocus={()=>setError('s_tran_date',null)}   onChange={(e)=>setTranDate(e.target.value)} type="date" className="form-control" placeholder="Enter ..."/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      
                      {error.s_tran_date} 
                     
                     </div>
                    </div>

                    
                   

                    </div>


                    <div className="row">

                    <div className="col-sm-6">
                          
                          <div className="form-group">
                            <label>Time</label>
                            <div class="cs-form">
                              <input  onFocus={()=>handleerror('time',null)} class="form-control"  type="time" step="1"  onChange={(e)=>settime(e.target.value)} />
                            </div>
                                                
                          </div>
    
                          <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                            {error.time}
                          </div>
    
                    </div>
                        

                    <div className="col-sm-6">
                        
                      <div className="form-group">
                        <label>Account</label>
                        <select   onFocus={()=>handleerror('s_broker',null)}   onChange={(e)=>setBroker(e.target.value)}  className="form-control">
                          <option value={null} >--Select Account--</option>
                          {showbroker()}
                        </select>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.s_broker}
                      </div>
  
                    </div>

                    <div className="col-sm-6">
                        
                      <div className="form-group">
                        <label>Fund Type</label>
                        <select   onFocus={()=>handleerror('s_fundtype',null)}   onChange={(e)=>setFundtype(e.target.value)}  className="form-control">
                          <option value={''} >--Select Fund Type--</option>
                          <option value={'NML'} >NML</option>
                          <option value={'MTF'} >MTF</option>
                          
                        </select>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.s_fundtype}
                      </div>
  
                    </div>
                      


                    <div className="col-sm-6">
                        
                        <div className="form-group">
                          
                          <label>Stock</label>
                          <select   onFocus={()=>handleerror('s_name',null)}   onChange={(e)=>setStock(e.target.value)}  className="form-control">
                            <option value={null} >--Select Stock--</option>
                            {showstocklist()}
                          </select>
                       
                        </div>

                        <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                          {error.s_name}
                        </div>
  
                    </div>






                </div>



                    <div className="row">

                    <div className="col-sm-6"> 

                    <div className="form-group">

                    <label>Industry Type</label>
                    <select className="form-control">

                    {/* {showindus()} */}
                    <option value={industry} >{industry}</option>
                    {/* <option value={null} >--Select Industry--</option>
                    <option value="Software & IT Services" >Software & IT Services</option>
                    <option value="Machinery, Equipment & Components" >Machinery, Equipment & Components</option>
                    <option value="Paper & Forest Products" >Paper & Forest Products</option>
                    <option value="Construction & Engineering">Construction & Engineering</option>
                    <option value="Automobiles & Auto Parts">Automobiles & Auto Parts</option>
                    <option value="Oil & Gas">Oil & Gas</option>
                    <option value="Aerospace & Defense">Aerospace & Defense</option>
                    <option value="Banking Services">Banking Services</option>
                    <option value={'Chemicals'}>Chemicals</option>
                    <option value={'Food & Tobacco'}>Food & Tobacco</option> */}
                    </select>
                    </div>

                    <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                    
                     {error.s_industry}
                    
                    </div>

                    </div>


                    <div className="col-sm-6">

                      <div className="form-group">
                      <label>Market Cap</label>
                      <select    className="form-control">

                       {/* {showcap()} */}
                       <option value={marketcap} >{marketcap}</option>
                      {/* <option value={null} >Select Market Cap</option> */}
                      {/*<option value="Micro" >Micro</option>
                      <option value="Small" >Small</option>
                      <option value="Middle" >Middle</option>
                      <option value="Large" >Large</option> */}
                      </select>
                      </div>
                        <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.s_market_cap}
                        </div>

                    </div>



                  

                    

                   

                
                </div>

                  
                   <div className="row">

                        <div className="col-sm-6">
                        
                          <div className="form-group">
                            <label>Deal Type</label>
                              <select onFocus={()=>handleerror('s_deal_type',null)}   onChange={(e)=>setDealType(e.target.value)} className="form-control">
                              <option value={null} >-- Deal Type --</option>
                              <option value="Indtraday" >Indtraday</option>
                              <option value="Delivery" > Delivery</option>
                              
                            </select>
                          </div>
                          <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                            {error.s_deal_type}
                          </div>
                        
                        </div>


                      
                        

                  <div className="col-sm-6">
                     
                     <div className="form-group">
                       <label>Quantity</label>
                       <input  onFocus={()=>setError('s_qty',null)}   onChange={(e)=>setQuantity(e.target.value)}   type="text" className="form-control" placeholder="Enter ..."/>
                     </div>
                     <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                     
                       {error.s_qty} 
                    
                     </div>
                  </div>
                  
                  
                  </div>
                  
                    <div className="row">


                  

                   <div className="col-sm-6">
                      
                      <div className="form-group">
                        <label>Avg. Price</label>
                        <input onFocus={()=>setError('sa_price',null)}   onChange={(e)=>setStocAvgPrice(e.target.value)}   type="text" className="form-control" placeholder="Enter ..."/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      
                        {error.sa_price} 
                   
                      </div>
                      
                    </div>


                    <div className="col-sm-6">
                      
                      <div className="form-group">
                        <label> Price</label>
                        <input onFocus={()=>setError('s_price',null)}   onChange={(e)=>setStockPrice(e.target.value)}   type="text" className="form-control" placeholder="Enter ..."/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      
                        {error.s_price} 
                   
                      </div>

                    </div>


                    

                    
                    
                    
                   


                   
                  </div>
                  
                  <div className="row">



                  <div className="col-sm-6">
                      <div className="form-group">
                        <label>Brokerage</label>
                        <input  onFocus={()=>setError('s_brok_charges',null)} onChange={(e)=>setBrokerage(e.target.value)}  type="text" className="form-control" placeholder="Enter ..."/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      
                      {error.s_brok_charges} 
                     
                     </div>
                    </div>




                   <div className="col-sm-6">
                      <div className="form-group">
                        <label>SEBI Charge</label>
                        <input  onFocus={()=>setError('s_sabi_charges',null)}  onChange={(e)=>setSabiCharge(e.target.value)}   type="text" className="form-control" placeholder="Enter ..."/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      
                        {error.s_sabi_charges} 
                 
                       </div>
                    </div>

                  


                    
                   
                    

                    

                  </div>

                  <div className="row">

                  <div className="col-sm-6">
                     
                    <div className="form-group">
                      <label>Stamp Duty</label>
                      <input  onFocus={()=>setError('s_stampduty_charges ',null)}   onChange={(e)=>setStampDuty(e.target.value)} type="text" className="form-control" placeholder="Enter ..."/>
                    </div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                    
                      {error.s_stampduty_charges} 
                
                    </div>
                      
                  </div>

                    
                   
                  <div className="col-sm-6">
                     
                     <div className="form-group">
                       <label>STT Charge</label>
                       <input  onFocus={()=>setError('s_stt_charges',null)}  onChange={(e)=>setSttCharge(e.target.value)}  type="text" className="form-control" placeholder="Enter ..."/>
                     </div>
                     <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                       
                       {error.s_stt_charges} 
                 
                     </div>
                   
                    </div>

                    {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label>Cumulative Value</label>
                        <input  onFocus={()=>setError('s_stt_status',null)}  onChange={(e)=>setSttStatus(e.target.value)} type="text" className="form-control" placeholder="Enter ..."/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      
                        {error.s_stt_status} 
                 
                       </div>
                  
                  </div> */}

                    

                   
                   
                  </div>


                  <div className="row">

                 
                    
                  {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label>Average Rate</label>
                        <input  onFocus={()=>setError('s_t_charges',null)} onChange={(e)=>setTotalCharges(e.target.value)}  type="text" className="form-control" placeholder="Enter ..."/>
                      </div> 
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      
                       {error.s_t_charges} 
                 
                      </div>
                   
                    </div>
                     */}
                  
                  </div>


                  
                   <div className="row">

                    <div className="col-sm-12">
                    
                      <div className="form-group">
                        <label>Remarks</label>
                        <textarea  onFocus={()=>setError('s_remark',null)}  onChange={(e)=>setRemark(e.target.value)} className="form-control" rows="3" placeholder="Enter ..."></textarea>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      
                      {error.s_remark} 
                 
                    </div>
                    </div>
                   
                  </div>
                
                <div className="card-footer text-center">
                  <button onClick={()=>Submit()}  type="button" className="btn btn-primary">Save changes</button>
                </div>
    
  </div>
  )
}
