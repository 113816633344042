import React from 'react'
import './App.css';
import { BrowserRouter as Router,Route, Routes  } from "react-router-dom";

import Login from './Login';
import Dashboard from './Dashboard';
import Report from './reports/Report';
import Addstock from './Addstock';
import Funds from './rkp/Funds';
import ViewStatement from './rkp/ViewStatement';
import Registration from './Registration';
import AddBroker from './AddBroker';
import ProtectedRoutes from './protect/protectedroutes';
import Masterstock from './masterstock';
import Newsfeed from './rkp/Newsfeed';

import { Piechart } from './rkp/Googlechart';
import ExcelUpload from './Excel'

// ............................
import Holdingreport from './rkp/currentHoldingforReport';
import Transaction from './rkp/transactionhistoryforReport';
import Showchartforreport from './rkp/chartforReport';
import Showcapforreport from './rkp/capforReport';
import Profile from './Profile';
// import FormBuilder from './builder';
import DraggableComponent from './builder';
import StockAlert from './rkp/StockAlert';
import Mis from './rkp/mis';

import DarkMode from '../src/components/darkmode'
import ApexChart from './rkp/chart';
import Ltpfeed from './rkp/Ltpfeed';

// .................................

export default function Menu() {

  return (
  
    <Router>
     
      <Routes>
        
        <Route  path="/" element={<Login/>}  />
        
       
        <Route  path="/" element={<ProtectedRoutes/>}  >
         
          <Route path="/Registration" element={<Registration/>} />
          <Route path="/Dashboard" element={<Dashboard/>} />
          <Route path="/Report" element={ <Report/>}  />
          <Route path="/Funds" element={ <Funds/> }  />
          <Route path="/ViewStatement" element={ <ViewStatement/>}  />
          <Route path="/Addstock" element={ <Addstock/>}   />
          <Route path="/AddBroker" element={  <AddBroker/>}   />
          <Route path="/Chart" element={  <Piechart/>   }   />
          <Route path="/Master" element={<Masterstock/>} />
          {/* <Route path="/excel" element={  <ExcelUpload/>   }   /> */}
          <Route path="/excel" element={  <ExcelUpload/>   }   />
         
          <Route path="/holding" element={  <Holdingreport/>   }   />
          <Route path="/transaction" element={  <Transaction/>   }   />
          {/* <Route path="/charting" element={<Showchartforreport/>} /> */}
          <Route path="/charting" element={  <Showchartforreport/>   }   />
          <Route path="/cap" element={  <Showcapforreport/>   }   />
          <Route path="/profile" element={  <Profile/>   }   />
          <Route path="/builder" element={<DraggableComponent/>}/>
          <Route path="/StockAlert" element={<StockAlert/>}/>
          <Route path="/mis" element={<Mis/>}/>
          <Route path="/mode" element={<DarkMode/>}/>
          <Route path="/news" element={<Newsfeed/>}/>
          <Route path="/ltpfeed" element={<Ltpfeed/>}/>
          <Route path="/charts" element={<ApexChart/>} />
          
        </Route>
 
 
      </Routes>
              
    </Router>
  
  )
}



