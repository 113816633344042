import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import { useState} from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { URL } from './service/url'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { JSONURL } from './service/url'
// import inddustrytype from "./industrytype.json"



export default function Addstock(props) {

  
const dispatch=useDispatch()

const admin=JSON.parse(localStorage.getItem('admindata'))
const navigate=useNavigate()

const [brokerdata,setBrokerdata]=useState([])



const [trandate,setTranDate]=useState('')
const [brokerid,setBrokerid]=useState('')
const [stockid,setStockId]=useState('')

const [industry,setIndustry]=useState('')
const [marketcap,setMarketCap]=useState('')


const [amount,setAmount]=useState('')












const [error,setError]=useState({})

const Swal = require('sweetalert2')
const [brokername,setBrokerName]=useState()
const [ave,setAverage]=useState('')
const [industrylist,setIndustryList]=useState([])
const [newindustry,setNewIndustry]=useState('')
const [stocklist,setStockList]=useState([])



const admindetail=JSON.parse(localStorage.getItem('admindata'))


 // ...............api get broker 

 const getbroke=async()=>{
  var formdata=new FormData()
  formdata.append('admin_id',admindetail.user_id) 
  const result= await axios.post(`${URL}brokerinfo&bpuser=10891&action=getbrokers`,formdata)
  setBrokerdata(result.data.data)
  
  }

 useEffect(()=>{
  getbroke()
 })



  const getstocklist = async (broker)=>{
    
  
    var fordata = new FormData()

     fordata.append('broker_id',broker)
     fordata.append('buy_sell','Sell')
     fordata.append('admin_id',admindetail.user_id)
 
     var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=getBrokerwiseStock`,fordata)
 
     if(result.data.data)
     {
       setStockList(result.data.data)
     }

   
 }

   useEffect(()=>{

     getstocklist(brokerid)

   },[brokerid])



   const showstocklist=()=>{

    return stocklist.map((val)=>{

      return (
       
        <option value={val.StockName} >{val.StockName}</option>
     
        )


    })

  }


  const fetchindustry=async()=>{
    const formdata=new FormData()
    formdata.append('admin_id',admindetail.user_id)
    const response = await axios.post(`${URL}stockInfo&bpuser=10891&action=getIndustry`,formdata);
    const industry =  response.data.data
    setIndustryList(industry)
  };

  useEffect(()=>{
    
    
      fetchindustry()
    
  },[])





const showbroker=()=>{
return  brokerdata.map((val)=>{
  return (
          
        <>
         <option value={val.user_id} >{val.user_name}</option>

        </>

      )})}


    const setstock=async(value)=>{
      setStockId(value)
      let formdat=new FormData()
     formdat.append('stock_name',value)
     formdat.append('broker_id',brokerid)
     formdat.append('admin_id',admindetail.user_id)

     var result = await axios.post(`${URL}stockInfoexcel&bpuser=10891&action=stockCheckExits`,formdat)
     setMarketCap(result.data.data[0].st_type)
     setIndustry(result.data.data[0].industry_name)

    }


    // console.log("marketcap",marketcap)
    // console.log("industrytype",industry)
// const showidustry=()=>{


// return industrylist.map((val)=>{

//   return (
  
//     <>
//       <option value={val.industry_name} >{val.industry_name}</option>
//     </>
 

//   )})}



// console.log('dataaa',trandate,trantype,quantity,stockprice,stockname,industry,marketcap,brokerage,sttcharge,sabicharge,remark,newindustry,totalcharges)


// handle error function 

const handleerror=(input,value)=>{


  setError((prev)=>({...prev,[input]:value}))
  
  }


// const stockset=async(value)=>{
  
//  setStockId(value)

// }


 

// Validation function 

const validation=()=>{
  
  var isvalid=true
  
  if(trandate=="")
  {
    handleerror("s_tran_date","Please Enter Transaction Date")
    isvalid=false
  }

  if(brokerid=="")
  {
    handleerror("s_broker_id","Please Select Account")
    isvalid=false
  }


  if(stockid=="")
  {
    handleerror("stock_id","Please Select Stock")
    isvalid=false
  }


  if(amount=="")
  {
    handleerror("s_amount","Please Enter Dividend  Amount")
    isvalid=false
  }
  else if(isNaN(amount))
  {
    handleerror("s_amount","Please Enter Dividend Amount (Only in digit)")
    isvalid=false

  }

  return isvalid
  
  }


const Submit=()=>{


  if(validation())
  {

    



  }
 



}



const  refreshPage=()=>{
  
  window.location.reload(false);

}


const brokerrr=(bid,bnm)=>{

  var body={bro_id:bid,bro_name:bnm}
  var bi ='broker'

  dispatch({type:'brokeridset',payload:[bi,body]})
  
  // setBrokerid(bid)
  // setBrokerName(bnm)
 
}

// var bro  = useSelector((state)=>state.broker) 

// useEffect(()=>{

// if(!bro.broker)
// {
//   setBrokerid(null)
  
// }
// else
// {
//   setBrokerid(bro.broker.bro_id)
//   setBrokerName(bro.broker.bro_name)
 
// }
  
// },[bro.broker])






// const [excelfile,setExcelFile]=useState([])

// const [button,setButton]=useState(false)


// [0]..........................................................................excel function 


// const handleFileChange=async(e)=>{

 
//   let file = e.target.files[0]
//   let data= await file.arrayBuffer()
//   let excelfile=xlsx.read(data)
//   let excelsheet=excelfile.Sheets[excelfile.SheetNames[0]];
//   let exceljson=xlsx.utils.sheet_to_json(excelsheet)
  
//   setExcelFile(exceljson)
//   if(exceljson){
//     setButton(true)
//   }
//   else{
//     setButton(false)
//   }


  
// }






// const handledata=(event,index)=>{
    
    
//   let newform=[...excelfile]
//   newform[index][event.target.name]=event.target.value
//   setExcelFile(newform)


// } 


// [0]..........................................................................excel function 


// console.log('s_averagerate',ave)

// console.log('quantity',quantity)




// const addindustry=async(e)=>{
// e.preventDefault();
// if(newindustry!==''){
//   const formdata=new FormData()
//   formdata.append("industry_name",newindustry)
//   formdata.append("admin_id",admindetail.user_id)
//   const response = await axios.post(`${URL}stockInfo&bpuser=10891&action=industryAdd`,formdata)
// if(response.data){

//   Swal.fire({
//     position: 'top-center',
//     icon: 'success',
//     title: 'Industry Add Successfully',
//     showConfirmButton: false,
//     timer: 1600
//   }).then((result) => {
   
  
//     window.location.reload(false);

   
//     })
 
// }
// }
// else
// {

//   Swal.fire({
//     position: 'top-center',
//     icon: 'error',
//     title: 'Please Enter Industry Type',
//     showConfirmButton: true,
    
//   })
 
// }
 
// };


  return (
    <>
     <div className="wrapper">
    <Header   refresh={refreshPage}   brokerr={brokerrr} />
    <div className="content-wrapper">
    <section className="content-header">
      <div className="container-fluid">
     
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1>Add Dividend </h1>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="/Dashboard">Home</a></li>
              <li className="breadcrumb-item active">Add Stock</li>
            </ol>
          </div>
        </div>
       

      </div>
    </section>
    <section className="content">
      <div className="container-fluid">
        
    <div className='row'>
    <div className="col-sm-6">
 <div className="card card-default" >
         <div class="card-body">


         

                  <div className="row"  >

                    <div className="col-sm-6">
                      
                      <div className="form-group">
                        
                        <label>DATE  &nbsp;(DD/MM/YYYY)</label>
                        <input  onFocus={()=>handleerror('s_tran_date',null)}   onChange={(e)=>setTranDate(e.target.value)}  type="date" className="form-control" placeholder="Enter ..."/>
                     
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      
                       {error.s_tran_date} 
                      
                      </div>
                     
                    </div>

                    
                    
                    
                  </div>

                  <div className="row">

                    <div className="col-sm-6">
                        
                      <div className="form-group">
                        <label>ACCOUNT</label>
                        <select   onFocus={()=>handleerror('s_broker_id',null)}   onChange={(e)=>setBrokerid(e.target.value)}  className="form-control">
                          <option >-- Select Account --</option>
                          {showbroker()}
                        </select>
                    
                      </div>
                      
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.s_broker_id}
                      </div>

                    </div>

                  </div>

                <div className='row'>

                  <div className="col-sm-6">
                    
                    <div className="form-group">
                      <label>STOCK</label>
                        <select onFocus={()=>handleerror('stock_id',null)}   onChange={(e)=>setstock(e.target.value)} className="form-control">
                        <option value="Micro" >-- Select Stock --</option>
                        {showstocklist()}
                      </select>
                    </div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                      {error.stock_id}
                    </div>
                  
                  </div>


                </div>




                  
                  <div className="row">

                   <div className="col-sm-6">
                      <div className="form-group">
                        <label>AMOUNT</label>
                        <input   onFocus={()=>handleerror('s_amount',null)}  onChange={(e)=>setAmount(e.target.value)}  type="text" className="form-control" placeholder="Enter Stock Dividend"/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.s_amount}
                      </div>
                    </div>

                    
                  </div>


                   
                    
                  
                  
                  

                  {/* <div className="row">
                    <div className="col-sm-6">
                     
                      <div className="form-group">
                        <label>STT CHARGES</label>
                        <input  onFocus={()=>handleerror('s_stt_charges',null)}  onChange={(e)=>setStockvalue(e.target.value)} type="text" className="form-control" placeholder="Enter STT Charges"/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.s_stt_charges}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>SEBI CHARGES</label>
                        <input  onFocus={()=>handleerror('s_sabi_charges',null)}   onChange={(e)=>setNetQty(e.target.value)} type="text" className="form-control" placeholder="Enter SEBI Charges"/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.s_sabi_charges}
                      </div>
                    </div>
                  </div> */}
{/* 
                  <div className="row">
                    <div className="col-sm-6">
                     
                      <div className="form-group">
                        <label>STAMP DUTY</label>
                        <input   onFocus={()=>handleerror('s_stampduty_charges',null)}  onChange={(e)=>setCumulativeQty(e.target.value)} type="text" className="form-control" placeholder="Enter Stam Duty"/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.s_stampduty_charges}
                      </div>
                    
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>BROKERAGE CHARGES</label>
                        <input  onFocus={()=>handleerror('s_brok_charges',null)}  onChange={(e)=>setNetValue(e.target.value)}  type="text" className="form-control" placeholder="Enter Brokerage Charges"/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.s_brok_charges}
                      </div>
                    </div>

                    
                  </div> */}

                  {/* <div className="row">
                  
                   <div className="col-sm-6">
                      <div className="form-group">
                        <label>Cumulative Value</label>
                        <input  onFocus={()=>handleerror('s_t_charges',null)}  onChange={(e)=>setCumulativevalue(e.target.value)}  type="text" className="form-control" placeholder="Enter ..."/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.s_t_charges}
                      </div>
                    </div>
                    
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Average Rate</label>
                        <input  onFocus={()=>handleerror('s_averagerate',null)}  onChange={(e)=>setAverage(e.target.value)}  type="text" className="form-control" placeholder="Enter ..."/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.s_averagerate}
                      </div>
                    </div>
                    
                  </div> */}

                  {/* <div className="row">
                    
                  </div> */}
                  
                  {/* <div className="row"> */}
                    {/* <div className="col-sm-12">
                    
                      <div className="form-group">
                        <label>Remarks</label>
                        <textarea  onFocus={()=>handleerror('s_remark',null)}  onChange={(e)=>setRemark(e.target.value)}  className="form-control" rows="3" placeholder="Enter Remarks"></textarea>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.s_remark}
                      </div>
                    
                    </div> */}
                    {/* <div className="col-sm-12">
                  
                      <div class="mb-3">
                        <label for="formFileSm" class="form-label">Excel Sheet </label>
                        <input   onChange={(e)=>handleFileChange(e)}  class="form-control" id="formFileMd" type="file" />
                      </div> */}
                     
                     {/* { button?<>
                      <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
                          Preview
                      </button>
                     </>:<>
                        <div style={{color:'red'}}>
                         Please Choose..  files
                         </div>
                     </>


                      }                      
                    </div> */}



                    

                   
                  {/* </div> */}

                  



                </div>


                <div className="card-footer">
                  <button  onClick={()=>Submit()}    type="button" className="btn btn-block btn-primary" style={{width:'200px', float:'none', margin:'0 auto' }}>Submit</button>
              </div>
         </div>
        </div>

    </div>
    </div>
    </section>
    </div>
    {/* ............... */}
    <div>

      <div  class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div    class="modal-dialog modal-xl" role="document">
          <div  class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Stock Sheet</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div  style={{overflowX:'auto',overflowY:'auto',maxHeight:'70vh'}}>
            {/* ............. */}
            {/* <table style={{border: "1px solid black" ,borderCollapse: "collapse",width:'100%',height:'100%',paddingLeft:'5px'}}>
              <thead >
                <tr>
                <th style={{border: "1px solid black" ,borderCollapse: "collapse",alignSelf:'center'}} >S.No.</th>
                <th style={{border: "1px solid black" ,borderCollapse: "collapse",alignSelf:'center'}} >DATE</th>
                <th style={{border: "1px solid black" ,borderCollapse: "collapse"}} >STOCK</th>
                <th style={{border: "1px solid black" ,borderCollapse: "collapse"}} >BUY_QTY</th>
                <th style={{border: "1px solid black" ,borderCollapse: "collapse"}} >BUY_AVARAGE</th>
                <th style={{border: "1px solid black" ,borderCollapse: "collapse"}} >BUY_AMOUNT</th>
                <th style={{border: "1px solid black" ,borderCollapse: "collapse"}} >SELL_QTY</th>
                <th style={{border: "1px solid black" ,borderCollapse: "collapse"}} >SELL_AVARAGE</th>
                <th style={{border: "1px solid black" ,borderCollapse: "collapse"}} >SELL_AMOUNT</th>
                <th style={{border: "1px solid black" ,borderCollapse: "collapse"}} >NET_QTY</th>
                <th style={{border: "1px solid black" ,borderCollapse: "collapse"}} >NET_AMOUNT</th>  

               
                </tr>
              </thead>
              <tbody>
                {
                  excelfile.map((val,index)=>{
                    return (
                      <tr>
                        <td style={{border: "1px solid black" ,borderCollapse: "collapse"}}>

                          {index+1}
                        </td>
                        
                        <td style={{border: "1px solid black" ,borderCollapse: "collapse"}}>

                          <input type='date'  value={val.DATE}/>
                        
                        </td>

                        <td style={{border: "1px solid black" ,borderCollapse: "collapse"}}>

                          <input name={'STOCK'} onChange={(event)=>handledata(event,index)} type='input' defaultValue={val.STOCK}/>
                        </td>

                        <td style={{border: "1px solid black" ,borderCollapse: "collapse"}}>

                          <input name={'BUY_QTY'} onChange={(event)=>handledata(event,index)} type='input' defaultValue={val.BUY_QTY}/>
                        </td>

                        <td style={{border: "1px solid black" ,borderCollapse: "collapse"}}>

                          <input  name={'B_AVG'} onChange={(event)=>handledata(event,index)} type='input' defaultValue={val.B_AVG}/>
                        </td>

                        <td style={{border: "1px solid black" ,borderCollapse: "collapse"}}>

                          <input name={'B_AMOUNT'} onChange={(event)=>handledata(event,index)} type='input' defaultValue={val.B_AMOUNT}/>
                        </td>

                        <td style={{border: "1px solid black" ,borderCollapse: "collapse"}}>

                          <input name={'SELL_QTY'} onChange={(event)=>handledata(event,index)} type='input' defaultValue={val.SELL_QTY}/>
                        </td>

                        <td style={{border: "1px solid black" ,borderCollapse: "collapse"}}>

                          <input name={'S_AVG'} onChange={(event)=>handledata(event,index)} type='input' defaultValue={val.S_AVG}/>
                        </td>

                        <td style={{border: "1px solid black" ,borderCollapse: "collapse"}}>

                          <input name={'S_AMOUNT'} onChange={(event)=>handledata(event,index)} type='input' defaultValue={val.S_AMOUNT}/>
                        </td>

                        <td style={{border: "1px solid black" ,borderCollapse: "collapse"}}>

                          <input name={'NET_QTY'} onChange={(event)=>handledata(event,index)} type='input' defaultValue={val.NET_QTY}/>
                        </td>

                        <td style={{border: "1px solid black" ,borderCollapse: "collapse"}}>

                          <input name={'NET_AMOUNT'}  onChange={(event)=>handledata(event,index)} type='input' defaultValue={val.NET_AMOUNT}/>
                        </td>
                       
                        
                      </tr>
                    )
                  })
                }
              </tbody>
            </table> */}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
             
            </div>
          </div>
        </div>
      </div>
      
    </div>
    {/* ......................... */}
    <Footer/>
    </div>
    </>
  )
}

