import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
//import { createRoot } from 'react-dom/client';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import axios from 'axios'
import { URL } from './service/url';
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'


export default function CurrentHoldingMultipleScriptAccount() {


  const dispatch=useDispatch()
  const [rowData, setRowData] = useState();

  const columnDefs = useMemo(() => [
        { field: 'SNO', width:100, },
        { field: 'ACCOUNT_NAME', width:100,},
        { field: 'STOCKS',width:100,},
        { field: 'QTY',width:100,},
        { field: 'RATE',width:100,},
        // { field: 'CMP',width:100,},
        { field: 'HOLDING',width:100,},
        // { field: 'MARKET VALUE',width:100,},
        // { field: 'UNREALISED',width:100,},
        // { field: 'REALISED',width:100,}
    ], []);
    
    
    const defaultColDef = useMemo(() => ({
      resizable: true,
      sortable: true,
      flex:1
    }), []);
    

    
    // useEffect(() => {
    //   fetch('/stock_trading_api/currentholdingmultiplescriptaccount.json')
    //       .then(result => result.json())
    //       .then(rowData => setRowData(rowData))
    //       .catch(error => console.error('Error fetching data:', error));
    // }, []);



    // var sto  = useSelector((state)=>state.broker) 

    // console.log("stock_Multiple_single",sto.stockname.stockname_name)



var sto  = useSelector((state)=>state.broker ) 



useEffect(()=>{

  // alert('hello....')

},[sto])



// const update=async()=>{

  
//   var formdata= new FormData()
//   formdata.append('stock_name',sto.stockname.stockname_name)
//   var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=multipleScriptStock`,formdata)
//   setRowData(result.data.data)


// }








  
  // return (
    
  //   <div className="ag-theme-alpine" style={{width: '100%', height: 250}}>
  //     <AgGridReact
  //             className="ag-theme-alpine"
  //             animateRows="true"
  //             columnDefs={columnDefs}
  //             defaultColDef={defaultColDef}
  //             enableRangeSelection="true"
  //             rowData={rowData}
  //             rowSelection="multiple"
  //             suppressRowClickSelection="true"
  //             pagination={true}
  //         />
  //  </div>
  
  // )



}

