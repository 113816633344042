import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
import { AgGridReact } from 'ag-grid-react'; 
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'; 
import 'ag-grid-community/styles/ag-theme-alpine.css'; 
import mqtt from 'mqtt'

import { useSelector } from 'react-redux';


const DarkMode = () => {


    const admindetail=JSON.parse(localStorage.getItem('admindata')); 

    const gridRef = useRef();

    const MQTT_HOST = 'wss://test.mosquitto.org:8081';

    const MQTT_TOPIC = 'testtopic/amitjha/244';

    const [mqttClient, setMqttClient] = useState(null);

    const [mqttdata,setMqttData]=useState([]);

    const [brokerid,setBrokerid]=useState(null);

    var bro  = useSelector((state)=>state.broker) 

    useEffect(()=>{
    
    if(!bro.broker)
    {
      setBrokerid(null)
      
    }
    else
    {
      setBrokerid(bro.broker.bro_id)
     
    }
      
    },[bro.broker])
    


  
    const [columnDefs, setColumnDefs] = useState([
        { field: 'SNO', 
          resizable: true ,   
          suppressSizeToFit: true ,
          minWidth:80,
          cellStyle: { textAlign: 'center' }
    
         
        },
        { field: 'STOCKS', 
          resizable: true ,   
          suppressSizeToFit: true ,
          minWidth:200,
          cellStyle: { textAlign: 'center' }
         
        },
          { field: 'INDUSTRY', 
          resizable: true ,
          suppressSizeToFit: true , 
          filter:true,
          minWidth:200,
          cellStyle: { textAlign: 'center' }
        },
          { field: 'MARKET_CAP', 
          resizable: true ,
          suppressSizeToFit: true , 
          minWidth:150,
          cellStyle: { textAlign: 'center' }
    
        },
          { field: 'QTY', 
          resizable: true ,
          suppressSizeToFit: true ,
          minWidth:100,
        },
          { field: 'CURRENT_MARKET_PRICE', 
          resizable: true ,
          suppressSizeToFit: true , 
          filter:true ,
          minWidth:200,
        },
        { field: 'INVESTED_HOLDING',
          resizable: true , 
          suppressSizeToFit: true ,
          minWidth:200,
        },
          { field: 'CURRENT_HOLDING', 
          resizable: true , 
          suppressSizeToFit: true ,
          minWidth:180, 
        },
          { field: 'AVG_VALUE', 
          resizable: true , 
          suppressSizeToFit: true ,
          minWidth:120, 
        },
          { field: 'UNREALISED', 
          resizable: true ,  
          suppressSizeToFit: true ,
          minWidth:150,
        },
          { field: 'REALISED',
           resizable: true,
           suppressSizeToFit: true ,
           minWidth:150, 
        },
        { field: 'PER_GAIN',
           resizable: true,
           suppressSizeToFit: true ,
           minWidth:150, 
        },
        { field: 'lastPrice',
           resizable: true,
           suppressSizeToFit: true ,
           minWidth:150, 
        },
        { field: 'Unrealized',
           resizable: true,
           suppressSizeToFit: true ,
           minWidth:150, 
        }
        ,
        { field: 'CurrentHolding',
           resizable: true,
           suppressSizeToFit: true ,
           minWidth:150, 
        }
      
      
      ]);


      const defaultColDef = useMemo(() => ({

        resizable: true,
        sortable: true,
        filter:true,
        floatingFilter:true,
        cellRenderer:false,
        flex:1,
        maxWidth:300,
        suppressSizeToFit: true,
      
        
      
    }), []);

    
 

   const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 250,
    };
  }, []);
  
  
      
      

  
useEffect(() => {
    const client = mqtt.connect(MQTT_HOST);
  
    client.on('connect', () => {
      setMqttClient(client);
      client.subscribe(MQTT_TOPIC);
    });
  
    return () => {
      client.end(); 
    };
  }, []);
  

  useEffect(() => {
    const handleMessage = (topic, message) => {
      const parsedData = JSON.parse(message.toString());
      console.log('Data received from MQTT:', parsedData);
      setMqttData(parsedData);
    };

    if (mqttClient) {
      mqttClient.on('message', handleMessage);
    }

    return () => {
      if (mqttClient) {
        mqttClient.off('message', handleMessage); 
      }
    };
  }, [mqttClient]);


  // mqtt...code .................................

//   useEffect(() => {
//     const fetchData = async () => {
//         try {
//             const response = await fetch('http://192.168.2.2/raj/NewMqttPhp/stockMqtt.php');
//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }
//             const data = await response.json();
//             console.log('Data received from URL:', data);
//             setMqttData(data);
//         } catch (error) {
//             console.error('Error fetching data from URL:', error);
//         }
//     };

//     const intervalId = setInterval(fetchData, 10000); // Fetch data every 10 seconds

//     return () => clearInterval(intervalId);
// }, []);


    return (
        <>

            <div className="card-body">


                <div className="ag-theme-alpine" style={{width: '100%', height: 800}}>

                    <AgGridReact 
                        
                        ref={gridRef} 
                        rowData={mqttdata} 
                        columnDefs={columnDefs} 
                        defaultColDef={defaultColDef} 
                        enableRangeSelection={true}
                        enableCharts={true}
                        animateRows={true} 
                        rowSelection='multiple' 
                        pagination={true}
                        onGridReady={mqttdata}
                        autoGroupColumnDef={autoGroupColumnDef}
                        sideBar={'columns'}
                        pivotMode={true}
                    
                    />
                </div>    

            </div>

        </>
        
    );
};

export default DarkMode;
// DarkMode.js

// import React, { useCallback, useMemo, useState, useEffect } from "react";
// import { createRoot } from "react-dom/client";
// import { AgGridReact } from "@ag-grid-community/react";
// import "@ag-grid-community/styles/ag-grid.css";
// import "@ag-grid-community/styles/ag-theme-alpine.css"; // Replace with your preferred Ag-Grid theme
// import "./dark-mode.css"; // Your dark mode styles
// import "./styles.css";
// import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
// import { ModuleRegistry } from "@ag-grid-community/core";

// ModuleRegistry.registerModules([ClientSideRowModelModule]);

// const formatNumber = (number) => {
//   return Math.floor(number).toLocaleString();
// };

// const createRowData = () => {
//   var rowData = [];
//   for (var i = 0; i < 20; i++) {
//     rowData.push({
//       a: Math.floor(((i + 323) * 145045) % 10000),
//       b: Math.floor(((i + 323) * 543020) % 10000),
//       c: Math.floor(((i + 323) * 305920) % 10000),
//       d: Math.floor(((i + 323) * 204950) % 10000),
//       e: Math.floor(((i + 323) * 103059) % 10000),
//       f: Math.floor(((i + 323) * 468276) % 10000),
//     });
//   }
//   return rowData;
// };

// const DarkMode = () => {
//   const [darkMode, setDarkMode] = useState(false); // State to toggle dark mode
//   const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
//   const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
//   const [rowData, setRowData] = useState(createRowData());
//   const [columnDefs, setColumnDefs] = useState([
//     { field: "a", enableCellChangeFlash: true },
//     { field: "b", enableCellChangeFlash: true },
//     { field: "c", cellRenderer: "agAnimateShowChangeCellRenderer" },
//     { field: "d", cellRenderer: "agAnimateShowChangeCellRenderer" },
//     { field: "e", cellRenderer: "agAnimateSlideCellRenderer" },
//     { field: "f", cellRenderer: "agAnimateSlideCellRenderer" },
//   ]);

//   const defaultColDef = useMemo(() => {
//     return {
//       flex: 1,
//       cellClass: "align-right",
//       valueFormatter: (params) => {
//         return formatNumber(params.value);
//       },
//     };
//   }, []);

//   const onGridReady = useCallback((params) => {
//     const updateValues = () => {
//       var rowCount = params.api.getDisplayedRowCount();
//       for (var i = 0; i < 2; i++) {
//         var row = Math.floor(Math.random() * rowCount);
//         var rowNode = params.api.getDisplayedRowAtIndex(row);
//         var col = ["a", "b", "c", "d", "e", "f"][Math.floor(Math.random() * 6)];
//         rowNode.setDataValue(col, Math.floor(Math.random() * 10000));
//       }
//     };
//     setInterval(updateValues, 250);
//   }, []);

//   // Effect to check URL parameter on mount
//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     const mode = urlParams.get("mode");
//     if (mode === "dark") {
//       setDarkMode(true);
//     }
//   }, []);

//   return (
//     <div className={darkMode ? "dark-mode" : ""} style={containerStyle}>
//       <div style={gridStyle} className={"ag-theme-alpine"}>
//         <AgGridReact
//           rowData={rowData}
//           columnDefs={columnDefs}
//           defaultColDef={defaultColDef}
//           onGridReady={onGridReady}
//         />
//       </div>
//     </div>
//   );
// };



// export default DarkMode;
