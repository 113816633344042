import React from 'react'

import { URL } from './service/url'
import {Link, json, useNavigate} from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios';


export default function Login() {

// states bellow

const [adminid,setAdminId]=useState('')
const [pasword,setPasword]=useState('')
const [alert,setAlert]=useState('')


//  use navigate bellow 

const navigate=useNavigate()

// navigate function bellow

const  Navigatedashboard=async()=>{
  
   var formdata=new FormData()
   formdata.append('username',adminid)
   formdata.append('password',pasword)
  
   
   var result = await axios.post(`${URL}applogin&bpuser=10891`,formdata)
    
  

   if(result.data.res_code==1)
   {   
      localStorage.setItem('admindata',JSON.stringify(result.data))
      navigate('/Dashboard')
        
   }
   else{
     
    setAlert(result.data.res_msg)
      

   }
    
}
const handleKeyPress = (e) => {
  if (e.key === "Enter") {
    Navigatedashboard();
  }
};

  return (
    <div className='login-page'>
<div className="login-box">
 
  <div className="card card-outline card-primary">
    <div className="card-header text-center">
      <Link to="/" className="h1">
            <b>
            <img alt='login' title='login'  className='img-fluid' src={process.env.PUBLIC_URL + '../dist/img/AdminLTELogo.png'} style={{width:'50px', height:'50px'}} />
            </b>
           <span style={{fontSize:'22px'}}> Stock Trading</span>
      </Link>
    </div>
    <div className="card-body">
      <p className="login-box-msg">Welcome to Portfolio Tracker</p>

      <form onKeyPress={handleKeyPress} action="" method="post">
        <div className="input-group mb-3">
          <input  onFocus={()=>setAlert('')}    onChange={(event)=>setAdminId(event.target.value)}   type="text" className="form-control" placeholder="User Name"   />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-user"></span>
            </div>
          </div>
        </div>
        <div className="input-group mb-3">
          <input onFocus={()=>setAlert('')}   onChange={(event)=>setPasword(event.target.value)}   type="password" className="form-control" placeholder="Password"/>
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-lock"></span>
            </div>
          </div>
        </div>
        {/* alert */}
        { <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
            {alert}
          </div>}
        {/* endalert */}
        <div className="row">
         
          
          <div className="col-4" style={{float:'none', margin:'0 auto'  }}>
            <div onClick={()=>Navigatedashboard()} type="submit" className="btn btn-primary btn-block">Login</div>
          </div>
         
        </div>
      </form>

    

      
      {/* <p className="mt-2">
        <Link to="/Registration" className="text-center" style={{display:'block'}}>Register a new membership</Link>
      </p> */}


    </div>
   
  </div>
 
</div>



    </div>
  )
}
