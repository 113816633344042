import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import { useState } from 'react'
import axios from 'axios'
import { URL } from './service/url'
import {  useNavigate } from 'react-router-dom'
import SweetAlert2 from 'react-sweetalert2';

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

// CommonJS


export default function AddBroker() {

  const dispatch=useDispatch()
  // const admin=JSON.parse(localStorage.getItem('admindata'))
  const admindetail=JSON.parse(localStorage.getItem('admindata'))
  const navigate=useNavigate()
   
  const [brokerjoiningdate,setBrokerJoiningDate]=useState('')
  const [checkboxes, setCheckboxes] = useState(['Buy'])
  const [brokername,setBrokerName]=useState('')
  const [brokershortname,setBrokerShortName]=useState('')
  const [brokerdesignation,setBrokerDesignation]=useState('')
  const [brokernumber,setBrokerNumber]=useState('')
  const [brokerdob,setBrokerDateOfBirth]=useState('')
  const [brokeradd,setBrokerAdd]=useState('')
  const [error,setError]=useState({})
  const [brokerid,setBrokerid]=useState()
  // const [checked, setChecked] = useState(true);
  const [swalProps, setSwalProps] = useState({});
  // const [brokerid,setBrokerid]=useState()
  // const [brokernamee,setBrokerNamee]=useState()
  // const [checkbrokername,setCheckBrokerName]=useState([])

 

  const Swal = require('sweetalert2')
  

 
  
  

  // checkbox bellow
   const handleInputChange = (value, checked) => {
    if (checked)
     {
        setCheckboxes([...checkboxes, value]);
    } 
    else {
        setCheckboxes(checkboxes.filter((item) => item !== value));
    }
}

// end  checkbox 


const Brokername=async(event)=>{
  
  setBrokerName(event.target.value)
  // let formdata=new FormData()
  // formdata.append('broker_name',brokername)
// let result = await axios.post(`${URL`,)






}



const handleerror=(input,value)=>{


setError((prev)=>({...prev,[input]:value}))

}

const validation=()=>{
var isvalid=true

if(!brokername)
{
  handleerror("b_name","Please Enter Account Name")
  isvalid=false
}
if(!brokerdesignation)
{
  handleerror("b_designation","Please Enter Account Designation")
  isvalid=false

}
if(!brokerjoiningdate)
{
  handleerror("b_joiningdate","Please Enter Account Joining Date")
  isvalid=false

}
if(!brokerdob)
{
  handleerror("b_dob","Please Enter Account DOB")
  isvalid=false

}
if(brokernumber=="")
{
  
  handleerror("b_number","Please Enter Mobile Number")
  isvalid=false

}
else if (brokernumber.length<10 || brokernumber.length>10)
{
  handleerror("b_number","Please Enter Number 10 Digit")
  isvalid=false

}
else if(isNaN(brokernumber))
{
  handleerror("b_number","Please Enter Number (Only Numeric)")
  isvalid=false
}

if(!brokeradd)
{
  handleerror("b_add","Please Enter Account Address")
  isvalid=false

}
if(!brokershortname)
{
  handleerror("b_shortname","Please Enter Account Short Name")
  isvalid=false

}

return isvalid

}


const Submit=async()=>{


  if(validation()){

    
  let formdata=new FormData()
  formdata.append('b_admin_id',admindetail.user_id)
  formdata.append('b_address',brokeradd)
  formdata.append('b_designation',brokerdesignation)
  formdata.append('b_mobile',brokernumber)
  formdata.append('b_rights',checkboxes)
  formdata.append('b_birth',brokerdob)
  formdata.append('join_date',brokerjoiningdate)
  formdata.append('b_name',brokername)
  formdata.append('b_short_name',brokershortname)

  formdata.append ('admin_id',admindetail.user_id)

      
  
  
  let result = await axios.post(`${URL}brokerinfo&bpuser=10891&action=insert`,formdata)
  
 
  if(result.data.res_code==1)
  {
    Swal.fire({
      position: 'top-center',
      icon: 'success',
      title: 'Add Account Successfully',
      showConfirmButton: false,
      timer: 1600
    }).then((result) => {
     
    
          navigate('/Dashboard')
     
    })

  }
  else if (result.data.res_code==2)
  {
    Swal.fire({
      position: 'top-center',
      icon: 'error',
      title: 'Account already exists',
      showConfirmButton: false,
      timer: 1600
    }).then((result) => {
     
    
          navigate('/Dashboard')
     
    })

  }
 

  }
  else{}
}
  
const  refreshPage=()=>{
  
  window.location.reload(false);

}


const brokerrr=(bid,bnm)=>{

  var body={bro_id:bid,bro_name:bnm}
  var bi ='broker'

  dispatch({type:'brokeridset',payload:[bi,body]})
  
  

  
}


var bro  = useSelector((state)=>state.broker) 

useEffect(()=>{



if(!bro.broker)
{
  setBrokerid(null)
  
}
else
{
  setBrokerid(bro.broker.bro_id)
  setBrokerName(bro.broker.bro_name)
 
}
  
},[bro.broker])








  return (
    <>
     <div className="wrapper" >
    <Header   refresh={refreshPage}   brokerr={brokerrr}  />
    <div   className="content-wrapper">
    <section className="content-header">
      <div className="container-fluid">
     
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1>Add Account</h1>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="/Dashboard" >Home</a></li>
              <li className="breadcrumb-item active">Add Account</li>
            </ol>
          </div>
        </div>
       

      </div>
    </section>
    <section className="content">
      <div className="container-fluid">
        
    <div className='row'>
    <div className="col-sm-6">
 <div className="card card-default">
         <div class="card-body">

                  <div className="row">
                    <div className="col-sm-6">
                      
                      <div className="form-group">
                        <label>Joning Date (DD/MM/YYYY)</label>
                        <input onFocus={()=>handleerror("b_joiningdate",null)}  onChange={(event)=>setBrokerJoiningDate(event.target.value)} type="date" className="form-control" placeholder="Enter ..."/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.b_joiningdate}
                      </div>
                    </div>
                    {/* <div className="col-sm-6">
                      <div className="form-group" style={{marginTop:'0.2rem'}}>
                        <label>Account Rights</label> <br/>
                        <div class="form-check form-check-inline ml-4">
                         
                          <input  name='rights'   defaultChecked={checked}  onChange={(event)=>handleInputChange(event.target.value,event.target.checked)}   class="form-check-input" type="checkbox" id="inlineCheckbox1" value="Buy"/>
                          <label class="form-check-label" for="inlineCheckbox1">Buy</label>
                          </div>
                          <div class="form-check form-check-inline">
                          <input  name='rights'  onChange={(event)=>handleInputChange(event.target.value,event.target.checked)}    class="form-check-input" type="checkbox" id="inlineCheckbox2" value="Sell"/>
                          <label class="form-check-label" for="inlineCheckbox2">Sell</label>
                          </div>
                          <div class="form-check form-check-inline">
                        
                          <input  name='rights'  onChange={(event)=>handleInputChange(event.target.value,event.target.checked)}    class="form-check-input" type="checkbox" id="inlineCheckbox3" value="Fund Add" />
                            <label class="form-check-label" for="inlineCheckbox3">Fund Add</label>
                          </div>
                       
                        </div>
                    </div> */}
                  </div>
                  
                   <div className="column">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Name</label>
                        <input onFocus={()=>handleerror("b_name",null)} onChange={(event)=>Brokername(event)}  type="text" className="form-control" placeholder="Enter ..."/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.b_name}
                      </div>
                    </div>
                    {/* ... */}

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Short Name</label>
                        <input    onFocus={()=>handleerror("b_shortname",null)} onChange={(event)=>setBrokerShortName(event.target.value)}  type="text" className="form-control" placeholder="Enter ..."/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.b_shortname}
                      </div>
                    </div> 
                   {/* ...... */}

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Designations</label>
                        <input onFocus={()=>handleerror("b_designation",null)} onChange={(event)=>setBrokerDesignation(event.target.value)} type="text" className="form-control" placeholder="Enter ..."/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.b_designation}
                      </div>
                    </div>

                  
                    {/* ... */}
                    
                  </div>
                  
                    <div className="row">
                    <div className="col-sm-6">
                      
                      <div className="form-group">
                        <label>Date of Birth</label>
                        <input onFocus={()=>handleerror("b_dob",null)} onChange={(event)=>setBrokerDateOfBirth(event.target.value)}  type="date" className="form-control" placeholder="Enter ..."/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.b_dob}
                      </div>
                      
                    </div>
                    {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label>User ID</label> 
                        <input type="text" className="form-control" placeholder="Enter ..."/>
                      </div>
                    </div> */}
                  </div>
                  
                   <div className="row">
                    <div className="col-sm-12">
                     
                      <div className="form-group">
                        <label>Mobile No.</label>
                        <input  onFocus={()=>handleerror("b_number",null)}  onChange={(event)=>setBrokerNumber(event.target.value)}  type="text" className="form-control" placeholder="Enter ..."/>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.b_number}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Address</label>
                          <textarea  onFocus={()=>handleerror("b_add",null)} onChange={(event)=>setBrokerAdd(event.target.value)}  className="form-control" rows="3" placeholder="Enter ..."></textarea>
                      </div>
                      <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                        {error.b_add}
                      </div>
                    </div>
                   
                  </div>

                  {/* { 
                    alert?<>
                    <div class="alert alert-success" role="alert">
                      Record Submit Successfully
                    </div>
                    </>:<>

                    </>

                  } */}

                  


                </div>
                <div className="card-footer">
                  <button   onClick={()=>Submit()}   type="button" className="btn btn-block btn-primary" style={{width:'200px', float:'none', margin:'0 auto' }}>Submit</button>
              </div>
         </div>
        </div>

    </div>
    </div>
    </section>
    </div>
    <Footer/>
    </div>
    <SweetAlert2 {...swalProps} />
    </>
  )
}

