
import Avatar from "react-avatar";
import React, { useEffect, useState, useRef } from "react";
import {
  getDatabase,
  ref,
  push,
  set,
  onChildAdded,
  serverTimestamp,
} from "firebase/database";
import ScrollToBottom from "react-scroll-to-bottom";
import { ToastContainer, toast } from "react-toastify"; // Import toast
import "react-toastify/dist/ReactToastify.css";
export default function Chatbox() {
  const [name, setName] = useState("");
  const [msg, setMsg] = useState("");
  const database = getDatabase();
  const chatListRef = ref(database, "chat");
  const chatContainerRef = useRef(null);
  const initialChat = JSON.parse(localStorage.getItem("chat")) || [];
  const [chat, setChat] = useState(initialChat);
  const [isUserJoined, setIsUserJoined] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [lastUnreadMessage, setLastUnreadMessage] = useState(null);
  const [lastPendingMessage, setLastPendingMessage] = useState(null);

  
  useEffect(() => {
    const unsubscribe = onChildAdded(chatListRef, (data) => {
      const message = { ...data.val(), key: data.key };
      if (!chat.find((existingMessage) => existingMessage.key === message.key)) {
        setChat((prevChat) => [...prevChat, message]);
        if (!isUserJoined || document.hidden) {
          if (message.name !== name) {
            setUnreadMessages((prevUnreadMessages) => [
              ...prevUnreadMessages,
              message,
            ]);
            setLastUnreadMessage(message);
            if (message.pending) {
              setLastPendingMessage(message);
            }
            toast.info(`${message.name}: ${message.message}`, {
                position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
              onClick: () => {
                console.log("Notification clicked");
              },
            });
          }
        }
        // Update local storage with the updated chat array
        const updatedChat = [...chat, message];
        localStorage.setItem("chat", JSON.stringify(updatedChat));
      }
    });
    return () => {
      unsubscribe();
    };
  }, [isUserJoined, name, chat]);

  const showNotification = (sender, message) => {
    if ("Notification" in window && Notification.permission === "granted") {
      new Notification(`${sender} sent a message`, {
        body: message,
        icon: "/path/to/your/icon.png", // Replace with your notification icon path
      });
    } else if ("Notification" in window && Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          showNotification(sender, message);
        }
      });
    }
  };
  const handleJoinChat = () => {
    if (name.trim() !== "") {
      setIsUserJoined(true);
      setLastUnreadMessage(null);
    }
  };
  const sendChat = () => {
    if (msg.trim() !== "") {
      const chatRef = push(chatListRef);
      set(chatRef, {
        name,
        message: msg,
        timestamp: serverTimestamp(),
        pending: false,
      });
      setMsg("");
      const updatedChat = [
        ...chat,
        { name, message: msg, timestamp: serverTimestamp(), pending: false },
      ];
      localStorage.setItem("chat", JSON.stringify(updatedChat));
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendChat();
    }
  };
  const handleScrollToBottom = () => {
    setUnreadMessages([]);
    setLastUnreadMessage(null);
    setLastPendingMessage(null);
  };
  return (
    <div>
      <ToastContainer /> {/* Initialize ToastContainer */}
      <div className="card direct-chat direct-chat-primary">
      <div className="card-header">
          <h3 className="card-title">
          {isUserJoined ? <>
          {/* <select className="form-control">
              <option value="ACCEELYA" selected>
               ACCEELYA
              </option>
              <option value="ACE">ACE</option>
              <option value="AIAENG">AIAENG</option>
              <option value="ANDHRPAP">ANDHRPAP</option>
            </select> */}
           <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'300px'}}>
           <div style={{fontWeight:'bold',color:'grey',fontSize:'18px'}}>
            Welcome : &nbsp;  {name}
           </div>
           </div>
          </>
             : (
              <div>
                <input
                  type="text"
                  placeholder="Enter your name"
                  className="form-control"
                  onBlur={(e) => setName(e.target.value)}
                />
                <button onClick={handleJoinChat} className="btn btn-primary mt-2">
                  Join Chat
                </button>
              </div>
            )}
          </h3>
          <div className="card-tools" style={{display:'flex',flexDirection:'row',width:'150px',justifyContent:"space-between" }}>
          {/* <span title="3 New Messages" className="badge badge-primary">
            3
          </span> */}
          <div style={{fontWeight:'bold',fontSize:'15px'}}>
            Massages
           </div>
          <button type="button" className="btn btn-tool" data-card-widget="collapse">
            <i className="fas fa-minus"></i>
          </button>
          {/* <button type="button" className="btn btn-tool" title="Contacts" data-widget="chat-pane-toggle">
            <i className="fas fa-comments"></i>
          </button> */}
          {/* <button type="button" className="btn btn-tool" data-card-widget="remove">
            <i className="fas fa-times"></i>
          </button> */}
        </div>
        </div>
        {isUserJoined && (
          <div className="card-body">
            <ScrollToBottom className="direct-chat-messages" scrollToBottom={true} onScroll={handleScrollToBottom}>
              {chat.map((message, i) => (
                <div key={i} className={`direct-chat-msg ${message.name === name ? "right" : ""}`}>
                  <div className="direct-chat-infos clearfix">
                    <span className={`direct-chat-name ${message.name === name ? "float-right" : "float-left"}`}>
                      {message.name}
                    </span>
                    <span className={`direct-chat-timestamp ${message.name === name ? "float-left" : "float-right"}`}>
                      {new Date(message.timestamp).toLocaleString()}
                    </span>
                  </div>
                  <Avatar
                  className="direct-chat-img"
                  alt="message user"
        // name={name}
        src='/assets/user.png'
        size="40"
        round={true}
      />
                  <div className="direct-chat-text">
                    {message.message}
                  </div>
                </div>
              ))}
            </ScrollToBottom>
          </div>
        )}
        {isUserJoined && (
          <div className="card-footer">
            <form
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <div className="input-group">
                <input
                  onInput={(e) => setMsg(e.target.value)}
                  onKeyPress={handleKeyPress}
                  value={msg}
                  type="text"
                  name="message"
                  placeholder="Type Message ..."
                  className="form-control"
                />
                <span className="input-group-append">
                  <button onClick={sendChat} className="btn btn-primary">
                    Send
                  </button>
                </span>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}