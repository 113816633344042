import { useState } from "react"
import { useEffect } from "react"
import { URL } from "../service/url"
import axios from "axios"


export default function Transaction(){

    const admin=JSON.parse(localStorage.getItem('admindata'))
   
    const [transactiondata,setTransactionData]=useState([])


    const gettransactiondata=async()=>{

        var formdata=new FormData()
        
        formdata.append('admin_id',admin.user_id)
       
        var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=transctionhistoryforreport`,formdata)
       
        setTransactionData(result.data.data)

        
       }

       useEffect(()=>{
        gettransactiondata()
       },[])


       const showtransaction=()=>{
     
      return   transactiondata.map((val)=>{

          return (
            
            <tr>
            <td className="borderleft ">{val.DATE}</td>
            <td className="text-left"><span className="green-text">{val.Transaction_Type}</span></td>
            <td className="text-left mt10">{val.Company}</td>
            <td className="text-right mt10_3">{val.Price}</td>
            <td className="text-right mt10_3">{val.Qty}</td>
            <td className="borderright text-right no-mob no-tab2">{val.Transaction_Value}</td>
           </tr>

          )

        })

       }



       



return (

    <div className="pagecontent">


    <div className="pagecontent2 border_not_bottom">
        <div className="row">
            <div className="col-md-12">
                <p className="mt8">TRANSACTION HISTORY </p>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="sttable14_2">
                    <table className="table">
                        <tbody>
                            <tr className="no-mob no-tab2">
                                <th className="borderleft mt9 mt10_1">Date</th>
                                <th className="mt9 text-left">Transaction Type </th>
                                <th className="mt9 text-left mt10">Company</th>
                                <th className="mt9 text-right mt10_3">Price</th>
                                <th className="mt9 text-right mt10_3">Qty</th>
                                <th className="mt10_2 borderright text-right mt9">Transaction Value</th>
                            </tr>
                           
                            {showtransaction()}

                            {/* <tr>
                                <td className="borderleft ">27 Jun 23</td>
                                <td className="text-left"><span className="green-text">Buy</span></td>
                                <td className="text-left mt10">Route Mobile</td>
                                <td className="text-right mt10_3">1,614.83</td>
                                <td className="text-right mt10_3">1,432</td>
                                <td className="borderright text-right no-mob no-tab2">23,12,436</td>
                            </tr>
                            <tr>
                                <td className="borderleft ">26 Jun 23</td>
                                <td className="text-left"><span className="red-text">Sell</span></td>
                                <td className="text-left mt10">Torrent Power</td>
                                <td className="text-right mt10_3">638.86</td>
                                <td className="text-right mt10_3">2,269</td>
                                <td className="borderright text-right no-mob no-tab2">14,49,568</td>
                            </tr> */}
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</div>

)

}