
import axios from "axios"
import { URL } from "../service/url"
import { useState } from "react"
import { useEffect } from "react"



export default function Holdingreport(){


    const admin=JSON.parse(localStorage.getItem('admindata'))
    const [brokerid,setBrokerid]=useState(null)
    const [currentholdingdata,setCurrentHoldingData]=useState([])

    const getholdingdat=async()=>{

        var formdata=new FormData()
        
    
        formdata.append('bro_id',brokerid)
        
        formdata.append('admin_id',admin.user_id)
       
        var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=retrieve`,formdata)
        setCurrentHoldingData(result.data.data);
 }
    
    useEffect(()=>{
      
      getholdingdat()
    
    },[])
    
    
    
    
    const showcurrentholdin=()=>{
    
    return currentholdingdata.map((val)=>{
    
    return (
    
        <tr>
            <td className="borderleft mt10_10" >{val.STOCKS}</td>
            <td className="text-right" >{val.AVRAGE_PURCHASE_PRICE}</td>
            <td className="text-right">{val.QTY}</td>
            <td className="text-right">{val.HOLDING}</td>
            <td className="text-right" >0.00</td>
            <td className="text-right borderright" >0.00</td>
        </tr>
           
    
    )
    
    })
    
    
    }

    let total=0
    
    currentholdingdata.map((vali)=>{
  
        total+=vali.HOLDING
  
    })
   
    console.log(total,'total')
    
    


return (

    <>
                     <div className="pagecontent">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mt19">
                                    CURRENT HOLDINGS
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="pagecontent">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="sttable14_2">
                                                <table className="table">
                                                    <tbody>
                                                        <tr className="bordertop">
                                                            <th className="borderleft mt9 mt10_10" >Company</th>
                                                            <th className="mt9 text-right" >Buy Price</th>
                                                            <th className="mt9 text-right">Qty</th>
                                                            <th className="mt9 text-right">Current Value</th>
                                                            <th className="mt9 text-right" >Unrealised<br /> Gain/Loss (INR)</th>
                                                            <th className="mt9 text-right borderright" >Unrealised<br /> Gain/Loss (%)</th>
                                                        </tr>

                                                        {showcurrentholdin()}
                                                        {/* <tr>
                                                            <td className="borderleft mt10_10" >ACE</td>
                                                            <td className="text-right" >437.81</td>
                                                            <td className="text-right">100</td>
                                                            <td className="text-right">46,075</td>
                                                            <td className="text-right" >-2,294</td>
                                                            <td className="text-right borderright" >0%</td>
                                                        </tr> */}

                                                        <tr>
                                                           
                                                            <td className="borderleft mt9">Total</td>
                                                            <td className="text-right"></td>
                                                            <td className="text-right"></td>
                                                            <td className="text-right mt9"><b>{total}</b></td>
                                                            <td className="text-right mt9"><strong>0.00</strong></td>
                                                            <td className="text-right mt9">0.00</td>
                                                        
                                                        </tr>
                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



    </>

)






}