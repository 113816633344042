import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import AddFund from './AddFund'
import WithDraw from './WithDraw'
import axios from 'axios'
import { URL } from '../service/url'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'


//import ViewStatement from './ViewStatement'



export default function Funds() {

const dispatch=useDispatch()
const admin=JSON.parse(localStorage.getItem('admindata'))
const admindetail=JSON.parse(localStorage.getItem('admindata'))
const [brokerdata,setBrokerdata]=useState([])
const [broker,setBroker]=useState()
const [investmentammount,setInvestmentAmmount]=useState('')
const [withdrawammount,setWithdrawAmmount]=useState('')
const [availableamm,setAvailableAmm]=useState('')
const [margininvestmentammount,setMarginInvestmentAmmount]=useState('')
const [marginwithdrawammount,setMarginWithdrawAmmount]=useState('')
const [marginavailableamm,setMarginAvailableAmm]=useState('')
const [brokerid,setBrokerid]=useState()
const [brokernamee,setBrokerNamee]=useState()








 // ...............api get broker 

 const getbroke=async()=>{
  var formdata=new FormData()
  formdata.append('admin_id',admindetail.user_id) 
  const result=await axios.post(`${URL}brokerinfo&bpuser=10891&action=getbrokers`,formdata)
  setBrokerdata(result.data.data)
  
  }


  const dropdown=()=>{

   return  brokerdata.map((broker)=>{

    return (

     <option value={broker.user_id} >{broker.user_name}</option>
        
    )


    })


  }
  
  
  useEffect(()=>{
  
    getbroke()
   
   },[])


  // ........................api get broker end function 



const addAmmwitAmm=async()=>{

  

let formdata=new FormData()

formdata.append('broker_id',broker)
formdata.append('admin_id',admindetail.user_id)


var result=await axios.post(`${URL}stockfund&bpuser=10891&action=getAvailableCash`,formdata)

if(result.data.data[0].availableAmount==null){
  setInvestmentAmmount('0.00')

}
else{
  setInvestmentAmmount(result.data.data[0].availableAmount)
}

if(result.data.data[0].withdralAmount==null)
{
  setWithdrawAmmount('0.00')

}
else{
  setWithdrawAmmount(result.data.data[0].withdralAmount)
}


var avail='0.00'

if(result.data.data[0].availableAmount==null && result.data.data[0].withdralAmount==null )
{ 
  setAvailableAmm("0.00")
}
else{

  avail=result.data.data[0].availableAmount-result.data.data[0].withdralAmount
  setAvailableAmm(avail)
}

}



const addNLMAmmwitAmm=async()=>{

    

    let formdata=new FormData()
    
    formdata.append('broker_id',broker)
    formdata.append('admin_id',admindetail.user_id)
    
    
    var result=await axios.post(`${URL}stockfund&bpuser=10891&action=getAvailablemtf`,formdata)

    console.log(result.data.data,'mtf..')
    
    if(result.data.data[0].availableAmount==null){
      setMarginInvestmentAmmount('0.00')
    
    }
    else{
      setMarginInvestmentAmmount(result.data.data[0].availableAmount)
    }
    
    if(result.data.data[0].withdralAmount==null)
    {
      setMarginWithdrawAmmount('0.00')
    
    }
    else{
      setMarginWithdrawAmmount(result.data.data[0].withdralAmount)
    }
    
    
    var avail='0.00'
    
    if(result.data.data[0].availableAmount==null && result.data.data[0].withdralAmount==null )
    { 
      setMarginAvailableAmm("0.00")
    }
    else{
    
      avail=result.data.data[0].availableAmount-result.data.data[0].withdralAmount
      setMarginAvailableAmm(avail)
    }
    
  
  
  
  }

  



useEffect(()=>{
 
  addAmmwitAmm()
  addNLMAmmwitAmm()

},[broker])





const call=(bid)=>{
 
  
  setBroker(bid)

}


const  refreshPage=()=>{
  
  window.location.reload(false);

}


  const brokerrr=(bid,bnm)=>{
  var body={bro_id:bid,bro_name:bnm}
  var bi ='broker'

  dispatch({type:'brokeridset',payload:[bi,body]})
  
}

var bro  = useSelector((state)=>state.broker) 

useEffect(()=>{

if(!bro.broker)
{
  setBrokerid(null)
  
}
else
{
  setBrokerid(bro.broker.bro_id)
  setBrokerNamee(bro.broker.bro_name)
 
}
  
},[bro.broker])








  return (
    <>
    <div className="wrapper">
      <Header refresh={refreshPage}   brokerr={brokerrr}   />   
    <div className="content-wrapper">
    <section className="content-header">
      <div className="container-fluid">
                  
        <div className="row mb-2" >
                  
                  
                
          <div className="col-sm-6" style={{display:'flex',justifyContent:"space-between"}}>
            <div >
              <div className="btn btn-block btn-primary">
                  <label >Account's</label>
                  <select  onChange={(event)=>call(event.target.value)}  className="form-control">
                      <option value={null} >-- ALL ACCOUNT DATA --</option>
                      {dropdown()}
                  </select>
              </div>
            </div>  
          <div>

          <h4 style={{marginLeft:'130px'}} >NML</h4>

          <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:'40px'}}>
              
            <div style={{fontSize:16}}>


              <table style={{background:'#bdc3c7',border: '1px solid white' ,borderCollapse: 'collapse' , width:'330px'}}>

                <tr>
                  <th style={{ backgroundColor: '#bdc3c7 border' ,border:'1px solid white' ,borderCollapse: 'collapse'}}> Investment Amount </th>
                  <td style={{backgroundColor: '#bdc3c7 border' ,border: '1px solid white' ,borderCollapse: 'collapse',display:'flex',justifyContent:'center'}}>&#8377; {investmentammount} </td>
                </tr>

                <tr>
                  <th style={{ backgroundColor: '#bdc3c7 border' , border: '1px solid white' ,borderCollapse: 'collapse'}}>Used Amount</th>
                  <td style={{backgroundColor: '#bdc3c7 border' ,border: '1px solid white' ,borderCollapse: 'collapse',display:'flex',justifyContent:'center'}}>&#8377; {withdrawammount}</td>
                </tr>

                <tr>
                  <th style={{  backgroundColor: '#bdc3c7 border' ,border: '1px solid white' ,borderCollapse: 'collapse'}}>Available Amount</th>
                  <td style={{backgroundColor: '#bdc3c7 border' ,border: '1px solid white' ,borderCollapse: 'collapse',display:'flex',justifyContent:'center',fontWeight:'bold'}}>&#8377;  {availableamm}</td>
                </tr>

              </table>

                {/* <div class="container" >

                  <div class="row"  style={{background:'red'}}>
                    <div class="col-12" >
                      Investment Amount
                    </div>
                    <div class="col">
                      &#8377; {investmentammount} 
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                    Withdraw Amount
                    </div>
                    <div class="col">
                        &#8377; {withdrawammount}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                        Available Amount
                    </div>
                    <div class="col">
                      &#8377;  {availableamm} 
                    </div>
                  </div>

                
                </div> */}
              
                
               </div>
                
            

              </div>

            </div>

          </div>
           
{/* ........................................ */}

          <div className="col-sm-5" >
          
            <h4 style={{marginLeft:'100px'}}>MTF</h4>

            <div style={{fontSize:16}}>
            
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',pddingLeft:'40px'}}>
              
                
                <table style={{background:'#bdc3c7',border: '1px solid white' ,borderCollapse: 'collapse' , width:'330px'}}>

                  <tr>
                    <th style={{ backgroundColor: '#bdc3c7 border' ,border:'1px solid white' ,borderCollapse: 'collapse'}}>Investment Amount </th>
                    <td style={{backgroundColor: '#bdc3c7 border' ,border: '1px solid white' ,borderCollapse: 'collapse',display:'flex',justifyContent:'center'}}>&#8377; {margininvestmentammount} </td>
                  </tr>

                  <tr>
                    <th style={{ backgroundColor: '#bdc3c7 border' , border: '1px solid white' ,borderCollapse: 'collapse'}}>Used Amount</th>
                    <td style={{backgroundColor: '#bdc3c7 border' ,border: '1px solid white' ,borderCollapse: 'collapse',display:'flex',justifyContent:'center'}}>&#8377; {marginwithdrawammount}</td>
                  </tr>

                  <tr>
                    <th style={{  backgroundColor: '#bdc3c7 border' ,border: '1px solid white' ,borderCollapse: 'collapse'}}>Available Amount</th>
                    <td style={{backgroundColor: '#bdc3c7 border' ,border: '1px solid white' ,borderCollapse: 'collapse',display:'flex',justifyContent:'center',fontWeight:'bold'}}>&#8377;  {marginavailableamm}</td>
                  </tr>

                </table>

                <Link style={{fontSize:'11px', color:'#007bff', paddingLeft:'50px'}} to={{pathname:"/ViewStatement",search:broker}}>
                  <img alt='User' title='User'  className='img-fluid' src={process.env.PUBLIC_URL + '../dist/img/financial-statements.png'} />Statement
                </Link>




              </div>


            </div>
             
            
          </div>
{/* ......................................... */}
          <div className="col-sm-0.8"   >
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="/Dashboard" >Home</a></li>
              <li className="breadcrumb-item active">Funds</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section className="content">
      <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-sm-6">
            <div className="card card-primary card-outline card-outline-tabs">
              <div className="card-header p-0 border-bottom-0">
                <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                  
                  <li className="nav-item">
                    <a className="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">Add Fund</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill" href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">Withdraw</a>
                  </li>
                 
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content" id="custom-tabs-four-tabContent">
                  <div className="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                  <AddFund/>
                  </div>
                  <div className="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
                    <WithDraw/>
                  </div>
                 
                </div>
              </div>
             
            </div>
          </div>
        </div> 
        </div>
     </section>      

    </div>
      <Footer/>
    </div>
    
    </>
  )
}
