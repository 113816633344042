import React from 'react';
import {useState, useRef, useEffect, useMemo, useCallback}  from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { useSelector } from 'react-redux'
import axios from 'axios';
import { IMGURL, URL } from '../service/url';
import { AgCharts } from 'ag-charts-community';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactModalImage from 'react-modal-image';


const Newsfeed = () => {

    const navigate=useNavigate()

    const locations=useLocation()



    // console.log(,'locations...')

    const admindetail=JSON.parse(localStorage.getItem('admindata'))
    
    const [formshow,setFormShow]=useState(false)

    const [stocklist, setStockList] = useState([]);

    const [newslistdata,setNewsListData]=useState([])
    const [fnewslistdata,setFNewsListData]=useState([])

    const [error,setError]=useState({})
    const [stockname,setStockName]=useState('0')
    const [attechment,setAttechment]=useState({ file: "/assets/user.png", bytes: "" })
    const [information,setInformation]=useState('')


   
    



    // handleerror function 

    const handleerror=(input,value)=>{

        setError((prev)=>({...prev,[input]:value}))
        
    }


    function handelPicture(event) {

        const file = event.target.files[0];
        
        if (file) {
           
            if (file.type.startsWith('image/')) {
                
                const fileURL = window.URL.createObjectURL(file);
                
                
                setAttechment({
                    file: fileURL,
                    bytes: file
                });
    
                
                handleerror("icon", null);
            } else {
               
                handleerror("icon", "Please select a valid image file (jpg, jpeg, png).");
            }
        } else {
            
            handleerror("icon",null);
        }
    }

      
    
  // validation function 

  const validation=()=>{


    var isvalid=true
    
   

    if(stockname==='0')
    {
      handleerror("s_stock","Please Select Stock ")
      isvalid=false
    }

    // if(attechment==='')
    // {
    //   handleerror("icon","Please Add Attechment")
    //   isvalid=false
      
    // }
 
    if(information==='')
    {

      handleerror("s_information","Please Enter Information")
      isvalid=false
    }


  

  
    return isvalid
    
   }
   
    


   


const getstock=async()=>{

    var formdata=new FormData()
    formdata.append('admin_id',admindetail.user_id) 
    const result= await axios.post(`${URL}stockInfo&bpuser=10891&action=masterStocks`,formdata)
    setStockList(result.data.data)
        
}



const getnewlist=async()=>{
        
    if(!locations?.state?.news_id)
     {   

    var formdata=new FormData()
        formdata.append('admin_id',admindetail.user_id) 
        const result= await axios.post(`${URL}stockInfo&bpuser=10891&action=Getnews`,formdata)
       if(result.data.data)
        {
            setNewsListData(result.data.data)
            setFNewsListData(result.data.data)
        }
        else
        {
            console.log('No data found..')
        }
    }
    else
    {
        var formdata=new FormData()
        formdata.append('admin_id',admindetail.user_id) 
        formdata.append('news_id',locations.state.news_id)
        const result= await axios.post(`${URL}stockInfo&bpuser=10891&action=Getnews`,formdata)
       if(result.data.data)
        {
            setNewsListData(result.data.data)
            setFNewsListData(result.data.data)
        }
        else
        {
            console.log('No data found..')
        }
    }
   
}





const getallnewss=async()=>{


    var formdata=new FormData()
    formdata.append('admin_id',admindetail.user_id) 
    const result= await axios.post(`${URL}stockInfo&bpuser=10891&action=Getnews`,formdata)
    if(result.data.data)
    {
        setNewsListData(result.data.data)
        setFNewsListData(result.data.data)
    }
    else
    {
        console.log('No data found..')
    }

}

useEffect(()=>{
    getstock()
    getnewlist()
},[])






    const showstocklist=()=>{

        return stocklist.map((val)=>{
    
          return (
           
            <option value={val.STOCKS} >{val.STOCKS}</option>
         
          )
    
    
        })
    
      }



      const shownews=()=>{

        return fnewslistdata.map((val,imgindex)=>{

            return (
                <>
                    <div className='card' style={{padding:'15px'}}>
            
                        <div className='row' style={{color:'#fff'}}>

                            <div className="col-sm-10" style={{background:'#007bff',padding:'10px',borderTopLeftRadius:'5px',borderBottomLeftRadius:"5px"}}>
                                
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                    <h4>{val.stock_name}</h4>
                                    <h5>{val.cur_date}</h5>
                                </div>

                                <div >
                                <p style={{fontSize:'16px'}}>{val.news}</p>
                                </div>

                            </div>
                            <div className="col-sm-2" style={{background:'#007bff',padding:'10px',borderTopRightRadius:'5px',borderBottomRightRadius:"5px",display:'flex',justifyContent:'center',alignItems:'center',borderLeft:'2px solid black'}}>
                                    
                                <div style={{width:'80%',height:'80%',background:'#fff',borderRadius:'5%',position: "relative",overflow:'hidden',objectFit:'fill',objectPosition:'center'}}>
                                  {/* <img src={val.file_path ? `${IMGURL}/${val.file_path}`:'/assets/exam.png'} style={{width:'100%',height:'100%'}} /> */}
                               
                                   <ReactModalImage
           
                                        small={val.file_path ? `${IMGURL}/${val.file_path}`:'/assets/exam.png'} 
                                        large={val.file_path ? `${IMGURL}/${val.file_path}`:'/assets/exam.png'} 
                                        alt={`Image ${imgindex}`}
                                        // customDownload={(e) => {
                                        //   e.preventDefault();
                                        //   const link = document.createElement('a');
                                        //   link.href = item.image_name;
                                        //   link.download = `Image_${imageIndex}`;
                                        //   document.body.appendChild(link);
                                        //   link.click();
                                        //   document.body.removeChild(link);
                                        // }}
                                    
                                        // hideDownload={false}
                                        hideZoom={false}
                                        style={{width:'100%',height:'100%'}}

                                    />
                               
                                </div>
                                
                            
                            </div>
                            

                        </div>

                    </div>

                </>
            )
        })
      }
  



  const Submit=async()=>{

    if(validation())
    {
        var formdata=new FormData()
        formdata.append('news',information)
        formdata.append('news_img_url',attechment.bytes)
        formdata.append('stock_name',stockname)
        formdata.append('admin_id',admindetail.user_id)
        let result = await axios.post(`${URL}stockinfo&action=newsadd&bpuser=10891`,formdata)
        if (result.data.res_code==1)
            {
              Swal.fire({
                position: 'top-center',
                icon: 'success',
                title: 'News Add Successfully',
                showConfirmButton: false,
                timer: 1600
              }).then((result) => {
                
                window.location.reload()
               
              })
    
    }


        
        
    }



  }

  const filtterdata = (e) => {

   
    const searchTerm = e.target.value.toLowerCase();


    const filterdata = newslistdata.filter((val) =>
        val.stock_name.toLowerCase().includes(searchTerm)
    );

    setFNewsListData(filterdata)

    
    
   
    
}




  return (
    <div style={{background:'#e0e0e0',minHeight:'100vh',width:'100%',padding:'10px 20px 10px 20px'}}>
 

       
          

        <div className='card' style={{display:'flex',flexDirection:'row',padding:'15px'}}>

            <div className="col-sm-1"  onClick={()=>navigate('/dashboard')} style={{background:'#007bff',padding:'10px',display:'flex',justifyContent:'center',alignItems:'center',color:'#fff',borderTopLeftRadius:'5px',borderBottomLeftRadius:"5px",fontSize:'20px',borderRight:'2px solid black'}} >
        
              HOME

            </div>
            <div className="col-sm-10" style={{background:'#007bff',padding:"10px",display:'flex',justifyContent:'center',alignItems:'center',borderTopRightRadius:'5px',borderBottomRightRadius:"5px"}} >
              <div style={{fontSize:'20px',color:'#fff'}}>NEWS</div>
              
            </div>

            
            <div className="col-sm-1" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>

             {formshow ? <>
                <img src='/assets/list.png' style={{width:'40px'}} onClick={()=>setFormShow(!formshow)} />
             </>:<>
                <img src='/assets/exam.png' style={{width:'40px'}} onClick={()=>setFormShow(!formshow)} />
             </>}
              
            </div>

        </div>

        {formshow ?<>

            <div className='card' style={{display:'flex',flexDirection:'row',padding:'15px'}}>
            {/* className="col-sm-12" */}
                <div className='row' >
                
                    <div className="col-sm-12">

                        <div className="form-group">

                            <label>Stock's</label>

                            <select  onFocus={()=>setError('s_stock',null)}  onChange={(e)=>setStockName(e.target.value)}  className="form-control">
                                
                                <option value={'0'} >--Selecte Stock--</option> 
                                {
                                showstocklist()
                                }

                            </select>

                        </div>
                        <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>

                            {error.s_stock} 

                        </div>

                    </div>


                    <div className="col-sm-12" style={{marginTop:'10px'}}>
                        <div className="form-group">
                            <label>Attechment</label>
                            <input onChange={(e)=>handelPicture(e)} style={{padding:'2px'}} class="form-control" id="formFileSm" type="file" />
                        </div>
                        <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                            {error.icon}
                        </div>
                    </div>


                    <div className="col-sm-12" style={{marginTop:'15px'}}>
                     
                        <div className="form-group">
                            <label>Information</label>
                            <textarea  onFocus={()=>setError('s_information',null)} onChange={(e)=>setInformation(e.target.value)} class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{height:'100px'}} />
                        </div>
                        <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
                            {error.s_information}
                        </div>
                    </div>

                    <div className="col-sm-12" style={{marginTop:'20px'}} >
                
                        <button  onClick={()=>Submit()}  type="button" className="btn btn-block btn-primary">Submit</button>
               
                    </div>

                    


                  

                </div>

            </div>


        </>:<>

        <div className='card' style={{display:'flex',flexDirection:'row',padding:'15px'}}>
         
            <div   style={{display:'flex'}}>

                <div className="col-sm-11" style={{width:'90vw'}}>

                    
                    
                    <label>Search Stock's</label>
                    <input   type="text" className="form-control"  onChange={(e)=>filtterdata(e)} placeholder="Enter Stock Name.."/>
                    

                    
                </div>
                <div className="col-sm-1" style={{display:'flex',justifyContent:'center',alignItems:'center'}} >

                    
                    
                    <div onClick={()=>getallnewss()} style={{background:'#007bff',padding:'15px',fontSize:'20px',borderRadius:'5px',color:'#fff'}}>

                      {'All'}

                    </div>
                    

                    
                </div>
            

            </div>

        </div>

        {/* list news............................................. */}
        <div>
         {shownews()}
        </div>
        {/* list news............................................. */}





        </>}





    
      
    </div>
  );
};

export default Newsfeed;
