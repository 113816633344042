import React from 'react'
import {Link} from 'react-router-dom'
import { useState,useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import {  useNavigate } from 'react-router-dom'
import { URL } from './service/url'

export default function Registration() {
  
  const navigate=useNavigate()

   const [error,setError]=useState({})
   const [name,setName]=useState('')
   const [email,setEmail]=useState('')
   const [phone,setPhone]=useState('')
   const [password,setPassword]=useState('')
   const [repassword,setRePassword]=useState('')
   const [icon, setIcon] = useState({ file: "/assets/user.png", bytes: "" });


  //  console.log(icon.bytes,'hiiii')


  //  console.log('preview',image.preview)
  //  console.log('data',image.data)

  
   const [terms,setTerms]=useState('')

   const [checked, setChecked] = useState(true);
 
   const [swalProps, setSwalProps] = useState({});
   
  
  
  // handleerror function 

  const handleerror=(input,value)=>{

    setError((prev)=>({...prev,[input]:value}))
    
  }


  function handelPicture(event) {
   
    setIcon({

      file: window.URL.createObjectURL(event.target.files[0]),
      
      bytes: event.target.files[0]

   
    });
  
    
    handleerror("icon",null)

  }



  
  // validation function 

  const validation=()=>{
   
    var isvalid=true
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
   
    if(!name)
    {
      handleerror("a_name",'Please Enter Full Name')
      isvalid=false
      
      
    }
    if(!regex.test(email))
    {
      handleerror("A_email",'Please Enter Valid Email')
      isvalid=false
    }

    
    if(!phone)
    {
      handleerror("A_phone",'Please Enter Phone Number')
      isvalid=false
    }
    else if (isNaN(phone))
    {
      handleerror("A_phone",'Please Enter Phone Number {Only Number}')
      isvalid=false
    }
    else if (phone.length<10 || phone.length>10)
    {
      handleerror("A_phone",'Please Enter 10 Digit Phone Number')
      isvalid=false
    }


    if(!password)
    {
      handleerror("A_password",'Please Enter Password')
      isvalid=false
    }
    else if(password.length <= 4 )
    {
      handleerror("A_password",'Please Enter Password length above 4 letter')
      isvalid=false;
    }
 
    if(!repassword)
    {
      handleerror("A_R_password",'Please Enter RePassword')
      isvalid=false;
    }
    else if(repassword.length <= 4)
    {
      handleerror("A_R_password",'Please Enter Repassword length above 4 letter')
      isvalid=false;
    }
    else if(password !== repassword)
    {
      handleerror("A_R_password",'Password Do Not Match')
      isvalid=false;
    }



  
    // if(!terms)
    // {
    //   handleerror("terms",'Please Checked Terms & Condition')
    //   isvalid=false
    // }
  
    if (!icon.bytes) {
      handleerror("icon", "Please select picture")
      isvalid = false;
    }
    else if (!icon.bytes.name.match(/\.(jpg|jpeg|png)$/)) {
      handleerror("icon", "Please select only picture ( jpg,jpeg,png) ")
      isvalid = false;

    }
     
    return isvalid
    
   }



  const ragister=async()=>{
  

    

   if(validation())
   { 
      
     var formdata=new FormData()
     formdata.append('user_name',name)
     formdata.append('user_email',email)
     formdata.append('user_phone',phone)
     formdata.append('user_password',repassword)
     formdata.append("profile_img_url",icon.bytes)
   

     formdata.append('user_type','SUPERADMIN')
     
      var result = await axios.post(`${URL}registration&bpuser=10891`,formdata)

      if(result.data.res_code == 1)
      {
      
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: 'Admin Registration Successfully',
          showConfirmButton: false,
          timer: 1600
        }).then((result) => {
         
          localStorage.clear()
          navigate('/')

         
        })


      }

      else if (result.data.res_code==2)
      {
        Swal.fire({
          position: 'top-center',
          icon: 'error',
          title: 'Account already exists',
          showConfirmButton: false,
          timer: 1600
        }).then((result) => {
         
        
              navigate('/Dashboard')
         
        })
    
      }
      else
      {}

    
   }



  }


  const Term=(event)=>{

  if(event.target.checked)
  {
    setTerms(event.target.value)
  }
  else{
    setTerms(null)
  }

  
  }


  
  return (
    <div className='register-page'>
    <div className="register-box">
  <div className="card card-outline card-primary">
    <div className="card-header text-center">
      <Link to="/" className="h1"><b><img alt='login' title='login'  className='img-fluid' src={process.env.PUBLIC_URL + '../dist/img/AdminLTELogo.png'} style={{width:'50px', height:'50px'}} /></b> <span style={{fontSize:'22px'}}> Stock Trading</span></Link>
    </div>
    <div className="card-body">
      <p className="login-box-msg">Register a new membership</p>

      <form action="/Login" method="post">
        
        <div className="input-group mb-2">
         
          <input  onFocus={()=>handleerror("a_name",null)}   onChange={(e)=>setName(e.target.value)}   type="text" className="form-control" placeholder="Full Name"/>
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-user"></span>
            </div>
          </div>
          
        </div>
        <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
            {error.a_name}
        </div>
      

        <div className="input-group mb-2">
          <input onFocus={()=>handleerror("A_email",null)}   onChange={(e)=>setEmail(e.target.value)}  type="email" className="form-control" placeholder="Email"/>
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-envelope"></span>
            </div>
          </div>
        </div>
        <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
            {error.A_email}
        </div>


        <div className="input-group mb-2">
          <input  onFocus={()=>handleerror("A_phone",null)}   onChange={(e)=>setPhone(e.target.value)}  type="text" className="form-control" placeholder="Mobile Number"/>
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-mobile"></span>
            </div>
          </div>
        </div>
        <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
            {error.A_phone}
        </div>


        <div className="input-group mb-2">
          <input  onFocus={()=>handleerror("A_password",null)}  onChange={(e)=>setPassword(e.target.value)} type="password" className="form-control" placeholder="Password"/>
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-lock"></span>
            </div>
          </div>
        </div>
        <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
            {error.A_password}
        </div>


        <div className="input-group mb-2">
          <input   onFocus={()=>handleerror("A_R_password",null)}  onChange={(e)=>setRePassword(e.target.value)}  type="password" className="form-control" placeholder="Retype Password"/>
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-lock"></span>
            </div>
          </div>
        </div>
        <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
            {error.A_R_password}
        </div>

         
         <div style={{marginBottom:'8px',fontWeight:'bold'}}>
          Select Profile
         </div>

        <div className="input-group mb-0">

          <div class="mb-3">
          <input  error={error.icon ? true : false}  type='file' name='file' onChange={handelPicture}  />
          </div>


        </div>

       
        <div className="input-group mb-0" style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}} >

        <img  src={icon.file}  width='100px' height='100px'   />

        <div style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
          {error.icon}
        </div>


        </div>



      

        <div className="row">
          {/* <div className="col-8">
            
            <div className="icheck-primary">
              <input  onFocus={()=>handleerror("terms",null)}    onChange={(event)=>Term(event)}  type="checkbox" id="agreeTerms" name="terms" value="agree"/>
              <label for="agreeTerms">I agree to the <Link to="/">terms</Link></label>
            </div>
          
          </div>
           */}

          {error.terms?<>
            <div className='ml-2' style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
            {error.terms}
          </div>
            <div className="col-12">
            <button  onClick={()=>ragister()}   type="button" className="btn btn-primary btn-block">Register</button>
          </div>
          

          </>:<>
          <div className='ml-2' style={{display:'flex',alignItems:'center',marginBottom:'5px',color:'#c23616'}}>
            {error.terms}
          </div>

          <div className="col-12">
            <button  onClick={()=>ragister()}   type="button" className="btn btn-primary btn-block">Register</button>
          </div>
          

          </>}
         
         
         
        </div>
      </form>

        <div style={{display:'flex',justifyContent:'center'}} className='mt-2'>
        <Link to="/Dashboard" className="text-center">Home</Link>
        </div>
   
   
    </div>
   
  </div>
</div>    

    </div>
  )
}
