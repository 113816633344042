import React from 'react';
import { initializeApp } from "firebase/app";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Storage from './storage/rootreducer';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import Calculations from './calculated';
import DynamicForm from './calculated';
var store=createStore(Storage)




const root = ReactDOM.createRoot(document.getElementById('root'));

// const firebaseConfig = {
//   apiKey: "AIzaSyBpQ7vLngn9ueMU_Gp-q7mNyXN5KOYO3SQ",
//   authDomain: "some-6a40a.firebaseapp.com",
//   databaseURL: "https://some-6a40a-default-rtdb.firebaseio.com",
//   projectId: "some-6a40a",
//   storageBucket: "some-6a40a.appspot.com",
//   messagingSenderId: "911227834937",
//   appId: "1:911227834937:web:8dca923d438d3ad9de33e8",
//   measurementId: "G-CQP0ZEN4NR"
// };
// const firebaseConfig = {
//   apiKey: "AIzaSyBX92bOUj5ulZIXfvsTAhNvtTtMGYyB51I",
//   authDomain: "some1-710f2.firebaseapp.com",
//   projectId: "some1-710f2",
//   storageBucket: "some1-710f2.appspot.com",
//   messagingSenderId: "683894895504",
//   appId: "1:683894895504:web:5fb9b542aa9ac925b4f915",
//   measurementId: "G-928FWB6LCN"
// };
const firebaseConfig = {
  apiKey: "AIzaSyAu86L31ZUnpfQlS1USVWpWJvvZOMZCseU",
  authDomain: "newp-17cb5.firebaseapp.com",
  projectId: "newp-17cb5",
  storageBucket: "newp-17cb5.appspot.com",
  messagingSenderId: "898974336938",
  appId: "1:898974336938:web:5b38a6583cd05449394dd3",
  measurementId: "G-WTJZ4ME9QW"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);



root.render(
  <React.StrictMode>
   
    <Provider store={store}  >
      <App />
      {/* <DynamicForm/> */}
    </Provider>
 
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
