var initialstate={

   broker:{
    
    
   
    }
   
   
    
    }


    
export default function Storage(state=initialstate,action){
 

        switch(action.type)
        {
           
          case 'brokeridset':
          state.broker[action.payload[0]]=action.payload[1]
          return {broker:state.broker}
          
          
        //   case 'Update_Type':
        //   state.customer[action.payload[0]]=action.payload[1]
        //   return {customer:state.customer}
        
        //   case 'Delete_Type':
        //   delete state.customer[action.payload[0]]
        //   return {customer:state.customer}
        
        
          default:
          return {broker:state.broker}
  
        
        }
 
        
    
    }

