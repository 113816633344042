import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import { URL } from "../service/url"
import Swal from "sweetalert2"
import * as XLSX from 'xlsx';





const Ltpfeed=()=>{

const admin=JSON.parse(localStorage.getItem('admindata'))  
const [stock,setStock]=useState('')
const [masterstock,setMasterStock]=useState([]) 
const [fromdate,setFromDate]=useState(new Date())  
const [todate,setToDate]=useState(new Date())  
const [ltpData,setLtpData]=useState([]) 
const [formshow,setFormShow]=useState(false)
const [jsonData, setJsonData] = useState([]);
const [formData, setFormData] = useState([]);
const [titleData, setTitleData] = useState({
    "DATE" : "" ,
    "LTP" : "",
    
});



const formatDate = (date) => {
   
    const parsedDate = typeof date === 'string' ? new Date(date) : date;
    if (isNaN(parsedDate.getTime())) {
        console.error("Invalid date:", date);
        return ''; 
    }

    const year = parsedDate.getFullYear();
    let month = parsedDate.getMonth() + 1; 
    let day = parsedDate.getDate();
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    
    return `${year}-${month}-${day}`;
};


const fetchData = async () => {
    const formdata = new FormData();
    formdata.append('fromdate', formatDate(fromdate));
    formdata.append('todate', formatDate(todate));
    var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=stockltpupdate`, formdata);
  
    console.log(result.data.data,'result')
   
    // const initialLtpData = result.data.data.map((stock) => ({
    //   stockName: stock.Stock,
    //   date: formatDate(fromdate),
    //   ltp: "" 
    // }));
    setLtpData(result.data.data);
};

useEffect(() => {

 fetchData()

}, [fromdate,todate])


const getstock=async()=>{
    var formdata=new FormData()
    formdata.append('admin_id',admin.user_id)
    const result= await axios.post(`${URL}stockInfo&bpuser=10891&action=masterStocks`,formdata)
    setMasterStock(result.data.data)
    
}

useEffect(()=>{
    getstock()
  },[])


const showbroker=()=>{
return  masterstock.map((val)=>{
    return (
        <>
        <option value={val.STOCKS} >{val.STOCKS}</option>
        </>
) })}

// const handleLtpChange = (index, value) => {
  
//     const updatedLtpData = [...ltpData];
//     updatedLtpData[index].ltp = value;
//     setLtpData(updatedLtpData);
//   };


  const showLtpList = () => {


    return ltpData.map((val, index) => (

      <div className='card' style={{ display: 'flex', flexDirection: 'row', padding: '15px' }} key={index}>
        <div style={{ fontSize: '20px', alignSelf: 'center', justifySelf: 'center', width: '30vw' }}>{val.Stock}</div>

        {/* <div style={{ borderRight: 'solid black 1px' }}></div> */}

        {/* <div style={{ display: 'flex', alignItems: 'center', background: '#ced4da', width: '30vw' }}>
          <input
            fullwidt
            type="text"
            className="form-control"
            placeholder="date"
            value={val.date}
            readOnly
          />
          <div style={{ paddingRight: '5px', paddingLeft: '5px', borderTopRightRadius: '20px', borderBottomRightRadius: '20px' }}>
            <img src='/assets/calendar.png' style={{ width: '100%' }} />
          </div>
        </div>

        <div style={{ borderRight: 'solid black 1px', marginLeft: '100px' }}></div>

        <div style={{ display: 'flex', alignItems: 'center', background: '#ced4da', width: '26vw' }}>
          <input
            fullwidt
            type="text"
            className="form-control"
            placeholder="Please Enter LTP.."
            value={val.ltp}
            onChange={(e) => handleLtpChange(index, e.target.value)}
          />
          <div style={{ paddingRight: '5px', paddingLeft: '5px', borderTopRightRadius: '20px', borderBottomRightRadius: '20px' }}>
            <img src='/assets/rupee.png' style={{ width: '100%' }} />
          </div>
        </div> */}


      </div>

    ));

  };



const navigate=useNavigate()


// const Savedata = async () => {
 
//     let dataa = ltpData.filter((val) => val.ltp !== '');

//     if (dataa.length > 0) {
      
//         // const formdata = new FormData();
//         // formdata.append('data',dataa); 
//         const requestBody = JSON.stringify({
//           body:dataa
//         });
      
//         try {



//             var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=dailyltpadd`,JSON.stringify(dataa));

//             if (result.data.res_code === 1) {
//                 Swal.fire({
//                     position: 'top-center',
//                     icon: 'success',
//                     title: 'Save LTP Successfully',
//                     showConfirmButton: false,
//                     timer: 1200
//                 }).then(() => {
                    
//                     setFromDate(new Date());
//                 });
//             } else {
//                 console.log('Server error...');
//             }
//         } catch (error) {

//             console.log('Error saving data:', error);
//         }
//     } else {
    
//         Swal.fire({
//             position: 'top-center',
//             icon: 'error',
//             title: 'Please Enter LTP',
//             showConfirmButton: false,
//             timer: 1600
//         });

      
//     }
// };

const handleFileUpload = (e) => {

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, {
        raw: false,
      });
      const headingMappings = {
        "DATE" : "DATE" ,
        "LTP" : "LTP",
       };

    
      
    const keysExcel = Object.keys(headingMappings);
    const keysTittle = Object.keys(titleData);

     

    if(keysExcel[0]!==keysTittle[0])
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong Date is invalid!',
      }).then((result) => {
        navigate('/ltpfeed')
      })

      return false;

    }
    else if(keysExcel[1]!==keysTittle[1])
    {
      // alert(" Particulars invalid")
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong LTP invalid !',
      }).then((result) => {
        navigate('/ltpfeed')
      })
      return false;

    }
    
    
      const parsedData = jsonData.map((item) => {
        const transformedItem = {};
        for (const originalHeading in item) {
          if (originalHeading in headingMappings) {
            const desiredKey = headingMappings[originalHeading];
            transformedItem[desiredKey] = item[originalHeading];
          } else {
          }
        }
        return transformedItem;
      });
      setJsonData(parsedData);
      setFormData(parsedData);
    };
    reader.readAsArrayBuffer(file);
  };


  const handleInputChange = (name, value, rowIndex) => 
    {
        const updatedData = [...formData];
        updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        [name]: value,
        
        };
        setFormData(updatedData);
    };


  
function validation(){
    return true;
}
  
const sendDataToAPI = async () => {
    if (stock==='') {
      Swal.fire('Please select a stock before submitting.');
      return false;
    }
  
    const newObj = { ...formData };
    newObj.b_admin_id = admin.user_id;

 

    const updatedFormData = formData.map(item => ({
        ...item,
        DATE: formatDate(item.DATE) 
    }));
  

    console.log(updatedFormData,'formData....')
    
    if (validation()) {
     
      const requestBody = {
        stock:stock,
        b_admin_id: admin.user_id,
        body:updatedFormData
    
       }
    
  
      try {
        const result = await axios.post(`${URL}stockInfo&bpuser=10891&action=dailyltpadd`,JSON.stringify(requestBody) );
       
      
        if (result.data.res_code === 1) {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Save LTP Successfully",
            showConfirmButton: false,
            timer: 1600,
          }).then((result) => {
            setJsonData([])
            setFormData([])
            setFormShow(false)
            setStock('')
            navigate("/Dashboard");
          });
        }
          else {
          // Handle other cases, e.g., when the submission was not successful
        }
      } catch (error) {
        
        console.error("Error submitting data:", error);
        // Handle the error appropriately, e.g., show an error alert
  
      }


    
    }
  };
  





  const keys = Object.keys(titleData);


return (


    <>


        <div style={{background:'#e0e0e0',minHeight:'100vh',width:'100%',padding:'10px 20px 10px 20px'}}>
        
     

            <div className='card' style={{display:'flex',flexDirection:'row',padding:'15px'}}>

                <div className="col-sm-1"  onClick={()=>navigate('/dashboard')} style={{background:'#007bff',padding:'10px',display:'flex',justifyContent:'center',alignItems:'center',color:'#fff',borderTopLeftRadius:'5px',borderBottomLeftRadius:"5px",fontSize:'20px',borderRight:'2px solid black'}} >
            
                 HOME

                </div>
                <div className="col-sm-10" style={{background:'#007bff',padding:"10px",display:'flex',justifyContent:'center',alignItems:'center',borderTopRightRadius:'5px',borderBottomRightRadius:"5px"}} >
                <div style={{fontSize:'20px',color:'#fff'}}>{formshow ? "LTP IMPORT" : "LTP LIST"}</div>
                
                </div>

                  
            <div className="col-sm-1" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>

                {formshow ? <>
                <img src='/assets/list.png' style={{width:'40px'}} onClick={()=>setFormShow(!formshow)} />
                </>:<>
                <img src='/assets/exam.png' style={{width:'40px'}} onClick={()=>setFormShow(!formshow)} />
                </>}
            
            </div>




            </div>

            {formshow ? <></>:
                
                <>

                    <div className='card' style={{display:'flex',flexDirection:'row',padding:'15px',alignItems:'center',justifyContent:'space-between'}}>
                        
                        <h3>From Date</h3> :
                        <input fullwidt  value={fromdate ? formatDate(fromdate) : ''} onChange={(e)=>setFromDate(new Date(e.target.value))} type="date" class="form-control col-sm-4" placeholder="date"  />
                        
                        <h3>To Date</h3> :
                        <input fullwidt  value={todate ? formatDate(todate) : ''} onChange={(e)=>setToDate(new Date(e.target.value))} type="date" class="form-control col-sm-4" placeholder="date"  />
                                        
                        {/* <div className="col-sm-1" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>

                        <button onClick={()=>Savedata()}  style={{padding:'13%',fontSize:"15px",background:'#007bff',color:'#fff'}}>Save</button>
                        
                        </div> */}
                    
                    </div>


                </> 

            }



            

            {formshow ? <>
                
                <div>

                    <div className='card' style={{display:'flex',flexDirection:'row',padding:'15px',alignItems:'center'}}>
                    
                    
                        <div className="samplefildownload col-sm-4">
                            
                            <select  onChange={(e)=>setStock(e.target.value)} id="brokerSelect"  className="form-control" >
                            <option value={''}>--- Select Stock ---</option>
                            {showbroker()}
                            </select>

                        </div>
                        
                    
                    
                        <div className="samplefildownload col-sm-4" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                            
                        
                            
                            <input style={{padding:'10px',borderRadius:'5px'}} type="file"   className=" mb-2 bg-primary text-white form control "  onChange={handleFileUpload} />
                            
                        

                        </div>

                    
                        
                        <div className="samplefildownload col-sm-4">
                        
                            <b style={{display:'flex',justifyContent:'center',alignItems:'center'}}>Sample file for
                            
                                <a  href='./excelformat/stock_ltp.xlsx' download  style={{ margin: '5px', float:'right' , color:'red',fontWeight:'bold' }} >
                                    download
                                </a> to Excel
                            
                            </b>

                        </div>

                    


                    </div>

                    {jsonData.length > 0 && (

                        <div class="table-responsive" style={{padding:'30px 0px 30px 0px'}}>

                            <table id='customers'>
                                <thead>
                                <tr>
                                    {/* {console.log(titleData)} */}
                                    {keys.map((keyk, index) => (
                                    <th key={index}>{keyk}</th>
                                    ))}
                                    {/* {Object.keys(jsonData[0]).map((key, columnIndex) => (
                                    <th key={key}>{key}</th>
                                    ))} */}
                                </tr>
                                </thead>
                                <tbody>
                                {jsonData.map((item, rowIndex) => (
                                    <tr key={rowIndex}>
                                    {Object.keys(item).map((key, columnIndex) => (
                                        <td key={key}>
                                            <div className="col-sm-6" style={{display:'flex',marginTop:'15px'}}> 
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        id={`${key}-${rowIndex}`}
                                                        name={`${key}-${rowIndex}`}
                                                        value={formData[rowIndex][key]}
                                                        onChange={(e) =>
                                                        handleInputChange(key, e.target.value, rowIndex)
                                                        }
                                                        style={{padding:'0.5vw',fontSize:'1rem'}}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    ))}
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                            <button class="btn btn-secondary" style={{marginTop:'40px',padding:'5px'}} type="button" onClick={sendDataToAPI}>
                            Save
                            </button>

                        </div>

                    )}




                </div>

            </>:<>

            {showLtpList()}

            </>}
           



        </div>



    </>




)



}

export default Ltpfeed