import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { URL,IMGURL } from './service/url';

// import { Avatar } from '@mui/material';

export default function Profile() {

    const navigate=useNavigate()
    const dispatch=useDispatch()
    const admin=JSON.parse(localStorage.getItem('admindata'))
    // console.log("admin//////////////////////////////",admin)
     const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isToggled, setIsToggled] = useState(false);
    const [pic,setPic]=useState('')
    const Swal = require('sweetalert2')



    


    
    const handleToggle = () => {
        setIsToggled(!isToggled);
      };
    // console.log(admin,"admin")
    function validation(){
        return true;
      }

      
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            Swal.fire("New password and confirm password doesn't match ")
          return;
        }
        else if (newPassword.length <= 4)
        {
            Swal.fire("Please Enter Password length above 4 letter")
            return;
        }


        if(validation()){
            
            var formdata=new FormData()
            formdata.append('username',admin.user_name)
            formdata.append('password',currentPassword)
            formdata.append('newpassword',newPassword)

        var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=profileChange`,formdata)
        if(result.data.res_code==1)
        {
            Swal.fire("Password Change Successfully")
            navigate('/dashboard')
            
        }else{
            Swal.fire("Re-try")
        }
   
        }
      };


      const  refreshPage=()=>{
  
        window.location.reload(false);
      
      }
      
      
      const brokerrr=(bid,bnm)=>{
      
        var body={bro_id:bid,bro_name:bnm}
        var bi ='broker'
      
        dispatch({type:'brokeridset',payload:[bi,body]})
        
    
      }

     


     const setimage=()=>{
  
        if(admin.profile_img_url1 == " ")
        {
            setPic('/assets/user.png')
            
        }
        else
        {
            setPic(admin.profile_img_url1)
        }
         
    }
  

  
    useEffect(()=>{
        setimage()
    },[])



    
      



  return (
      <div className="wrapper">
          <Header    refresh={refreshPage}   brokerr={brokerrr}   />
          <div className="content-wrapper">
              {/* Content Header (Page header) */}
              <div className="content-header">
                  <div className="container-fluid">
                      <div className="row mb-2">
                          <div className="col-sm-6">
                              <h1 className="m-0" style={{ fontSize: "16px" }}>Admin Profile</h1>
                          </div>{/* /.col */}
                          <div className="col-sm-6">
                              <ol className="breadcrumb float-sm-right">
                                  <li className="breadcrumb-item"><a href="Dashboard">Home</a></li>
                                  <li className="breadcrumb-item active">Admin Profile</li>
                              </ol>
                          </div>{/* /.col */}
                      </div>{/* /.row */}
                  </div>{/* /.container-fluid */}
              </div>
              <div className="content">
                  <div className="container-fluid">
                      <div class="col-md-12">
                          <div class="card card-primary card-outline">
                              <div class="card-body box-profile"  >
                              <p class="text-muted text-right"> ID:-{admin.user_id}</p>
                                  <div class="text-center">
                                  {/* <AccountCircleIcon style={{height:'150px',width:'150px'}}
                                  className="profile-user-img img-fluid img-circle"
                                  alt="User profile picture"
                                  src="dist/img/user2-160x160.jpg"
                                  /> */}
                                      {/* <img class="profile-user-img img-fluid img-circle" src="../../dist/img/user4-128x128.jpg" alt="User profile picture" /> */}
                                      <img   style={{
                                            borderRadius: "50%",
                                            width: 120,
                                            height: 120,
                                            background: "white",
                                            display: "block"
                                        }}  className="profile-user-img img-fluid img-circle" src={IMGURL+pic}    alt="User profile picture"   />
                                      
                                      {/* <Avatar  alt="User profile picture"   /> */}
                                  </div>
                                  <h3 class="profile-username text-center">{admin.user_name}</h3>
                                  <p class="text-muted text-center">{admin.user_type}</p>
                              </div>
                          </div>
                          <div class="card card-secondary">
                              <div class="card-header">
                                  <h3 class="card-title">My Profile</h3>
                              </div>
                              <div class="card-body">
                                  <strong><i class="fas fa-book mr-1"></i> Email ID</strong>
                                  <p class="text-muted">
                                     {admin.user_email}
                                  </p>
                                  <hr/>
                                      <strong><i class="fas fa-mobile-alt mr-1"></i> Mobile</strong>
                                      <p class="text-muted">{admin.b_mobile_no}</p>
                                      {/* <hr/> */}
                                      {/* <strong><i class="fas fa-book mr-1"></i> Fine Year</strong> */}
                                       <p class="text-muted">
                                     {/* {admin.finyear} */}
                                  </p>
                                  {/* <hr /> */}
                                          {/* <hr/> */}
                                              {/* <strong><i class="far fa-file-alt mr-1"></i> Notes</strong> */}
                                              {/* <input type='textarea' class="text-muted" ></input> */}
                                              <hr/>
                                              <strong><i class="fas mr-1"></i> <div >
                       <button className='btn btn-lock btn-secondary' onClick={handleToggle}>
                        {isToggled ? '  Change password' : 'Change password'}
                       </button>

                        <p> {isToggled ?   

                         
                         <form  className='container' onSubmit={handleSubmit}  >
                        
                         <div className="column" style={{padding:'10px'}}>

                            <div className="col-sm-6" style={{padding:'10px'}}>
                            
                                <input  type="password"  placeholder="Current Password"  value={currentPassword}  onChange={(e) => setCurrentPassword(e.target.value)}  className='form-control'/>
                            
                            </div>           
                                
                            <div className="col-sm-6"  style={{padding:'10px'}}>  
                             
                                 <input  type="password"    placeholder="New Password"   value={newPassword}   onChange={(e) => setNewPassword(e.target.value)} className='form-control'/>
                            
                            </div>                  

                            <div className="col-sm-6"  style={{padding:'10px'}}>    
                               
                                <input  type="password" placeholder="Confirm New Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className='form-control'/>
                          
                            </div> 

                            <div className="col-sm-6"  style={{padding:'10px'}}> 

                                <button className=' btn btn-secondary' type="submit">Save</button>

                            </div>    
                        
                            </div>
                         
                         </form>
                               
                               : null} </p>
                                         
                                            </div>
                                        </strong>
                                    </div>
                                </div>
                        </div>
                  </div>
              </div>
          </div>
          <Footer />
      </div>
  )
}
