
// import './App.css';

import Menu from "./Menu";

//import Test from "./Test";


function App() {


  return (
   
    <div className="wrapper">

      
      <Menu/>

    </div>
  
  );
}

export default App;
